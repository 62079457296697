.wi-Fail {
  padding-top: 4rem;
  position: fixed;
  -webkit-transform: translateZ(0);
  width: 100vw;
  top: 0;
  left: 0;
  font-size: 1.1rem;

  h3 {
    font-size: 2.5rem;
  }
}
