.wi-Login-ForgotPasswordLink {
  margin-top: 2px;
  float: right;
}
.wi-Login-ForgotPasswordLink.error {
  @media screen and (max-width: 1200px) {
    margin-top: 20px;
    float: none;
  }
}

.wi-showHidePassword {
  display: flex;
  align-items: center;
}

.wi-showHideLabel {
  margin-left: 4%;
}

.wi-passwordField {
  margin-top: 6%;
}
