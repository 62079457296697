.job-assist {
  &.modal {
    @media (max-width: 920px) {
      height: unset;
      overflow: scroll;
    }
  }

  &.profile-pictures {
    display: flex;

    @media (max-width: 920px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  }
}
