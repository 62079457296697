.pa-workflow-revisions {
  height: 100%;
  background-color: #eff1f0;
}
.pa-workflow-revisions-container {
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 16px 10px 10px 10px;
}

.pa-workflow-revisions-left {
  display: flex;
  flex: 1;
  justify-content: space-between;
}

.pa-workflow-revisions-right {
  margin-left: 20px;
  display: flex;
  flex: 1;
  justify-content: flex-end;
}

.pa-workflow-revisions-header {
  display: flex;
}

.pa-workflow-revisions-diffs {
  display: flex;
  flex: 1;
  margin-top: 16px;

  .pa-workflow-revisions-left,
  .pa-workflow-revisions-right {
    justify-content: center;
  }
}

.pa-workflow-revision-viewer {
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
}

.pa-workflow-revision-viewer-header {
  background: #eff1f0;
  border-radius: 3px 3px 0 0;
  border: 1px solid #d2d5d6;
  padding: 10px;
}

.pa-workflow-revision-name {
  color: #545454;
  font-size: 22px;
  font-weight: 600;
  line-height: 26px;
}

.pa-workflow-revision-action {
  float: right;
  padding-left: 50px;

  &--active {
    color: #0086bf;
    cursor: pointer;
  }

  &--disabled {
    color: #999999;
  }
}

.pa-workflow-revision-description {
  color: #545454;
}

.pa-workflow-revisions-select {
  width: 330px;

  .react-select__control {
    height: 51px;
  }
}

.pa-workflow-revision-viewer-body {
  background: #ffffff;
  border-radius: 0 0 3px 3px;
  border: 1px solid #d2d5d6;
  border-top: none;
  flex: 1;
}
