@import '~styles/variables';
@import '~styles/utilities';

.pa-SubNav {
  background-color: $white;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.wi-SectionHeader-Report {
  border: 0;
}

.wi-SectionHeaderNav {
  display: flex;
  align-items: center;
  font-size: 14px;
  a,
  span {
    @extend %type-header-title;
    color: $charcoal;
    margin: 0 0.5rem;
    padding: 6px 10px;
    position: relative;
    text-decoration: none;
    font-size: 14px;
    &:first-child {
      margin: 0 0.5rem 0 0;
    }
    &.active {
      font-weight: 700;
      color: var(--primaryA, $blue);
      border-bottom: 2px solid var(--primaryA, $blue);
    }
  }
}

.wi-SectionHeader-editableTitle {
  color: $charcoal;
  font-size: $font-size-h1;
  margin-bottom: 0;
}

.wi-SectionHeader--Title {
  font-size: $font-size-h1;
  font-variant: normal;
  position: relative;
  margin-top: 6px;
}

.wi-SectionHeader-editIcon {
  margin-left: 5px;
  position: relative;
  top: 7px;
}

.wi-SectionHeader--Title-validation-error {
  color: $red;
  font-size: 12px;
}

.wi-SectionHeader--Subtitle {
  font-size: $font-size-base;
  font-variant: normal;
  position: relative;
  top: -1.5rem;
  padding-bottom: 5px;
  border-bottom: 1px solid $grey;
}

.wi-SectionHeader-editableSubtitle {
  color: $charcoal;
  font-size: $font-size-base;
  margin-bottom: 0;
}
