@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-StepFieldValidationModal {
  min-height: 550px;
  .well {
    overflow-y: scroll;
  }
  .react-select__control,
  .react-select__value-container,
  .react-select__value-container input[type='text'] {
    height: auto;
    align-items: center;
  }
  .react-select__control,
  .react-select__menu {
    text-transform: lowercase;
  }
}

.wi-StepFieldValidation-ExpectedValue {
  margin-top: 5px;
}

.wi-StepFieldValidation-ActionRender {
  .wi-Integrations-Pill {
    margin: 5px;
  }
}
