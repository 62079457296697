@import '~styles/utilities';
@import '~styles/variables';

.Tokens-Fields {
  border: 1px solid gray;
  background-color: white;
  list-style: none;
  width: 20%;
  margin-bottom: 16px;
}

.Tokens-Field {
  margin: 5px;
}

.wi-newToken-warning {
  background-color: #fff5e7;
  border: 1px solid #eed5b2;
  margin-top: 35px;
  margin-bottom: 35px;
  display: flex;
  color: $charcoal;
  flex-direction: row;

  .wi-Icon {
    margin: 0;
    margin-right: 8px;

    svg {
      width: 16px;
    }
  }
}

.wi-newToken-error {
  display: flex;
  color: $red;
  flex-direction: row;
}

.wi-copyToClipboard {
  position: absolute;
  top: 20px;
  color: #ffffff;
  background-color: $blue;
  border-color: $blue;
  font-size: 14px;
  min-height: 30px;
  padding: 0 10px;
  cursor: pointer;
  display: inline-flex;
  font-family: 'proxima-nova', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;

  border-style: solid;
  align-items: center;
  text-decoration: none;
  text-align: center;
  &:hover {
    background-color: #006996;
    border-color: #006996;
  }
}

.wi-copyToClipboardParent {
  position: relative;
}

.wi-copied {
  color: $red;
  position: relative;
  top: 55px;
}
