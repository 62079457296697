@import './TemplateDiffReportUtils';

.wi-Diff-TemplateWrapper {
  min-height: 90%;
  background-color: #fff;
  margin: 10px 50px 5px 45px;
  @media (max-width: 920px) {
    margin-right: 5px;
  }
  @media print {
    border: $grey;
  }
  @media screen {
    border: 1px solid $grey;
  }
}
