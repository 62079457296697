@import '~styles/variables';
@import '~styles/utilities.scss';

.pa-JobPlanning-RecurringWarning {
  color: $white;
  background-color: $dark_red;
  min-height: 56px;
  font-size: 13px;
  font-weight: $font-weight-regular;

  img {
    width: 40px;
    height: 40px;
  }

  .pa-JobPlanning-RecurringWarning-inner {
    @include flexbox(row, center);
    width: 66%;
    margin: 0 auto;
    padding: 7px 30px;
  }

  .pa-JobPlanning-RecurringWarning-text {
    margin: 0px 10px;
  }
}
