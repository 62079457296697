.pa-errorBoundary {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;

  .Button {
    margin-top: 10px;
  }
}
