@import '~styles/variables';
@import '~styles/utilities';
.wi-JobReportFields {
  .wi-JobReport-InputDeviation {
    right: calc(100% + 16px);
    top: -2px;
  }
  .completion-info {
    font-size: 11px;
    border-bottom: none !important;
    color: black !important;
  }
  .col-field-icon {
    width: 25px;
    width: 5%;
    height: 25px;
    line-height: 0;
    position: relative;
    div:last-of-type {
      height: 25px;
      display: flex;
      align-items: center;
    }
  }

  .completion-info {
    border-bottom: 1px dashed $grey;
  }
  .requiredFlag {
    font-style: italic;
    color: $red;
    font-size: $font-size-small;
  }
  .wi-RequiredBadge {
    left: 0;
    margin-left: 4px;
  }

  .wi-StepFieldItem {
    position: relative;
    page-break-inside: avoid;
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    .empty-field {
      position: absolute;
      right: 100%;
      width: 14px;
    }
    .wi-StepFieldItem-label > .wi-Icon:first-child {
      position: absolute;
      right: 100%;
    }
    .wi-StepFieldItem-label {
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }
  }
  .col-field-value {
    word-wrap: break-word;
    white-space: normal;
    max-width: 550px;

    & .underlined > a:not(.please-use-sera) {
      word-break: break-all;
    }

    & > div {
      position: relative;
      text-align: right;
      & > div {
        position: relative;
        page-break-inside: avoid;
        display: flex;
        justify-content: right;
      }
    }
    a span {
      color: $black;
    }

    .wi-ExifImage-Container img.pa-signature {
      width: 215px;
      height: auto;
    }

    .wi-Report-Danger {
      color: $red;
      border-bottom-color: $red;
    }

    .option-list {
      span {
        display: inline-block;
      }
    }
    .please-use-parsable {
      font-size: $font-size-small;
      color: $grey;
      margin: 6px 0 22px;
      &.clickable {
        cursor: pointer;
      }
    }
    .number-constraints {
      font-size: 10px;
      float: right;
    }
    video,
    audio {
      max-height: 275px;
      max-width: 275px;
      margin-right: auto;
    }

    img {
      height: 275px;
      max-width: 100%;
      margin-right: auto;
    }
  }

  .col-field-value .wi-Gallery-Container {
    display: flex;
    flex-wrap: wrap;
    line-height: initial;
    width: 100%;
    max-width: 376px;
    > div {
      width: calc(((#{$pa-Report--width} / 2) / 3) - #{$pa-Report-Gallery--gap});
      margin-left: 10px;
      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}

.text-strikethrough {
  text-decoration: line-through;
}

.field-key-isna {
  margin-left: 5px;
  display: inline-block;
}

// overrides for rows containing formulas
.wi-JobDetailReport .wi-JobReportSteps-Container .pa-JobReport-CalcRow {
  .wi-StepFieldItem-label {
    width: 100%;
  }
  .wi-Icon {
    height: 22px !important;
  }
}
