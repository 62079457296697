@import '~styles/variables';
@import '~styles/utilities';
@import '~styles/extends.scss';

.wi-WebExTasks {
  @extend %pa-WebEx--WellInner;
  height: 100%;
  background-color: $light_grey;

  .pa-CompleteButtomWrapper {
    flex: 1 1 100%;
  }

  .pa-NotifyRoleButtonWrapper {
    margin-bottom: 15px;
  }

  .ButtonSet {
    margin-bottom: 0;
  }

  .panel {
    @include flexbox(column, space-between, stretch);
    margin-bottom: 0;
    background-color: #ffffff;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.05);

    .panel-heading {
      font-weight: 600;
    }

    .panel-body {
      flex-grow: 1;
      overflow: auto;
      position: relative;
      margin: 24px;
      margin-top: 0;
    }
  }

  .pa-stepList .panel-body {
    padding: 14px 0;
    margin: 0;
    margin-bottom: 24px;
  }

  .pa-NA-Title {
    text-decoration: line-through;
  }

  .wi-StepList {
    color: $charcoal;

    .wi-StepListItem {
      cursor: pointer;
      margin-top: 6px;
    }

    .wi-StepListItem-StepGroup {
      color: $black;
    }

    .wi-StepListItem-Title {
      &:hover {
        background-color: $highlight_grey;
      }

      .wi-StepListItem-TitleLeft {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;

        .wi-Icon[title='Not Applicable'] {
          svg {
            width: 16px;
            height: 16px;
          }
        }
      }

      &.wi-StepListItem-Active {
        color: $black;
        @extend %type-text-medium--bold;
        font-weight: 800 !important;
      }
    }
  }

  .wi-WebExTasks--reverse {
    flex-direction: row-reverse;
  }
}

.wi-WebExTasks--LoadingOverlay {
  bottom: -$padding-large-vertical;
}

.wi-WebExTaskDetail-Issue {
  height: auto !important;
  padding-right: 50px;
  background-color: $white;

  .panel {
    margin-bottom: 0px;
    box-shadow: 0px 0px 0px 0px #fff;
    border: none;
    .panel-body {
      overflow: visible;
      padding: 10px 12px 4px 12px;
      background-color: inherit;
    }
  }

  .GroupHeaders .flexboxgrid--col-xs-12 {
    padding-bottom: 0px;
  }
}

.wi-WebExTaskDetail-IssueTemplateTitle {
  background-color: $light_grey !important;
  border-bottom: solid 1px $grey;
  border-top: solid 1px $grey;
  padding-top: 10px;
  padding-bottom: 10px;
}

//layout specific needed for height;
.wi-WebExTaskDetail {
  height: 100%;

  .panel-heading {
    position: relative;
    font-weight: 600;

    img {
      position: absolute;
      width: 25px;
      height: 25px;
      right: 5px;
      top: 8px;
    }
  }

  h3 {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .wi-WebExTaskDetail-Descrip {
    white-space: pre-wrap;

    img,
    video {
      max-width: 100%;
      max-height: 240px;
    }

    video::-webkit-media-controls {
      display: flex !important;
    }

    & > * {
      margin-bottom: 5px;
    }
  }

  .wi-WebExTaskDetail-Subtitle {
    color: $text_color;
  }

  .GroupHeaderIcon {
    margin-right: 6px;
  }

  .Document:not(.Document-Thumbnail):not(.GroupHeaderIcon) {
    background-color: $light_grey;
    border: 1px solid $grey;
    margin: 10px 0;
    padding: 4px 8px;
    @include flexbox();

    img,
    video {
      max-width: calc(100% - 8px);
    }

    .wi-LightBox-OpenState {
      img,
      video {
        max-width: calc(100vw - 140px);
        max-height: calc(100vh - 220px);
      }
    }
  }

  &.wi-WebExTaskDetail-FlattenedUI {
    .wi-WebExTaskDetail-TaskTitle {
      .wi-WebExTaskDetail-TitleHeader {
        @include flexbox();
        text-transform: uppercase;
        color: $dark_blue;

        .wi-WebExDeviationsAndIssuesInfo {
          @include flexbox();
          width: 80px;

          div {
            @include flexbox();
            width: 32px;
          }
        }

        .wi-WebExTaskDetail-StepTitle {
          color: $dark_blue;
          margin-bottom: 0;
        }
      }
    }
  }
  .wi-WebExTaskDetail-StepTitle {
    margin-right: 10px;
  }
}

.wi-WebExTaskDetail-StepDescrip {
  font-size: $btn-font-size-lg;
  p {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .wi-DescriptionElement-Txt {
    @extend %pa-Markdown--FlatDescription;
  }
}

.wi-WebExTaskFields {
  &:first-of-type {
    margin-top: 20px;
  }
}

.wi-WebExTaskField {
  position: relative;

  .form-group {
    display: inline-block;
  }

  .wi-WebExTaskField-Input,
  .Select {
    display: inline-block;
    width: 100%;
  }

  textarea.wi-WebExTaskField-Input {
    display: block;
  }

  .pa-WebExTaskField-Date {
    margin-right: 0.5rem;
    min-width: 16rem;
    width: 100%;
    .pa-WebExTaskField-Date-Inner {
      display: flex;
      justify-content: center;
    }
  }

  .wi-WebExTaskField-Invalid {
    border-color: $red;
  }

  .wi-WebExTaskField-ShowOnlyMarkNA {
    margin-top: -25px;
    margin-bottom: -25px;
  }

  .Select {
    vertical-align: middle;
  }

  .wi-WebExTaskFieldData {
    position: relative;
    flex-wrap: wrap;

    input[disabled],
    textarea[disabled] {
      background-color: $grey;
    }

    > div {
      display: inline-block;
      min-width: 16rem;
      width: 100%;
    }

    img,
    video {
      max-width: 100%;
      max-height: 240px;
    }

    video::-webkit-media-controls {
      display: flex !important;
    }

    & > * {
      margin-bottom: 25px;
    }
    .pa-WebExTaskFieldData--mobileonly {
      font-style: italic;
      color: $dark_grey;
      font-style: italic;
      color: $charcoal;
      display: block;
      background-color: $grey;
      height: 40px;
      width: 100%;
      line-height: 40px;
      padding-left: 40px;
      border-radius: 4px;
    }

    .pa-WebExTaskFieldData--Disabled {
      opacity: 0.7;
      pointer-events: none;
    }

    .pa-WebExTaskFieldMeasurementUnit {
      margin-top: 0;

      .pa-WebExTaskFieldMeasurementUnit-Input {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: center;
        align-content: center;
      }

      .wi-WebExTaskField-Outer {
        flex: 1 1 0;
        padding-right: 5px;
      }

      .pa-WebExTaskFieldMeasurementUnit-Units {
        flex: 1 1 0;

        .Select-value,
        .Select-placeholder,
        .Select-input {
          margin-left: 0;
        }

        &.pa-WebExTaskFieldMeasurementUnit-Units-Invalid {
          .Select-control {
            border: 2px inset $red;
          }
        }

        &.Select.is-disabled.Select--single {
          .Select-control {
            opacity: unset;
            background-color: $light_grey !important;
            border: 1px solid $grey;

            .Select-value-label {
              color: $black !important;
            }
          }
        }
      }
    }
  }

  .Document:not(.Document-Thumbnail) {
    background-color: $light_grey;
    border: 1px solid $grey;
    margin: 10px 0;
    padding: 4px 8px;
    @include flexbox();
  }

  .wi-WebExTaskField-Descrip {
    border-left: 3px solid $grey;
    margin: 10px 10px 10px auto;
    padding-left: 15px;
    font-size: $btn-font-size-lg;

    .wi-DescriptionElement-Txt {
      @extend %pa-Markdown--FlatDescription;
      margin-bottom: 0px;
    }
  }
}

.pa-WebExTaskField-topWrapper {
  @include flexbox(row, space-between, center);
}

.pa-WebExTaskField-NAWrapper {
  @include flexbox(row, flex-end, center);
  min-width: 110px;
  margin-left: 5px;
}

.pa-WebExTaskField-NAToggle {
  transform: scale(0.9);
}

.pa-WebExTaskField-NAText {
  padding-right: 5px;
  &.pa-red {
    color: $red;
  }
}

.pa-WebExTaskField-condition {
  margin-bottom: 25px;
}

.wi-WebExTaskField-Icon {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 18px;
  height: 18px;
}

.pa-WebExTaskField-KeyWrapper {
  @include flexbox(row, flex-start, center);
}
.wi-WebExTaskField-IntegrationIcon {
  justify-content: flex-start;
  flex: 1;
}

.wi-WebExTaskFieldData {
  margin: 0.5rem 0 1rem;
  display: flex;

  .wi-Input > input,
  .rdt > input {
    padding-left: 40px;
    height: 40px;
    width: 100%;
    margin-bottom: 0%;
  }

  // All of these is to allow fitting field icons in the select components
  .Select--multi .Select-control {
    height: 40px;

    .Select-placeholder {
      line-height: 40px;
      margin-left: 35px;
    }

    .Select-value {
      line-height: 22px;
      .Select-value-label {
        line-height: 22px;
      }
    }

    .Select-input {
      height: 22px;
    }

    .Select-placeholder + .Select-input {
      margin-left: 40px;
    }

    .Select-multi-value-wrapper > div:first-child {
      margin-left: 40px;
    }
  }

  .Select--single .Select-control {
    height: 40px;

    .Select-placeholder {
      line-height: 36px;
      margin-left: 35px;
    }

    .Select-value {
      line-height: 36px;
      margin-left: 35px;
      .Select-value-label {
        line-height: 36px;
      }
    }

    .Select-input {
      height: 36px;
      margin-left: 35px;
    }
  }

  .Select {
    &.is-disabled {
      .Select-control {
        opacity: calc(0.6 * 0.65);
        border: 1px solid $black;
        background-color: $charcoal;
      }
      &.Select--multi {
        .Select-value {
          .Select-value-label {
            color: $white;
            background-color: $charcoal;
          }
        }
      }
    }
  }

  .wi-WebExTaskField-LimitedList {
    .ButtonSet {
      .Button {
        height: 48px;
        margin: 0;
        border: 1px solid $grey;
        border-bottom: 0px;
        &:last-child {
          border: 1px solid $grey;
          border-bottom: 1px solid $grey;
        }
        &:focus {
          outline: 0;
        }
      }

      .Button-primary.Button-disabled {
        background-color: $charcoal;
        color: $white;
      }

      .Button-disabled {
        opacity: calc(0.6 * 0.65);
        border: 1px solid $black;
        border-bottom: 0px;
        &:last-child {
          border: 1px solid $black;
        }
      }
    }
  }
}

.wi-date-not-allowed {
  cursor: not-allowed;
}

.wi-WebExTaskDetail-IssueBannerContainer {
  height: 20px;

  .wi-WebExTaskDetail-IssueBanner {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
  }
}

.wi-WebexIssueModal-List {
  background-color: transparent;
  height: 314px;
  overflow-x: hidden;
  overflow-y: scroll;
  width: 100%;
  max-height: 314px;
}

.wi-WebexIssueModal-ItemContainer {
  color: $text_color;
  border-bottom: 1px solid $grey;

  .wi-WebexIssueModal-Item {
    @include flexbox(row, space-between, center);
    flex-wrap: wrap;
    padding: $padding-small-vertical $padding-small-horizontal;
  }

  .wi-WebexIssueModal-Info {
    flex: 1;
    .issueTitle {
      font-size: 18px;
      color: $text_color;
      font-weight: bold;
    }

    .issueID {
      color: $charcoal;
      font-weight: bold;
    }

    .issueCreator {
      color: $dark_grey;
    }

    .createdBy,
    .createdDate {
      color: $grey;
    }
  }

  button {
    flex: 0 0 30px;
  }

  strong {
    color: $dark_grey;
  }

  .title {
    flex-basis: 100%;
  }

  a {
    text-decoration: none;
    cursor: hand;
  }
}
