@import '~styles/variables';
@import '~styles/utilities';

.wi-Rev-Revert-Body {
  @include flexbox(column, center);
  color: $charcoal;

  .wi-Rev-Revert-Version {
    margin-bottom: 2px;
    margin-top: 10px;

    .wi-Rev-Revert-Version-label {
      font-weight: 700;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-right: 5px;
    }

    .wi-Rev-Revert-Version-timestamp {
      font-weight: 500;
      max-width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .wi-Rev-Revert-Author {
    .wi-Rev-Revert-Author-label {
      font-weight: $font-weight-mbold;
    }
    .wi-Rev-Revert-Author-name {
      font-weight: 500;
      margin-left: 5px;
    }
  }
}
