@import '~styles/colors.scss';

.wi-Insights-ChartBuilderHeader {
  display: flex;
  align-items: center;
  padding: 0 20px 16px;

  & > .label {
    display: inline-block;
    // <SelectExtended> takes 3px padding in the bottom while its options are shown
    padding-bottom: 3px;
    color: $charcoal;
    font-size: 14px;
    font-weight: normal;
  }

  & > .Select-Extended {
    // fixed height in order to avoid itself and UIs next moves
    // because <SelectExtended> takes 3px padding in the bottom while its options are shown
    height: 39px;
    margin-left: 16px;

    & > .Select {
      width: 220px;
    }
  }
}
