@import '~styles/variables';
@import '~styles/utilities';

.wi-DocumentFooter {
  @include flexbox();
  font-size: 13px;
  margin: 20px 32px 32px 32px;
  border: 2px dashed $grey;
  border-radius: 4px;
}

.wi-DocumentFooter--Upload {
  height: 100%;
  flex: 1;
  font-size: 18px;

  .dropzone {
    padding: 1rem 0;
  }

  small {
    font-size: 13px; // Overriding third-party usage
  }

  .wi-Icon {
    margin: 12px 0 12px 0;
    svg {
      height: 36px;
      width: 36px;
    }
  }
}

.wi-DocumentFooter-DocEmail {
  @include flexbox();
  justify-content: center;
  height: 100%;
  flex: 1;

  i {
    color: $charcoal;
    float: left;
    margin-right: 1rem;
    font-size: 2rem;
    line-height: 3rem;
  }
}
