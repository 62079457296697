@import '~styles/variables';
@import '~styles/utilities';

//variables
$vertical-inter-spacing-between-elements: 10px;
$vertical-intra-spacing-between-elements: 5px;
$removed-red: $highlight_red;
$added-green: $highlight_green;

// mixins

@mixin diffFont() {
  @extend %type-header-medium;
  margin: 14px 0px 8px;
}

@mixin removedText() {
  background-color: $removed-red;
  text-decoration: line-through;
}

@mixin addedText() {
  background-color: $added-green;
}

@mixin diffText() {
  &.wi-Diff-RemoveText {
    @include removedText();
  }

  &.wi-Diff-AddedText {
    @include addedText();
  }
}

@mixin diffImgRemoved($borderWidth: 1px, $borderSyle: solid) {
  background-color: $removed-red;
  padding: 1px;
  margin: -1px;
  img {
    opacity: 0.5;
  }
}

@mixin diffImgAdded($borderWidth: 1px, $borderSyle: solid) {
  background-color: $added-green;
  padding: 1px;
  margin: -1px;
}

@mixin diffImg($borderWidth: 1px, $borderSyle: solid) {
  &.wi-Diff-ImageRemoved {
    @include diffImgRemoved($borderWidth, $borderSyle);
  }

  &.wi-Diff-ImageAdded {
    @include diffImgAdded($borderWidth, $borderSyle);
  }
}
