@import '~styles/variables';
@import '~styles/extends.scss';

$descriptionMediaSize: 500px;

$galleryWidth: calc($pa-Report--width / 2);
$galleryMediaSize: calc($galleryWidth / 3); // 3 items per rows

.wi-JobReportSteps-Container > .wi-JobReportNode > .wi-JobReportNode-Body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.wi-JobReportNode {
  margin: 10px 0;
  position: relative;

  .wi-tinyHeader {
    color: $blue;
  }

  .wi-JobReport-Deviation {
    color: $white !important;
    -webkit-print-color-adjust: exact;
    z-index: 10000;
    position: absolute;

    .wi-JobReport-DevText {
      $height: 22px;
      $arrow_height: $height / 2;
      line-height: $height;
      bottom: 0;
      color: $white;
      display: inline-block;
      width: 45px;
      text-align: center;
      background-color: $red;
      height: $height;

      &:before {
        display: inline-block;
        position: absolute;
        left: 45px;
        content: ' ';
        width: 0;
        height: 0;
        border-top: $arrow_height solid transparent;
        border-bottom: $arrow_height solid transparent;
        border-left: $arrow_height solid $red;
      }
    }

    .wi-Icon {
      height: 56px !important;
      width: 45px !important;
      position: absolute;
      bottom: 0;
    }
  }

  .wi-JobReport-NodeDeviation {
    right: Calc(100% + 11px);
  }
  &.wi-JobReportNode_Template {
    margin-top: 30px;
  }
  .wi-JobReport-DevOrNA {
    display: flex;
    padding-bottom: 4px;
    margin-top: 20px;
    &:empty {
      padding: 0;
      margin: 0;
    }
    .wi-JobReport-NodeDeviation {
      position: static;
      padding-right: 20px;
      .wi-JobReport-DevText {
        position: relative;
        top: 0;
        left: 0;
      }
    }
  }

  .wi-NABackground {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    padding: 7px;
    margin: 0 -8px;
    pointer-events: none;
    background-image: linear-gradient(
      to left top,
      transparent 50%,
      $red 51%,
      $red 55%,
      transparent 50%
    );
    background-size: 20px 20px;
    .wi-NABackground-inner {
      background: #fff;
      padding: 1px 10px 1px 0;
    }

    .wi-JobReportNode-Header-Container {
      margin-top: 0;
      margin-right: -8px;
    }
    .wi-JobReportNode-StepGroup {
      opacity: 75%;
      margin-right: -8px;
    }
  }
  .wi-JobReport-NAIcon {
    svg {
      width: 25px;
    }
  }
  .wi-JobReport-NAText {
    display: flex;
    justify-content: space-between;
    color: $red;
    flex: 0 1 100%;
  }

  .nestedStepNA,
  .completed-string {
    color: $red;
    font-size: $font-size-base;
    display: flex !important;
    flex: 1 1;
    .dotted-connector {
      min-width: 0;
    }
  }
  .completed-string {
    color: $black;
  }
  .dotted-connector {
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    flex: 1 1 auto;
    display: flex !important;
    min-width: 150px;
    &:after {
      content: '';
      flex: auto;
      height: 6px;
      margin: 9px 6px 0;
      display: inline-block;
      background-image: radial-gradient(ellipse, #000 1px, #000 1px, transparent 1px);
      background-size: 8px 8px;
      background-position: 0 0;
      background-repeat: repeat-x;
    }
    &--red {
      &:after {
        background-image: radial-gradient(ellipse, $dark_red 1px, $dark_red 1px, transparent 1px);
      }
    }
  }
  .completed-string--hourglass {
    text-align: right;

    & .dotted-connector::after {
      background-image: none;
    }

    & > div:last-child {
      display: inline;
    }
  }

  .wi-JobReportNode {
    &.wi-JobReportNode_NA {
      margin-top: 50px;
    }
  }

  .step-heading.wi-isNotApplicable,
  .step-heading.wi-isNotApplicable div {
    text-decoration: line-through;
    color: $text_color;
  }

  .wi-MainIcon {
    position: relative;
    top: 1px;
  }

  .wi-JobReportNode-Header {
    break-inside: avoid;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;

    &:first-child {
      padding-top: 0;
    }

    .step-heading-container {
      width: 100%;
      @media print {
        direction: rtl;
      }
    }

    div {
      display: inline-flex;
    }
    .step-heading {
      @extend %type-header-title;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;

      &--hourglass {
        align-items: flex-start;
      }

      @media print {
        direction: ltr;
      }

      &.step-heading-withDesc {
        flex: 0 1 100%;
      }
      .step-heading-version {
        white-space: nowrap;
        display: block;
        font-size: 16px;
        font-weight: 400;
        padding: 0 10px;
      }
      .field-key-isna svg {
        width: 20px;
        height: 20px;
      }
      .wi-JobReportNode-Title {
        line-height: 1.3;
      }
    }
    .wi-SelectRole-NonEditing {
      text-transform: uppercase;
      margin-top: 10px;
    }
  }
  .wi-JobReportNode-Pill {
    float: right;
    font-size: $font-size-base;
    font-weight: $font-weight-regular;
    background-color: $white;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    color: $black;
    border-radius: 20px;
    padding: 1px 5px 0 5px;
    margin: 0 5px 5px;
    position: relative;
    top: 2px;
  }
  .wi-JobReportNode-Header-Container {
    break-inside: avoid;
    background-color: #fff;
    border: 1px solid $black;
    -webkit-print-color-adjust: exact;
    padding-left: 0;
    padding-bottom: 5px;
    padding-top: 5px;
    color: $black;
    margin-bottom: 20px;
    .step-heading-title {
      padding-left: 10px;
    }
    .wi-JobReportNode-Header > img:first-child {
      position: relative;
      top: 5px;
      flex-shrink: 0;
    }
  }
  &.wi-JobReportNode_Template > .wi-JobReportNode-Header-Container {
    background: $light_grey;
  }
  &.wi-JobReportNode_Template
    .wi-JobReportNode-StepGroup
    .wi-JobReportNode-StepGroup
    .wi-JobReportNode-Step {
    .wi-NABackground-inner {
      background: $light_grey;
      @media print {
        background: #fff;
      }
    }
  }

  .wi-JobReportNode-StepGroup {
    .conditional {
      width: 100%;
      border: none;
      margin-top: 8px;
      margin-left: $pa-Report-Number--width;
      padding-bottom: 8px;
      z-index: 1;
      position: relative;
      display: flex;
      align-items: center;

      td {
        border-bottom: none;
      }
    }

    .completion-info {
      color: $charcoal;
      @extend %type-text-regular;
    }
    .conditional-question {
      @extend %type-text-regular;
    }
    .conditional-answer {
      span {
        margin-left: 10px;
        border: 1px solid $charcoal;
        color: $charcoal;
        padding: 6px;
        border-radius: 20px;
      }
    }
    .wi-tinyHeader {
      margin: 0;
    }
    .equipment-item {
      display: inline-block;
    }
    .notices table {
      margin: 0;
    }
    .notice-item-part {
      font-size: 120%;
      display: inline-block;
    }
    .equipment,
    .notices {
      margin: 5px 0;
    }
  }

  .wi-JobReportNode-Body {
    padding-left: $pa-Report-Number--width;
    padding-right: 0;

    .wi-DescripElement-Txt {
      @extend %pa-Markdown--FlatDescription;
      margin-bottom: 0px;
    }
    .wi-Document-doc-icon {
      border: 0;
    }
    &.wi-JobReportNode-StepGroup {
      padding: 1px 15px 0 10px;
      position: relative;
      border: 0;
      background: white;
    }

    p {
      margin-bottom: 5px;
      overflow-wrap: anywhere;
    }
    .wi-WebExTaskDetail {
      white-space: pre-wrap;
      position: relative;
      z-index: 1;

      .wi-WebExTaskDetail-Descrip {
        page-break-inside: avoid;
        position: relative;
        > h3 {
          margin-bottom: 0;
          border-bottom: 1px solid $black;
        }
      }

      .Document {
        padding: 8px;
        border-color: $dark_blue;
        background: transparent;
        .wi-Document-title {
          color: $dark_blue;
        }
      }

      .wi-ExifImage-Container {
        max-width: $descriptionMediaSize;
        max-height: $descriptionMediaSize;

        img {
          max-height: $descriptionMediaSize;
          max-width: $descriptionMediaSize;
          margin: 0;
        }
      }

      .wi-Gallery-Container {
        img {
          height: calc(#{$galleryMediaSize} - #{$pa-Report-Gallery--gap});
          width: 100%;
          object-fit: cover;
        }
      }

      .wi-Document-Video {
        max-width: $descriptionMediaSize;
        max-height: $descriptionMediaSize;

        video {
          max-height: $descriptionMediaSize;
          max-width: $descriptionMediaSize;
          margin: 0;
          width: 100%;
        }
      }
    }
  }

  .wi-JobReportUnhiddenLabel {
    display: flex;
    align-items: center;
    margin-left: 8px;
    margin-right: 8px;
    gap: 4px;

    svg {
      width: 12px;
    }
    span {
      font-size: 12px;
      white-space: nowrap;
    }
  }
  &.wi-JobReportNode_Template .wi-JobReportNode-StepGroup .wi-JobReportNode-StepGroup {
    border: 1px solid $black;
    border-top: 0;
    background: $light_grey;
  }
  &.wi-JobReportNode_Template
    .wi-JobReportNode-StepGroup
    .wi-JobReportNode
    .wi-JobReportNode-Header-Container {
    margin-bottom: 0;
    .step-heading-title {
      padding-left: 0;
    }
  }
}

.wi-JobDetailReport-print .wi-JobReportNode-Header .step-heading {
  max-width: 85% !important;
}
