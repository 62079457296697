@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-EditorTools {
  background-color: $white;
  padding: 10px;
  border-left: 1px solid $grey;
  border-bottom: 1px solid $grey;

  .wi-EditorTools-page {
    background-color: $white;
  }

  .wi-EditorTools-Title {
    @extend %type-header-title;
  }
  .wi-EditorTools-Header {
    @include flexbox();
  }

  .wi-EditorTools-AddIcon {
    @include flexbox();
    @include addIcon();
    img {
      height: 20px;
      width: 20px;
    }
  }

  .wi-EditorTools-Display {
    opacity: 0.00000000000000001;
    transition: opacity 1s ease;

    .wi-EditorTools-Display-Tool {
      display: inline-block;
      cursor: default;
      margin-right: -5px;
      a {
        cursor: default;
      }
    }

    &.wi-EditorTools-Display-Visible {
      opacity: 1;
    }

    .Document {
      img:not(.wi-Icon) {
        max-height: 50px;
        max-width: 50px;
        margin: 5px;
        padding: 0;
      }
    }
  }
}

.wi-EditorTools-Modal {
  .wi-EditorTools-Tool {
    display: inline-block;

    img {
      max-width: 65px;
      max-height: 65px;
      margin: 5px;
    }

    .wi-Document-title {
      display: none;
    }

    .wi-Document-title {
      display: none;
    }
  }

  .wi-EditorTools-Tool:hover {
    background-color: $light_grey;
  }

  .wi-EditorTools-Tool-Active,
  .wi-EditorTools-Tool-Active:hover {
    background-color: $blue;
  }
}
