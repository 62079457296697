@import '~styles/utilities';
@import '~styles/variables';

.wi-JobTable {
  border-collapse: collapse;

  .ReactTable {
    .rt-td:nth-child(3),
    .rt-td:nth-child(4) {
      overflow: visible;
    }
  }

  .wi-RecurringIcon {
    width: 25px;
    height: 25px;
    margin-bottom: -8px;
  }
}
.wi-JobTableRow-title {
  > a {
    width: 100%;
  }

  .truncate-Container {
    min-width: 0;
    flex: 1 1 0;
  }
  .text-truncate {
    display: flex;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &:active {
    cursor: default;
  }

  .subText {
    color: $dark_grey;
    font-size: $font-size-small;
  }
}

.wi-RecurringRuleError {
  border: solid 2px $red;
}

.pa-JobActions-Container {
  display: flex;
  justify-content: flex-end;
}

.btn-dropdown {
  @include flexbox(row, flex-start);
}
.wi-TableRow-SubText,
.wi-TableDescripSubText {
  color: $dark_grey !important;
  font-size: $font-size-small;
}
.wi-JobTable-Column-LastActivity,
.wi-JobTable-Column-CreateDate,
.wi-JobTable-Column-StartDate,
.wi-JobTable-Column-ReasonForCompletion,
.wi-JobTable-Column-CanceledAt,
.wi-JobTable-Column-ArchivedAt,
.wi-JobTable-Column-Templates,
.wi-JobTable-Column-Deviations,
.wi-JobTable-Column-Issues {
  @include singleLineTextEllipsis();
}
