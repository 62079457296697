@import '~styles/variables';
@import '~styles/utilities';

.wi-Document,
.wi-Document-Video,
.wi-Document-Audio {
  page-break-inside: avoid;
}
.DocumentVideo {
  background: $light_grey;
  border: 1px solid $grey;
  margin: 10px 0;
  padding: 4px 8px;
  align-items: center;
}

.Document {
  font-size: $font-size-small;
  flex-grow: 1;
  page-break-inside: avoid;

  a {
    color: $charcoal;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }

  & > .wi-Document-Uploading {
    @include flexbox(column, flex-start, center);
    span {
      font-size: 18px;
    }
  }

  .wi-DocumentList & > .wi-Document-content-wrapper,
  &.Document-Preview > .wi-Document-content-wrapper {
    @include flexbox(row, flex-start, center);
    min-height: 50px;
    &.wi-Document-Uploading {
      padding-top: 0;
    }
  }

  i {
    font-size: 18px;
    margin: 0 6px 0 0;
  }

  .wi-Document-title {
    @include singleLineTextEllipsis();
    font-size: 14px;
    flex: 1;
    margin: 0 0 0 25px;
  }

  .wi-Document-caption {
    @include singleLineTextEllipsis();
    text-align: center;
    flex: 1 1 100%;
    font-size: $font-size-small;
  }

  .wi-Document-timestamp {
    @include singleLineTextEllipsis();
    text-align: center;
    flex: 1 1 100%;
    font-size: $font-size-micro;
  }

  .wi-Document-doc-icon {
    height: 50px;
    width: 50px;
    display: flex;
    background: $light_grey;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey;
  }

  .wi-Document-sub-title {
    font-size: 12px;
    color: $dark_grey;
  }

  img + .wi-Document-title {
    margin-left: 10px;
  }

  img,
  video {
    max-height: 50px;
    max-width: 50px;
  }

  &.Document-Thumbnail {
    img,
    video {
      max-height: 50px !important;
      max-width: 50px !important;
      margin: 4px;
    }
  }

  img:not(.wi-Icon),
  video:not(.wi-Icon) {
    margin: 4px;
  }

  video:not(.wi-Icon) {
    &::-webkit-media-controls {
      display: none !important;
    }
  }

  .wi-DocumentDelete {
    margin-left: auto;
  }

  &:hover {
    .wi-DocumentDelete {
      cursor: pointer;
    }
  }
}

.Document-Bordered,
.Document-Preview {
  border: 1px solid $blue;
  border-radius: 2px;
  margin: 10px 0;
}

.wi-JobDetailReport {
  background-color: $light_grey;
  .wi-Document-Border {
    background-color: $white;
    border: 1px solid $grey;
    width: 99%;
    @include flexbox();
    padding: 6px;
  }

  .wi-Document-content-wrapper {
    .img {
      width: 40px;
      height: 40px;
    }
  }
  .container {
    box-shadow: 0 0 26px rgba(0, 0, 0, 0.06);
  }
}

.wi-Document-content-wrapper {
  @include flexbox(column, center, center);
}

.wi-Document-Border {
  @include flexbox();
}
