@import '~styles/variables';
@import '~styles/utilities';

$MODAL_MARGIN: 60px;
$MODAL_PADDING: 20px;

$MIN_HEIGHT: 400px;

$MODAL_WIDTH_SM: 320px;
$MODAL_WIDTH_MD: 620px;
$MODAL_WIDTH_LG: 920px;
$MODAL_WIDTH_FULL: 100%;

$MODAL_BREAKPOINT: $MIN_HEIGHT + $MODAL_MARGIN * 4;

// prevent body scrolling when a window is open
.ReactModal__Body--open {
  overflow: hidden;
}

// make sure modals are above everything else!
.ReactModalPortal {
  z-index: 500;
  position: relative;
}

.ReactModalWithOverflow {
  .well {
    overflow-y: visible !important;
  }
}

//Overrides
.ReactModal__Content {
  input {
    margin-bottom: 0;
  }
}

.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(100, 100, 100, 0.5);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding: $MODAL_MARGIN;

  // if browser is larger than the minimum modal
  // height, allow it to be vertically aligned.
  @media (min-height: $MODAL_BREAKPOINT) {
    align-items: center;
  }
}

.ReactModal__Content {
  width: $MODAL_WIDTH_MD;
  max-height: calc(100vh - #{$MODAL_MARGIN * 2});
  min-height: $MIN_HEIGHT;
  position: static;
  background-color: $white;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;

  // target IE 10 - 11 only (ie isn't very good with min/max
  // height for flexbox - we need to specify height explicitly)
  // https://parsable.atlassian.net/browse/PA-9716
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    height: calc(100vh - #{$MODAL_MARGIN * 2});
    max-height: $MIN_HEIGHT + $MODAL_MARGIN;
  }

  &.size_sm {
    width: $MODAL_WIDTH_SM;
  }

  &.size_lg {
    width: $MODAL_WIDTH_LG;
  }

  &.size_full {
    width: $MODAL_WIDTH_FULL;
  }

  &:focus {
    outline: 0;
  }

  label {
    font-size: $font-size-base;
    color: $charcoal;
  }

  form {
    margin: 0;
  }

  .wi-Input input {
    width: 100%;
  }

  .wi-MetadataLabel {
    color: $charcoal;
    padding-top: 10px;
  }

  .wi-Modal-ExtraContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .wi-ModalHeader,
  .well,
  .wi-ModalFooter {
    padding: $MODAL_PADDING;
    width: 100%;
  }

  .wi-ModalHeader {
    color: $white;
    background-color: var(--primaryB, $dark_blue);
    text-transform: capitalize;
    @include flexbox(row, flex-start, center);
    flex: 0 0 auto;

    &.wi-ModalHeader_spaceApart {
      justify-content: space-between;
    }

    h1 {
      margin: 0;
      line-height: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--secondaryC, $white);
      font-size: $font-size-h2;
      font-weight: $font-weight-mbold;
    }

    .wi-Icon {
      align-self: flex-start;
      margin: 10px;
      svg {
        width: 29px;
        height: 29px;
      }

      &:hover {
        @extend %wi-Icon--blue;
      }
    }
  }

  .wi-ModalHeader-subtitle {
    color: $light_grey;
    font-size: $font-size-small;
    margin-left: 6px;
    display: inline-block;
  }

  .well {
    background-color: transparent;
    border: none;
    box-shadow: none;
    margin: 0;
    //overflow-x: hidden;
    overflow-y: auto;
    flex: 1 1 auto;
    position: relative;
  }

  .wi-FormGroup.u-hidden {
    display: none !important;
  }

  .wi-ModalFooter {
    @include flexbox(row-reverse, space-between, center);
    background-color: $light_grey;
    flex: 0 0 auto;
  }
}
