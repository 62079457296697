@import '~styles/variables';

$animation-delay: 0.15s;
$track-length: 40px;
$track-height: 22px;
$thumb-spacing: 1px;
$thumb-diameter: $track-height - ($thumb-spacing * 2);

.pa-toggle {
  display: inline-block;
  position: relative;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  padding: 0;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.pa-toggle--disabled {
  opacity: 0.5;
  -webkit-transition: opacity $animation-delay;
  transition: opacity $animation-delay;
  cursor: not-allowed;
}

.pa-toggle-track {
  width: $track-length;
  height: $track-height;
  padding: 0;
  border-radius: $track-height;
  background-color: $charcoal;
  -webkit-transition: all $animation-delay ease;
  -moz-transition: all $animation-delay ease;
  transition: all $animation-delay ease;
}

.pa-toggle--checked .pa-toggle-track {
  background-color: var(--secondaryA, $green);
}

.pa-toggle--checked .pa-toggle-thumb {
  left: $track-length - ($thumb-diameter + $thumb-spacing);
}

.pa-toggle--checked {
  opacity: 1;
  -webkit-transition: opacity $animation-delay ease;
  -moz-transition: opacity $animation-delay ease;
  transition: opacity $animation-delay ease;
}

.pa-toggle-thumb {
  position: absolute;
  top: $thumb-spacing;
  left: $thumb-spacing;
  width: $thumb-diameter;
  height: $thumb-diameter;
  border-radius: 50%;
  background-color: $white;
  box-sizing: border-box;
  transition: all $animation-delay ease;
}
