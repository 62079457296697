@import '~styles/utilities';
@import '~styles/variables';

.wi-JobTableRow-title {
  width: 100%;
  > a {
    width: 100%;
  }

  .truncate-Container {
    flex: 1 1 0;
  }

  &:active {
    cursor: default;
  }

  &:complete {
    cursor: default;
    color: $charcoal;
  }

  .subText {
    color: $dark_grey;
    font-size: $font-size-small;
  }
}

.pa-JobTableRow-issueType {
  .wi-Icon {
    margin-right: 5px;
    vertical-align: text-bottom;
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.pa-JobTableRow-priority {
  padding: 3px 6px;
  border-radius: 100px;
  color: white;
  font-weight: $font-weight-semibold;
  font-size: $font-size-small;
  border: 1px inset rgba(0, 0, 0, 0.1);
  letter-spacing: 1px;
  white-space: nowrap;

  &.urgent {
    background-color: $red;
  }

  &.high {
    background-color: $orange;
  }

  &.medium {
    background-color: $blue;
  }

  &.low {
    background-color: $teal;
  }
}

.tooltipTemplateCount {
  border-bottom: 1px dotted $dark_grey;
  display: inline-block;
  padding: 0px 3px;
  line-height: 1.5;
  @include singleLineTextEllipsis();
  &:hover {
    cursor: default;
  }
}
.tooltipTemplateCount__items {
  display: flex;
  flex-direction: column;
}

.wi-JobTableRow-lookupId,
.wi-JobTableRow-CreateDate,
.wi-JobTableRow-jobType,
.pa-JobTableRow-date,
.pa-JobRowSteps,
.pa-JobTableRow-lastActivity,
.pa-JobTableRow-archivedDate,
.pa-TemplateTitles-Subtext,
.wi-TableRow-SubText {
  @include singleLineTextEllipsis();
}
