@import '~styles/colors.scss';

.pa-EmailReportModal {
  .pa-EmailReportModal__Description {
    padding-bottom: 24px;
    line-height: 1;
  }

  .pa-EmailReportModal__WarningMessage {
    color: $red;
  }

  .wi-ModalFooter__Right {
    display: flex;
    align-items: center;
  }
}
