.wi-ReferenceMatchOptionListEditor {
  &__item {
    &:nth-of-type(n + 2) {
      margin-top: 8px;
    }

    &__input {
      position: relative;
      border: 1px solid #d6d6d6;
      border-radius: 4px;

      &--deletable {
        padding-right: 36px;
      }

      &--invalid {
        border-color: #db2c33;
      }

      &:focus-within {
        border-color: #19baf2;
      }

      & > .pa-RefField-Single {
        padding: 7px 10px;
        border: none;
      }

      &__deleteIconButton {
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
        width: 36px;
        height: 36px;
        justify-content: center;
        cursor: pointer;
      }
    }

    &__helperText {
      margin-top: 2px;
      color: #d6d6d6;
      font-size: 11px;

      &--invalid {
        color: #db2c33;
      }
    }
  }
}
