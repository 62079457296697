@import '~styles/variables';
@import '~styles/utilities';

$padding-from-sides: 10px;
#wi-JobRoleDetail {
  .wi-JobRoleDetail-contentContainer {
    @include flexbox(column, space-between, stretch);
    @include boxify();
    background-color: $white;
    margin: 30px auto 0;
    max-width: 1024px;
    padding: 0;
    position: relative;
    width: 90%;
  }

  .role-container {
    position: relative;
    width: 100%;
  }

  .button-container {
    @include flexbox(column, flex-start, flex-end);
    background-color: $white;
    border-bottom-left-radius: $border-radius-standard;
    border-bottom-right-radius: $border-radius-standard;
    border-top: $border-light;
    padding: $padding-from-sides;
    width: 100%;
  }

  .pa-JobRoleDetail-bodyContainer {
    @include flexbox(column, flex-start, flex-start);
    padding: $padding-from-sides * 2;
    width: 100%;
  }
  .pa-JobRoleDetail-permissionsWrapper {
    @include flexbox(column, flex-start, flex-start);
    width: 100%;
  }
  .pa-JobRoleDetail-permissions-titleWrapper {
    @include flexbox(row, space-between);
    border-bottom: $border-light;
    width: 100%;
  }
  .pa-JobRoleDetail-permissions-titleText {
    font-size: $font-size-large;
    padding-bottom: 5px;
  }

  .pa-JobRoleDetail-restrictedWrapper {
    @include flexbox(column, flex-start, flex-start);
    width: 100%;
  }
  .pa-JobRoleDetail-restricted-titleWrapper {
    @include flexbox(row, space-between);
    width: 100%;
    border-bottom: $border-light;
    padding-bottom: 5px;
  }
  .pa-JobRoleDetail-restricted-titleText {
    font-size: $font-size-large;
  }
  .pa-JobRoleDetail-restricted-toggle {
    @include flexbox(row, flex-end);
  }

  .pa-JobRoleDetail-restricted-usersWrapper {
    @include flexbox(row, space-between);
    width: 100%;
  }

  .pa-JobRoleDetail-restricted-users {
    width: 100%;
  }

  .pa-RestrictedUsers-emptyText {
    padding-top: 8px;
    margin-bottom: 8px;
    font-size: $font-size-base;
    font-style: italic;
    width: 100%;
  }

  .pa-RestrictedUsers-restrictionInfo {
    padding-top: 16px;
    margin-bottom: 8px;
    font-size: $font-size-base;
    width: 100%;

    .pa-RestrictedUsers-restrictionInfo-restrictedText {
      border-bottom: $border-light;
      padding-bottom: 16px;
      .pa-RestrictedUsers-restrictionInfo-restrictedText-firstSentence {
        font-weight: $font-weight-mbold;
      }
      .pa-RestrictedUsers-restrictionInfo-restrictedText-removeUsersLink {
        cursor: pointer;
        color: $blue;
      }
    }
  }
}

.pa-RestrictedUsers-container {
  @include flexbox(column, space-between, stretch);

  .pa-RestrictedUsers-list {
    margin-bottom: -1px;
  }

  .pa-RestrictedUser-container {
    @include flexbox(row, space-between);
    padding: $padding-from-sides 0;
    border-bottom: $border-light;
  }
  .pa-RestrictedUser-name {
    font-size: $font-size-base;
  }
  .pa-RestrictedUser-rightSide {
    @include flexbox(row, flex-end, flex-end);
  }
  .pa-RestrictedUser-removeBtn {
    cursor: pointer;
    height: 20px;
  }
  .pa-RestrictedUser-email {
    font-size: $font-size-small;
    margin-right: 20px;
  }
}

.pa-AddPermittedUser-Wrapper {
  @include flexbox(row, flex-start, center);
  margin-top: $padding-from-sides;
}
