@import '~styles/variables';

.pa-StatusBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  line-height: $pa-StatusBanner--height;
  background-color: $charcoal;
  height: $pa-StatusBanner--height;

  a {
    color: $white;
    cursor: pointer;
    text-decoration: underline !important; // 😞

    &:hover {
      text-decoration: underline !important; // 😞
    }

    &.pa-statusBanner-offline {
      padding-left: 3px;
      font-weight: 500;
    }
  }

  &--success {
    background-color: $dark_green;
  }
  &--offline {
    background-color: $charcoal;
  }
  &--warning {
    background-color: $dark_red;
  }
  &--info {
    background-color: var(--secondaryB, $blue);
  }

  .wi-Icon {
    display: inline-block;
    margin-right: 10px;

    svg {
      display: flex;
      height: 14px;
    }

    path {
      fill: $white;
    }
  }
}
