.wi-PopoverMenu {
  min-width: 64px;
  background-color: #fff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;

  &[data-theme] {
    color: inherit;
  }

  & > .tippy-content {
    padding: 0;

    & > div {
      & > *:first-of-type {
        border-top-left-radius: 3px;
        border-top-right-radius: 3px;
      }

      & > *:last-of-type {
        border-bottom-left-radius: 3px;
        border-bottom-right-radius: 3px;
      }
    }
  }
}

.wi-PopoverMenu-Wrapper {
  display: inline-block;
}
