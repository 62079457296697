@import '~styles/variables';

.wi-TeamFail {
  h1 {
    font-size: 24px;
    margin-bottom: 24px;
  }

  .Button {
    margin-bottom: 24px;
  }
}
