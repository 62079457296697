@import './TemplateDiffReportUtils';

.wi-BaseStep-Wrapper {
  &.wi-BaseStep-AddedStep {
    @include diffImgAdded();
    padding: 0 5px 4px 4px;
  }

  &.wi-BaseStep-RemoveStep {
    @include diffImgRemoved();
    padding: 0 5px 4px 4px;
  }
}
