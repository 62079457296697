@import '~styles/variables';

.d3-body {
  position: relative;

  .d3-graph_editing & {
    margin-top: 20px;
  }
}

.d3-graph_editing .d3-loading svg {
  width: 40px;
  height: 40px;
}

.d3-messages {
  pointer-events: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;

  > * {
    flex: 1 1 auto;
  }
}

.d3-error,
.d3-loading {
  text-align: center;
  z-index: 3;
}

.d3-error {
  color: red;
}
