@import '~styles/variables';
@import '~styles/utilities';

$padding-editable: 3px;

.wi-InlineEditor-boundingInput {
  @include flexbox(row, flex-start);

  .wi-InlineEditor-innerInput {
    @include flexbox(row, flex-start);
    margin-left: -$padding-editable;
    padding: ($padding-editable - 1px) $padding-editable;

    &.wi-InlineEditor-active {
      flex-grow: 2;
    }

    input {
      flex: 1 1 auto;
      width: 100%;
      font-size: inherit;
      padding: 0;
      margin: 0;
    }
  }

  .wi-InlineEditor-hover {
    background-color: $light_grey;
  }

  .wi-InlineEditor-parentInput {
    flex: 1 1 auto;
  }

  .wi-InlineEditor-iconWrap {
    margin-left: 10px;
    @include flexbox(row, center, center);
  }
}

.wi-dummy {
  position: absolute !important;
  top: -999em;
  left: -999em;
  visibility: hidden !important;
  overflow: scroll;
  white-space: pre;
}
