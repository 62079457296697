@import '~styles/variables';
@import '~styles/utilities';
@import '~styles/colors';

#Workflows {
  height: 100%;
  min-width: 1024px;
  min-height: 600px;
  display: flex;
  flex-direction: column;

  .blank-slate {
    margin-top: 40px;
  }

  .pa-editorLayout-body-container {
    display: flex;
  }

  .pa-editorLayout-body {
    width: 100%;
    background-color: $light_grey;
  }

  .pa-editorLayout {
    flex: 1;
  }

  .pa-editorLayout .pa-editorLayout-title .wi-Icon {
    position: relative;
    top: 1px;
    margin-left: 10px;
  }

  .pa-editorLayout .pa-editorLayout-title {
    display: flex;
  }

  .pa-workflowEditor {
    flex: 1;
    display: flex;
    flex-direction: row;
    background-color: white;
    margin: 10px;
    border: 1px solid $grey;
    border-radius: 3px;
    position: relative;
  }

  #workflow-editor-status {
    position: absolute;
    right: 80px;
    height: 57px;
    line-height: 57px;
    color: $charcoal;
    font-size: $font-size-base;
    font-weight: normal;
  }

  #editor-container {
    display: flex;
    flex: 1;
    position: relative;

    #editor {
      flex: 1;
    }

    .djs-palette {
      display: flex;
      width: auto;
      top: 0;
      left: 0;
      width: 100%;
      border: none;
      border-bottom: 1px solid $grey;
      background-color: $light_grey;
      border-radius: 0;

      .djs-palette-entries {
        display: flex;

        .group {
          display: flex;

          &:first-child {
            margin-left: 5px;
          }
        }

        .entry {
          float: none;
          font-size: 24px;
          height: 56px;
          line-height: 56px;
          width: 40px;
          background-repeat: no-repeat;
          background-position: center center;

          &.disabled {
            cursor: not-allowed;
          }
        }

        .hand-tool {
          background-image: url(../../../../styles/icons/css/ic_hand_tool.svg);
          &:hover {
            background-image: url(../../../../styles/icons/css/ic_hand_tool_hover.svg);
          }
          &.disabled {
            background-image: url(../../../../styles/icons/css/ic_hand_tool_disabled.svg);
          }
        }
        .connection {
          background-image: url(../../../../styles/icons/css/ic_connection.svg);
          &:hover {
            background-image: url(../../../../styles/icons/css/ic_connection_hover.svg);
          }
          &.disabled {
            background-image: url(../../../../styles/icons/css/ic_connection_disabled.svg);
          }
        }
        .lasso-tool {
          background-image: url(../../../../styles/icons/css/ic_lasso_tool.svg);
          &:hover {
            background-image: url(../../../../styles/icons/css/ic_lasso_tool_hover.svg);
          }
          &.disabled {
            background-image: url(../../../../styles/icons/css/ic_lasso_tool_disabled.svg);
          }
        }
        .space-tool {
          background-image: url(../../../../styles/icons/css/ic_space_tool.svg);
          &:hover {
            background-image: url(../../../../styles/icons/css/ic_space_tool_hover.svg);
          }
          &.disabled {
            background-image: url(../../../../styles/icons/css/ic_space_tool_disabled.svg);
          }
        }
        .parallel-gateway {
          background-image: url(../../../../styles/icons/css/ic_parallel_gateway.svg);
          &:hover {
            background-image: url(../../../../styles/icons/css/ic_parallel_gateway_hover.svg);
          }
          &.disabled {
            background-image: url(../../../../styles/icons/css/ic_parallel_gateway_disabled.svg);
          }
        }
        .exclusive-gateway {
          background-image: url(../../../../styles/icons/css/ic_xor_gateway.svg);
          &:hover {
            background-image: url(../../../../styles/icons/css/ic_xor_gateway_hover.svg);
          }
          &.disabled {
            background-image: url(../../../../styles/icons/css/ic_xor_gateway_disabled.svg);
          }
        }
        .task-parsable-job {
          background-image: url(../../../../styles/icons/css/ic_job_activity.svg);
          &:hover {
            background-image: url(../../../../styles/icons/css/ic_job_activity_hover.svg);
          }
          &.disabled {
            background-image: url(../../../../styles/icons/css/ic_job_activity_disabled.svg);
          }
        }
        .task-parsable-issue {
          background-image: url(../../../../styles/icons/css/ic_issue_activity.svg);
          &:hover {
            background-image: url(../../../../styles/icons/css/ic_issue_activity_hover.svg);
          }
          &.disabled {
            background-image: url(../../../../styles/icons/css/ic_issue_activity_disabled.svg);
          }
        }
        .end-event {
          background-image: url(../../../../styles/icons/css/ic_end_event.svg);
          &:hover {
            background-image: url(../../../../styles/icons/css/ic_end_event_hover.svg);
          }
          &.disabled {
            background-image: url(../../../../styles/icons/css/ic_end_event_disabled.svg);
          }
        }
      }

      .separator {
        border: none;
        border-left: 1px solid $grey;
        margin: 16px 5px;
      }
    }

    .djs-direct-editing-parent {
      background: transparent !important;
      color: black;
    }
  }

  #workflow-editor-sidebar {
    display: flex;
    flex-direction: column;
    position: relative;

    .workflow-editor-sidebar-toggle {
      position: absolute;
      border-bottom: 1px solid $grey;
      background-color: $light_grey;
      height: 57px;
      right: 0;
      width: 320px;
      display: flex;
      justify-content: flex-end;

      .wi-Icon {
        margin-right: 16px;

        svg {
          height: 24px;
          width: 24px;
        }

        path {
          fill: $charcoal;
          transition: none;
        }

        &:hover {
          path {
            fill: #0085c1;
          }
        }
      }
    }

    .workflow-editor-sidebar-content {
      display: flex;
      flex-direction: column;
      flex: 1;
      width: 320px;
      margin-top: 57px;
      background-color: $light_grey;
      border-left: 1px solid $grey;
      padding: 16px;
      overflow-y: auto;

      .no-selection {
        flex: 1;
        align-self: center;
        text-align: center;

        color: #d3d7d7;
        font-size: 20px;
        font-weight: 600;
      }

      .group {
        margin-bottom: 24px;
      }
      .spaceTop {
        margin-top: 24px;
      }

      label {
        display: block;
        color: $charcoal;
      }
      .group > input[type='text'] {
        display: block;
        margin-top: 4px;
        margin-bottom: 0;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid $grey;
        height: 38px;
        line-height: 38px;
        width: 100%;

        &[disabled] {
          background-color: $light_grey;
        }
      }

      .object-type {
        color: $black;
      }

      .note {
        color: $dark_grey;
      }

      .Select {
        margin: 8px 0;
      }
      .wi-Select-Container {
        margin: 8px 0;

        .react-select__control {
          height: 36px;
        }
      }

      .job-description {
        .jd-name {
          color: $black;
        }
        .jd-description {
          color: $dark_grey;
        }
      }

      .completion-codes {
        display: flex;
        flex-direction: column;
        flex: 1;

        .title {
          font-weight: 500;
        }

        .completion-codes-container {
          display: flex;
          flex-direction: column;
          flex: 1;
          margin-top: 4px;

          .completion-codes-list {
            display: flex;
            flex-direction: column;
            flex: 1;
            border: 1px solid $grey;
            border-radius: 4px;
            background-color: white;
            overflow-y: auto;

            table {
              margin: 0;
              border-spacing: 0;

              td {
                padding: 7px 10px 7px 0;
                height: 36px;
                width: 100%;
                vertical-align: top;
                position: relative;

                &:first-child {
                  padding-left: 12px;
                  min-width: 62px;
                  width: auto;
                }

                .completion-code-remove {
                  position: absolute;
                  right: 0;
                  top: 0;
                  height: 100%;
                  align-items: center;
                  display: none;

                  &:before {
                    content: '';
                    width: 8px;
                    height: 100%;
                    background: linear-gradient(to right, transparent, white);
                  }

                  .wi-Icon {
                    padding-right: 7px;
                    padding-left: 7px;
                    background-color: white;
                    height: 100%;
                  }
                }

                &:hover .completion-code-remove {
                  display: flex;
                }
              }
            }

            .no-complation-codes {
              display: flex;
              flex-direction: column;
              flex: 1;
              align-items: center;
              text-align: center;
              justify-content: center;
              padding: 10px;
              color: $grey;

              .title {
                height: 16px;
                font-weight: 600;
                margin-bottom: 9px;
              }
            }
          }

          .wi-Select-Container {
            margin-bottom: 0;
          }

          .select-option--value {
            display: flex;

            span {
              display: inline-block;
              min-width: 50px;
              padding-right: 10px;
            }
          }
        }
      }
    }
  }

  .pa-workflow-zoom-controls {
    position: absolute;
    bottom: 70px;
    right: 15px;
    display: flex;
    flex-direction: column;

    button {
      height: 32px;
      width: 24px;
      font-size: 22px;
      padding: 0;
      line-height: normal;
      border-radius: 3px;
      border-color: $dark_grey;
      font-weight: normal;
      margin-bottom: 10px;

      span {
        position: relative;
        top: -1px;
      }

      &:hover {
        border-color: $dark_grey;
        color: $lochmara;
      }
    }
  }
}
