@import '~styles/variables';

.wi-UserTableRole {
  color: $charcoal;
  font-size: 14px;
  padding: 6px 24px 2px;
  text-transform: uppercase;
}

.wi-UserTableInset {
  background-color: $white;
  border-top: 1px solid $grey;
  font-size: 13px;
  margin-bottom: 20px;

  img {
    margin-right: 5px;
  }

  .Select {
    .Select-placeholder,
    .Select--single > .Select-control .Select-value {
      line-height: 40px;
    }

    .Select-input {
      height: 40px;

      > input {
        padding-top: 12px;
      }
    }
  }
}

.wi-UserTableUser {
  border-bottom: 1px solid $grey;
  padding: 8px 24px 8px 42px;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
  }
}

.wi-UserTableUserName {
  flex-grow: 3;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  color: $black;
  .wi-UserTableUserEmail {
    margin-right: 20px;
    color: $charcoal;
    font-size: 0.9em;
  }
}

.wi-UserTableUserRemove {
  flex-grow: 1;
  display: flex;
  cursor: pointer;
  transform: scale(0.9, 0.9);
  justify-content: flex-end;
}

button.wi-UserTableAdd {
  margin-top: 10px;
  margin-left: 10px;
}

.wi-JobUserTable .wi-FullWidthTable tbody {
  tr td {
    padding-left: 35px;
  }

  tr.Table-SubheaderRow td {
    padding-left: 1rem;
  }
}
