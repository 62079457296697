@import '~styles/variables';

.wi-Split-Button {
  display: inline-flex;
  position: relative;

  .wi-Split-Button__group {
    display: flex;
    button:not(:last-child),
    a:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
    button:not(:first-child),
    a:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .wi-Split-Button-Primary {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  .wi-Split-Button-Icon {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    width: auto;
    min-width: unset;
  }
}
