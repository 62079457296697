@import '~styles/variables';

.wi-Required {
  color: $red;
  display: inline-block;
  position: relative;
  right: -4px;
  top: -2px;
}

.wi-RequiredBadge {
  color: $red;
  height: 6px;
  width: 6px;
  border-radius: 6px;
  position: relative;
  top: -3px;
  left: 12px;
}

.wi-RequiredLegend strong {
  position: relative;
  left: 5px;
}
