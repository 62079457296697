@import '~styles/variables';
@import '~styles/utilities';

@mixin wi-at-mention-border-radius($radius) {
  border-top-right-radius: $radius;
  border-top-left-radius: $radius;
  border-bottom-right-radius: $radius;
  border-bottom-left-radius: $radius;
}

.wi-AtMentionContainer {
  position: relative;
  box-sizing: border-box;
  div {
    box-sizing: border-box;
  }
}

.wi-AtMention-menu-outer {
  @include wi-at-mention-border-radius(6px);
  overflow-y: auto;
  border: 0px;
  position: absolute;
  top: 100%;
  width: 100%;
  z-index: 1;
  margin-top: -5px;
}

.wi-at-mention-is-expanded {
  background-color: $white;
  border: solid 1px;
  border-color: $border-standard;
  max-height: 138px;
  box-shadow: 2px 4px 10px 0 rgba(0, 0, 0, 0.25);
}

.wi-at-mention-option-label {
  color: $text_color;
}

.wi-at-mention-is-header {
  background-color: $grey;
}

.wi-at-mention-option-header {
  color: $dark_grey;
  font-size: 0.75rem;
  text-transform: uppercase;
}

.wi-at-mention-option-label-subText {
  margin-left: 10px;
}

.wi-at-mention-is-selected {
  background-color: $light_blue;

  .wi-at-mention-option-label {
    color: $white;
  }
}

.AtMention-option {
  border-bottom: solid 1px $grey;
  padding: 9px 10px;
  height: 40px;
  cursor: pointer;
  display: block;
}
