@import '~styles/colors';
@import '~styles/utilities';

.pa-WorkflowTable {
  .pa-Workflow-description {
    color: $dark_grey;
    font-size: $font-size-small;
  }

  .pa-WorkflowTable-Column-Name {
    > a {
      width: 100%;
    }

    .truncate-Container {
      flex: 1 1 0;
    }
  }

  .pa-WorkflowTable-Column-Launch {
    display: flex;
    justify-content: flex-end;
    cursor: auto;
  }

  .pa-WorkflowTable-Column-Published,
  .pa-WorkflowTable-Column-Archived,
  .pa-WorkflowTable-Column-Updated {
    @include singleLineTextEllipsis();
  }
}
