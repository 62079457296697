@import '~styles/variables';
@import '~styles/utilities.scss';

.pa-Thread-Anchor {
  .pa-Thread-Anchor-Container {
    @include flexbox();
    position: absolute;
    right: -11px;
    top: 35px;
    width: 350px;
    z-index: 100;
    background: $white;
    border: 1px solid $grey;
    border-radius: 6px;
    box-sizing: content-box;

    .pa-Thread-Anchor-Container-Inner {
      width: 100%;
    }
  }

  // These subsequent psudo-classes are all to create the callout
  .pa-Thread-Anchor-Container:after,
  .pa-Thread-Anchor-Container:before {
    bottom: 100%;
    right: 20px;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  .pa-Thread-Anchor-Container:after {
    border-color: transparent;
    border-bottom-color: $white;
    border-width: 9px;
    margin-right: -9px;
  }
  .pa-Thread-Anchor-Container:before {
    border-color: transparent;
    border-bottom-color: $grey;
    border-width: 10px;
    margin-right: -10px;
  }
}
