@import '~styles/colors';

.NavTooltip {
  width: 320px;
  height: auto;
  opacity: 1;

  .rc-tooltip-content {
    height: 100%;
    font-family: 'proxima-nova';

    p,
    span {
      margin: 0;
    }

    .rc-tooltip-arrow {
      top: 77%;
      border-right-color: $white;
    }

    .rc-tooltip-inner {
      padding: 15px;
      box-shadow: 0px 0px 10px 0px rgba(0, 134, 191, 0.5);
      border-radius: 8px 8px 0px 0px;
      background-color: $white;
      height: 100%;
      color: $parsable-grey-400;

      .pa-ContextHelpMenu-close-container {
        margin-bottom: 15px;
        padding-bottom: 0px;
        height: 15.35px;
        .pa-ContextHelpMenu-close {
          float: right;
          color: $dark_grey;
          font-size: 20px;
          width: 15.36px;
          height: 15.35px;
          text-align: center;
          font-weight: 200;
        }
      }

      .flexboxgrid--row.pa-ContextHelpMenu-option {
        margin-bottom: 6px;
        min-height: 80px;
        margin-left: 0;
        margin-right: 0;
        cursor: pointer;

        .pa-ContextHelpMenu-icon {
          padding: 0;
          text-align: center;

          svg {
            width: 34px;
            height: 34px;
          }
        }

        .pa-ContextHelpMenu-content {
          padding: 5px;

          .pa-ContextHelpMenu-row-title {
            color: $blue;
            font-size: 16px;
            font-weight: 600;
          }
        }
      }

      .flexboxgrid--row.pa-ContextHelpMenu-option:hover {
        box-shadow: 0px 0px 10px 0px rgba(0, 134, 191, 0.5);
      }
    }
  }
}
