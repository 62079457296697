@import '~styles/variables.scss';
@import '~styles/colors.scss';

// some react-select overrides
.Select,
.Select-menu {
  .Select-placeholder {
    color: $dark_grey;
  }
  &.is-disabled {
    .Select-placeholder,
    .Select-arrow-zone {
      color: $charcoal !important;
    }
    > .Select-control,
    .Select-arrow,
    .Select-arrow-zone {
      background-color: $light_grey !important;
      cursor: not-allowed;
    }
  }

  &.is-focused {
    .Select-control {
      border: 1px solid var(--secondaryA, $blue);
    }
  }

  .Select-input:focus {
    outline: 0;
  }

  .Select-value {
    color: $charcoal !important;
  }

  .Select-option.is-focused {
    background: $highlight_grey !important;
  }

  .Select-option:hover {
    background: $highlight_grey !important;
  }

  .Select-option.is-selected {
    background-color: var(--secondaryA, $blue) !important;
    color: $white !important;
  }

  .Select-option span {
    word-break: break-all;
  }

  &.Select--multi {
    .Select-value {
      border: 1px solid var(--secondaryA, $blue);
      background-color: var(--secondaryA, $blue);

      .Select-value-icon {
        border-right: 1px solid var(--secondaryA, $blue);
        color: $white;
        padding: 2px 5px 2px;
      }

      .Select-value-label {
        color: $white;
      }
    }
  }

  &.Select--multi.is-disabled {
    .Select-value {
      .Select-value-label {
        color: $charcoal;
      }
    }
  }
}

.tethered-select-options {
  z-index: 1111;
}

.pa-Select-withIcon {
  display: flex;
  align-items: center;

  .wi-Icon {
    padding-right: 8px;

    svg {
      height: 14px;
      width: 14px;
    }
  }
}

.react-select__indicator-separator {
  display: none;
}

.is-focused:not(.is-open) > .Select-control {
  border: 1px solid var(--secondaryA, $blue) !important;
}
