@import '~styles/utilities';
@import '~styles/variables';

.Analytics-container {
  form,
  input,
  .wi-Input--With-Message {
    margin-bottom: 0;
  }
}

.Analytics-Description {
  @extend %type-text-regular;
  margin-bottom: 1.5rem;
}

.Analytics-Export-Legacy {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.Analytics-Section-Title {
  border-bottom: 1px solid $light_grey;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;

  h2 {
    font-size: 30px;
    color: $black;
    margin: 0;
    font-weight: $font-weight-light;
  }
}

@media print {
  .Analytics {
    width: 670px;
  }
}
