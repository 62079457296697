@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-StepDescription {
  border-radius: 3px;

  figure {
    margin: 0px 5px 0px 5px;
  }

  .wi-StepDescription-Contents {
    margin-bottom: 30px; //For spacing between the input field header
  }

  &.wi-StepDescription-Editing {
    background-color: $light_grey;
    border: 1px solid $grey;

    .wi-StepDescription-Contents {
      padding: 10px;
      border: 1px solid $grey;
    }

    .wi-StepDescription-Controls {
      display: flex;
      height: 30px;
      cursor: pointer;
      .wi-Icon {
        svg {
          width: 16px;
          height: 16px;
        }
      }

      & > div {
        line-height: 1;
        margin: 1px;
      }
    }

    .wi-StepDescription-Contents {
      min-height: 60px;
      background-color: $white;
      margin-bottom: 30px;
    }
  }

  .wi-StepDescription-Controls {
    display: none;
  }
}
