@import '~styles/variables';

.wi-Pill {
  border-radius: 8px;
  color: $charcoal;
  background-color: $white;
  border: 1px solid $charcoal;
  font-size: $font-size-micro;
  line-height: 1.3rem;
  padding: 0 8px;
  text-transform: uppercase;
  z-index: 1;
  margin-top: 4px;
  text-align: center;
  max-width: 100px;
}

.wi-Pill--Success {
  background-color: $green;
  color: $white;
  border: none;
}

.wi-Pill--Canceled {
  background-color: $orange;
  color: $white;
  border: none;
}

.wi-Pill--Info {
  background-color: $charcoal;
  color: $white;
  border: none;
}

.wi-Pill--Large {
  border-radius: 68px;
  font-size: 13px;
  font-weight: $font-weight-regular;
  height: 22px;
  line-height: 22px;
  width: 96px;
  text-transform: none;
}
