@import '~styles/variables';
@import '~styles/utilities.scss';

.tethered-refPicker {
  z-index: 1111;
  &.tether-element-attached-top {
    top: 7px !important;
    left: -9px !important;
  }

  &.tether-element-attached-bottom {
    top: -6px !important;
    left: -9px !important;
  }
}

.pa-RefPicker-Container {
  @include flexbox();
  @extend %type-text-regular;
  @include zoomAnimation();
  transform-origin: top left;
  animation-timing-function: cubic-bezier(0, 0, 0.58, 1);
  width: 350px;
  z-index: 100;
  background: $dark_blue;
  border: 1px solid $charcoal;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  border-radius: 6px;
  box-sizing: content-box;

  .pa-RefPicker-Inner {
    width: 350px;
    height: 300px;
    @include flexbox(column, space-between, stretch);

    .Picker-header {
      color: $white;
      font-size: $font-size-medium;
    }

    .Picker-footer,
    .Picker-header {
      @include flexbox(row, space-between, center);
      width: 100%;
      flex: 0 0 50px;
    }

    .Picker-header {
      border-bottom: solid 2px;

      .back {
        flex: 0;
        padding: 10px 0px 10px 10px;
      }
      .title {
        @include singleLineTextEllipsis();
        width: 100%;
        flex: 1;
        padding: 10px;
        font-size: 16px;
      }
    }

    .Picker-footer {
      border-top: 1px solid $grey;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      background-color: $white;
      justify-content: flex-end;
      padding: 0px 10px;
      font-size: $font-size-base;

      .Button {
        text-transform: uppercase;
        border: none;
        background-color: transparent;
      }
    }

    .Picker-list {
      overflow-y: scroll;
      flex: 1;
      background-color: $white;
      position: relative;

      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      padding-bottom: 10px;

      .Picker-list-empty {
        display: flex;
        justify-content: center;
        padding: 10px;
      }
    }

    .Picker-search {
      position: relative;
      height: 39px;
      border-bottom: solid 1px $light_grey;

      input {
        width: 100%;
        border: none;
        padding-left: 40px;
        margin: 0px;
        border-radius: 0px;
      }

      .Picker-search-icon {
        position: absolute;
        width: 40px;
        height: 40px;
        top: 0px;
        left: 0px;
      }
    }

    .Picker-item-container {
      width: 100%;
      padding: 0px 15px;
      cursor: pointer;
      &.active {
        background-color: $light_pattens_blue;
      }
      &.disabled {
        .Picker-item-text {
          color: $dark_grey;
        }
      }
    }

    .Picker-item {
      @include flexbox(row, flex-start, center);
      align-items: center;
      width: 100%;
      min-height: 40px;
      color: $charcoal;
      border-bottom: solid 1px $light_grey;
      flex-wrap: wrap;
      padding-top: 5px;
      padding-bottom: 5px;

      hr {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      .Picker-item-header {
        @include singleLineTextEllipsis();
        flex: 1 1 100%;
        color: $dark_grey;
        font-size: 12px;
      }

      .Picker-item-text {
        @include singleLineTextEllipsis();
        flex: 1 1 80%;
      }

      .Picker-item-icon {
        margin-right: 14px;
        flex: 0;
      }
    }
  }
}

.pa-RefPicker-Container-absolute {
  position: absolute;
}

.pa-RefPicker-Container-left {
  @include calloutArrow();
}

.pa-RefPicker-Container-right {
  @include calloutArrow(-320px, 30px);
}

.pa-RefPicker-Container-center {
  @include calloutArrow(-150px, 30px);
}
