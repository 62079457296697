@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-Dnd-HighlightLine {
  $HEIGHT: 2px;
  $SIZE: 10px;

  display: block;
  height: $HEIGHT;
  background: $blue;
  margin-top: calc(-#{$HEIGHT} / 2);
  padding: 0;
  position: absolute;
  z-index: 2;
  width: 100%;
  visibility: hidden;

  &.wi-Dnd-HighlightLine-Visible {
    visibility: visible;
  }

  &.wi-Dnd-HighlightLine-Relative {
    position: relative;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    top: calc(-#{$SIZE} / 2 - #{$HEIGHT} / 2);
    border: $HEIGHT solid $blue;
    padding: 0;
    position: absolute;
    width: $SIZE;
    height: $SIZE;
    background: $light_grey;
    border-radius: 100%;
  }

  &:before {
    left: calc(-#{$SIZE} / 2);
  }

  &:after {
    right: calc(-#{$SIZE} / 2);
  }
}
