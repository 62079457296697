@import '~styles/variables';
@import '~styles/utilities';

.wi-TemplateSetEditor {
  justify-content: flex-start !important;
}

#wi-JobPlanning-Title {
  & > div:first-of-type {
    display: flex;
    align-items: center;
  }

  .wi-TemplateSet-Title-EditIcon {
    margin-left: 8px;
  }

  .wi-JobPlanning-Title-Pill {
    margin-left: 8px;
  }
}

.wi-SearchBarContainer {
  .wi-SearchBar {
    width: 100%;
  }
}
