@import '~styles/variables';

.Analytics-Dashboard-Section {
  margin: 40px 0 20px;

  .Analytics-Dashboard-Section-Header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-bottom: 1px solid #979797;

    .Analytics-Dashboard-Section-Header-title {
      flex: 1 1 auto;
      font-size: 25px;
      color: $black;
      font-weight: $font-weight-light;
    }

    .Analytics-Dashboard-Section-Header-item {
      display: flex;
      align-items: center;
      padding-right: 30px;
      .dateRange {
        margin-left: 10px;
        & .dateRange-picker {
          border-radius: 4px;
          height: 36px;
        }
      }
      & .Select {
        margin-left: 10px;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
}

.Analytics-Dashboard-Section-DateConfirm {
  hr {
    margin: 20px 0;
  }

  label,
  input {
    margin-bottom: 0 !important;
  }

  .wi-Input .checkbox label {
    display: flex;
    font-size: 14px;
    text-transform: none;
  }
}

.dateRange-confirm-label {
}

.dateRange-confirm-label-date {
  color: $dark_grey;
  font-style: italic;
}
