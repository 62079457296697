@import '~styles/variables';
@import '~styles/utilities';
@import '~styles/colors';

//one off style for roles page
#wi-UserDetailHeader {
  .pa-SubNav {
    border-bottom: none;
  }
}

.wi-UserDetail {
  .wi-UserDetailHeader {
    margin-bottom: 0.75rem;
    margin-top: 1.5rem;
  }

  .wi-UserDetail--Username {
    margin-bottom: 0;
    margin-top: 0;
  }

  .wi-UserDetail--Header {
    margin-bottom: 25px;
    display: flex;
    flex-grow: 1;
  }

  .wi-UserDetail--NameAndEmail {
    flex-grow: 1;
  }

  .wi-UserDetail--ActiveDirectoryNotice {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .wi-Icon {
      margin-right: 4px;
    }
  }

  .wi-UserDetail--RemoveUserButton {
    flex-grow: 0;
    align-self: center;
  }

  .wi-userdetail--header .wi-Dropdown {
    height: auto;
    margin-right: 0;
    display: inline-flex;
  }

  .wi-Dropdown-Wrapped {
    right: 12px;
    top: 36px;
  }

  .wi-DropdownLabel {
    @include dropdownPillHelper();
    border: 1px solid $charcoal;
    background-color: $white;
  }

  .wi-TemplateTitleDropdown-Item {
    padding: 8px;
    text-align: left;
    height: 29px;
    width: 150px;
    line-height: 16px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:hover {
      background-color: $highlight_table_row;
      color: $charcoal;
    }
  }
}

.wi-UserDetailTables {
  margin-top: 35px;
}

.pa-Tabs-content .wi-searchableTable {
  margin-top: 20px;
  padding-bottom: 40px;
}

.pa-Tabs {
  display: flex;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid #dddddd;
}
.pa-Tabs-tab {
  font-size: 14px;
  text-transform: capitalize;
  letter-spacing: 0.3px;
  font-weight: 500;
  color: $charcoal;
  padding: 10px 10px;
  border-bottom: 2px solid;
  border-bottom-color: transparent;
  transition: all 0s linear;
  margin: 0 10px;
  &:hover {
    cursor: default;
  }
  &.tab-link-active {
    font-weight: 600;
    color: var(--primaryA, $blue);
    border-bottom-color: var(--primaryA, $blue);
    &:hover {
      color: var(--primaryA, $blue);
      border-bottom-color: var(--primaryA, $blue);
    }
  }
}
