.wi-Insights-Look-View-IframeView {
  position: relative;
  width: 100%;
  height: calc(100vh - 110px);

  & .modalSpinner {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #ececec;
    opacity: 0;
    visibility: hidden;
    transition: opacity 150ms ease-in-out 0ms, visibility 0ms ease-in-out 150ms;

    &--shown {
      opacity: 1;
      visibility: visible;
      transition: opacity 150ms ease-in-out 0ms, visibility 0ms ease-in-out 0ms;
    }
  }
}
