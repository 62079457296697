@import '~styles/variables';
@import '~styles/utilities';

#wi-AddStepAnywhere {
  @include flexbox(column, space-between, stretch);
  flex: 1 1 auto;
  height: 100%;
  border-radius: 3px;
  min-height: 500px;

  #wi-AddStepBody-Title,
  #wi-AddStepBody-Description {
    margin-bottom: 0px;
    background-color: white;
    border: solid 1px $grey;
  }

  .wi-AddStepAnywhere--Title {
    margin: 0;
  }

  #wi-AddStep-addStep.Button .wi-Icon {
    max-width: 20px;
    max-height: 20px;
    margin-right: 4px;
    margin-left: -2px;
  }

  .wi-AddStep-AddNewStepBar {
    @include flexbox(column, space-around);
    @extend %type-header-title;
    min-height: 80px;
    padding-bottom: 10px;
    position: relative;
    z-index: 1;
  }

  .wi-AddStepAnywhere--Header {
    min-height: 70px;
    background-color: $light_grey;
    border-bottom: $border-standard;
    border-radius: 3px;
    padding: 20px 2rem 20px 2rem;
    .wi-header-contents {
      @include flexbox(row, space-between, baseline);
      .wi-header-steps {
        font-size: 14px;
        color: $grey;
      }
    }
  }

  .wi-AddStepAnywhere--Body {
    overflow-y: scroll;
    @include flexbox(column, space-between, stretch);
    flex: 1 1 auto;
  }

  .wi-AddStepAnywhere--Footer {
    background-color: $light_grey;
    padding: 10px 12px;
    border-radius: 3px;
    min-height: 50px;
    .wi-footer-contents {
      @include flexbox(row-reverse, space-between, center);
      flex: 1 1 auto;
    }
  }
}

.wi-EditorTask-InputHeader {
  display: block;
  margin-bottom: 0.5rem;
}

#wi-AddStepBody-RoleSelector {
  .wi-SelectRole {
    background-color: $white;
    border-radius: 3px;
    border: solid 1px $grey;
    margin-bottom: 0;
    margin-left: 0;
    height: 38px;
    padding: 6px 10px;
    box-shadow: none;
    box-sizing: border-box;
  }
}

.wi-AddStepBody-Description {
  border-radius: 3px;
  border: solid 1px $grey;
}

.wi-AddStepBody-Description-Content {
  background-color: white;
  border-radius: 3px;
  margin-bottom: 0;
  border: 1px solid $grey;
  padding: 5px 10px 10px;
  min-height: 40px;
  max-height: 40px;
}

.wi-AddStepBody-Indent {
  padding: 10px 20px 10px 20px;
}

.wi-DraggableStep {
  @include flexbox(row, flex-start, baseline);
  background-color: $blue;
  cursor: move;
  width: 85%;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.wi-DraggableStep-Content {
  @include flexbox(row, flex-start, baseline);
  padding: 5px 30px;
  position: relative;
  font-size: 14px;
  color: $white;
  align-items: center;
  flex: 1 1 auto;
  justify-content: space-between;
  width: 100%;
}

.wi-DraggableStep-Box {
  width: 12px;
  height: 12px;
  border-radius: 1.1px;
  border: solid 1.4px $white;
  margin-right: 20px;
}

.wi-DraggableStep-Title {
  width: calc(100% - 12px - 20px);
  @include truncate();
}

.wi-template-notYetPosted-icon {
  padding-top: 0;
}

.wi-JobDisplay-step-notYetPosted {
  @include flexbox(row, flex-start, baseline);
  background-color: $blue;
  margin: 5px 15px 5px 15px;
  cursor: move;
  width: auto;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
}

.wi-JobDisplay-step-notYetPosted-wrap {
  background: transparent;
}

.wi-AddStepBody-DndWrapper {
  display: block;
  height: 1px;
  border: 0;
  border-top: 3px solid $blue;
  padding: 0;
  position: absolute;
  width: 100%;
}

.wi-AddStepBody-DndCircle {
  display: block;
  top: -7px;
  border: 2px solid $blue;
  padding: 0;
  position: absolute;
  width: 10px;
  height: 10px;
  background: $grey;
  border-radius: 5px;
}

.wi-AddStepBody-DndLeftCircle {
  left: -5px;
}

.wi-AddStepBody-DndRightCircle {
  right: -5px;
}
