@import '~styles/variables';
@import '~styles/utilities';

.AnalyticAsyncSelect-Option {
  display: flex;
  flex-wrap: nowrap;
}

.AnalyticAsyncSelect-Title {
  flex-grow: 1;
}

.AnalyticAsyncSelect-Published,
.AnalyticAsyncSelect-Description {
  color: $dark_grey;
  font-size: $font-size-small;
}

.AnalyticAsyncSelect-Title,
.AnalyticAsyncSelect-Description {
  @include singleLineTextEllipsis();
}

.AnalyticAsyncSelect-Published {
  @include singleLineNoWrap();
}
