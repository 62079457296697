@import '~styles/variables';
@import '~styles/utilities';

.wi-Rev-ValueRenderContainer {
  display: inline-block;
  max-width: 350px;
  min-width: 220px;
  padding-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;

  &.isFocused {
    background-color: $highlight_select;
  }

  &.isSelected {
    border-left: 5px solid $blue;
  }
}

.wi-Rev-ValueRender-BoldText {
  font-weight: $font-weight-heavy;
  color: $text_color;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.wi-Rev-ValueRender-DarkGrayText {
  color: $charcoal;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.wi-Rev-ValueRender-LightGrayText {
  color: $grey;
  font-size: $font-size-micro;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-bottom: 5px;
}

.wi-Rev-Heading-DD1Wrapper,
.wi-Rev-Heading-DD2Wrapper {
  .Select-multi-value-wrapper {
    align-self: center;
  }
}
