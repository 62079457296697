@import '~styles/variables';
@import '~styles/utilities';

.wi-TemplateTable {
  .wi-TemplateTableNameColumn {
    width: 100%;

    .wi-Icon {
      margin-right: 16px;
      margin-left: 4px;
      opacity: 0.5;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .wi-Pill {
      line-height: 0.95rem;
      padding: 0 6px;
      text-transform: uppercase;
      z-index: 1;
      height: 11.5px;
      margin-top: 5.25px;
      margin-left: 5px;
      font-size: 9px;
      width: 100%;
      height: 100%;
    }
  }

  .tag {
    float: right;
    font-size: $font-size-micro;
    font-weight: $font-weight-regular;
    background-color: $light_blue;
    color: $text_color;
    border-radius: 4px;
    padding: 3px 8px;
    margin: 8px 0 5px 5px;
  }

  .wi-TableDescripSubText {
    color: $dark_grey !important;
    font-size: $font-size-small;
  }

  .wi-TemplateNavButton {
    min-width: 0;
  }

  .wi-TemplateNavAlign {
    @include flexbox(row, flex-end);
  }

  // in review state
  .wi-Template-Row-InReview,
  .wi-TemplateExternal-Row,
  .wi-TemplateSet-Row-InReview {
    color: $charcoal;

    .wi-TableDescripText {
      color: $charcoal;
    }
  }

  .wi-TemplateTable-Column-Actions {
    display: flex;
    justify-content: flex-end;
    cursor: pointer !important;
    > div {
      display: flex;
    }
    button,
    a {
      align-self: center;
    }
  }

  &.wi-FullWidthTable tr.in-review {
    cursor: default;

    td {
      cursor: default;

      &:first-child {
        color: $charcoal;
      }
    }

    &:hover,
    &.active {
      background-color: initial;
    }
  }

  &.wi-TemplateTable-NoViewButton {
    .wi-TemplateNavButton {
      display: none;
    }
  }

  .wi-TemplateTable-Column-Type,
  .wi-TemplateTable-Column-Version,
  .wi-TemplateTable-Column-Status,
  .wi-TemplateTable-Column-Author,
  .wi-TemplateTable-Column-Created {
    @include singleLineTextEllipsis();
  }
}

.wi-TemplateTable-DetailsModal {
  .wi-TemplateTable-DetailsModal-Item {
    font-size: 16px;
  }

  .wi-TemplateTable-DetailsModal-Header {
    font-weight: 500;
  }

  .wi-TemplateTable-DetailsModal-Content {
    color: $dark_grey;
  }
}
