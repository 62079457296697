@import '~styles/variables';
@import '~styles/utilities';

.wi-UserTable,
.wi-AdminTable {
  .wi-UserTable-UserName,
  .wi-UserTable-UserEmail {
    @include singleLineTextEllipsis();
    padding-top: 3px;
    padding-bottom: 3px;
  }
}
.wi-UserTable-UserName {
  display: flex;
  flex-grow: 1;
  justify-content: space-between;
  align-items: center;
}
.wi-UserTable-UserName-text {
  @include singleLineTextEllipsis();
}
.wi-UserTable.removedUsers {
  .rt-tr {
    pointer-events: none;
  }
  .rt-tr:hover {
    background-color: inherit;
  }
}
