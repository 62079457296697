@import '~styles/colors.scss';
@import '~styles/variables';

.pa-UserSelection {
  .pa-UserSelection__Container {
    margin-top: 16px;
    margin-bottom: 20px;
  }

  .pa-UserSelection__Title {
    line-height: 1;
  }

  .pa-UserRow {
    border: 1px solid $grey;
    padding: 8px 16px 8px 16px;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;

      a > .wi-Icon:first-child {
        margin-right: 0px;
      }
    }
  }

  .pa-UserRow + .pa-UserRow {
    border-top-width: 0px;
  }

  .pa-UserRow__Email {
    margin-right: 8px;
  }

  .pa-UserRow__Name {
    color: $black;
  }

  .pa-UserRow__RemoveIcon {
    flex-grow: 1;
    display: flex;
    cursor: pointer;
    transform: scale(0.9, 0.9);
    justify-content: flex-end;
  }

  .pa-AddButton {
    align-items: center;
    cursor: pointer;
    padding: 8px 0px;
    display: flex;
  }
}
