@import '~styles/variables';

.wi-Input {
  position: relative;

  input[type='text'] {
    width: 100%;
  }

  .wi-Input-Uneditable {
    pointer-events: none;
    -ms-touch-action: none;
  }
}

.wi-Input-std-width {
  width: $text-box-width;
}

.radio {
  input.wi-Input-std-width {
    width: 2rem;
  }

  span {
    color: $text_color;
    font-size: $font-size-small;
    font-weight: normal;
    margin-bottom: 0.5rem;
    text-transform: uppercase;
  }
}
.wi-Input--ErrorMessage {
  font-size: $font-size-small;
  color: $red;
  position: relative;
  top: -1rem;
}
