@import '~styles/variables.scss';
.wi-InputList-List {
  .wi-InputList-ListHeader {
    font-weight: $font-weight-regular;
  }
  .wi-InputList-List-Item-Controls {
    display: flex;
    justify-content: space-around;
    flex-basis: 100px;
    flex-shrink: 0;
  }
  .wi-InputList-List-Item {
    display: flex;
    margin-bottom: 5px;
  }

  div .wi-Input {
    margin: 0px;
  }

  .wi-InputList-Input {
    height: auto;
    font-size: 12px;
    margin: 0px;
  }

  .wi-InputList-AddButton {
    margin-top: 10px;
    text-align: right;
  }
}

.wi-List-ListOptions {
  .tipsy {
    line-height: normal;

    &:after {
      width: auto;
      max-width: 450px;
      white-space: normal;
    }
  }

  .tipsy--lengthy {
    &:after {
      min-width: 450px;
      word-break: break-all;
    }
  }
}

.wi-InputList-error {
  font-size: 12px;
  color: #db2c33;
  margin-top: 2px;
  letter-spacing: 0.2px;
}
