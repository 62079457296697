.template-loading {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;

  .wi-template-loading-backdrop {
    background-color: #111111;
    height: 100vh;
    width: 100vw;
    opacity: 0.1;
  }

  .wi-Icon {
    position: fixed;
    top: calc(50vh - 50px);
    left: calc(50vw - 25px);
    background-color: #ffffff;
    padding: 2px;
    border-radius: 8px;
    svg {
      width: 50px;
      height: 50px;
    }
  }
}
