// NOTE:
// several global styles break the style of the microfrontend
// overrides global styles here to restore the original appearance

#job-manager-mfe-container {
  [data-testid='custom-view-dropdown-trigger'] + *[tabindex='-1'] button a {
    box-sizing: content-box;
  }

  [data-testid='adhoc-filter'] [data-testid='query-text-input'] input {
    padding-left: 32px;
    padding-right: 8px;
  }

  .Toastify__toast-container {
    width: auto;
  }
}

[data-testid='adhoc-filter-dialog'],
[data-testid='custom-view-manage-dialog'] {
  [data-testid='template-metadata-list'],
  [data-testid='job-metadata-list'] {
    [data-testid^='field'] input:not([aria-autocomplete='list']) {
      padding-left: 32px;
      padding-right: 8px;
    }
  }

  [role='listbox'] [role='option'] {
    margin-bottom: 0;
  }
}
