@import '~styles/colors.scss';
@import '~styles/typography.scss';

$text_color: $black;
$link_color: $blue;
$link_hover_color: $dark_blue;

$primary-blue-hover: $dark_blue;
$active-tab-color: $blue;
$tooltip-color: $dark_blue;

$tooltip-arrow-size: 5px;

// Buttons
$btn-font-weight: normal !default;
$btn-font-size: 14px;
$btn-height: 29px;

$btn-font-size-lg: 16px;
$btn-height-lg: 40px;
$btn-height-sm: 24px;
$btn-height-xs: 20px;

// Button Default
$btn-default-color: $blue;
$btn-default-bg: $white;
$btn-default-border: $blue;
$btn-default-color-active: $blue;
$btn-default-bg-active: $white;
$btn-default-border-active: $blue;

// Button Primary
$btn-primary-color: $white;
$btn-primary-bg: $blue;
$btn-primary-border: $blue;
$btn-primary-color-active: $white;
$btn-primary-bg-active: $dark_blue;
$btn-primary-border-active: $dark_blue;

// Button Success
$btn-success-color: $white;
$btn-success-bg: $green;
$btn-success-border: $green;
$btn-success-color-active: $white;
$btn-success-bg-active: $dark_green;
$btn-success-border-active: $dark_green;

// Button Info
$btn-info-color: $blue;
$btn-info-bg: $white;
$btn-info-border: $blue;
$btn-info-color-active: $white;
$btn-info-bg-active: $blue;
$btn-info-border-active: $blue;

// Button Warning
$btn-warning-color: $white;
$btn-warning-bg: $orange;
$btn-warning-border: $orange;
$btn-warning-color-active: $white;
$btn-warning-bg-active: $dark_orange;
$btn-warning-border-active: $dark_orange;

// Button Danger
$btn-danger-color: $white;
$btn-danger-bg: $red;
$btn-danger-border: $red;
$btn-danger-color-active: $white;
$btn-danger-bg-active: $dark_red;
$btn-danger-border-active: $dark_red;

// some classes to extend for non-standard buttons to give them standard looks
%btn-primary-bg-fade {
  transition: all 0.4s;
  background-color: $blue !important;

  &:hover {
    background-color: $dark_blue !important;
  }
}

$btn-link-disabled-color: $light_grey;
$primary-button-color: $blue;

//paddings
$spec-padding: $font-size-small;
$spec-padding-small: $font-size-micro;
$spec-padding-medium: $font-size-medium;
$spec-padding-large: $font-size-base;
$padding-base-vertical: $spec-padding;
$padding-base-horizontal: $spec-padding-small;
$padding-xs-vertical: 0.5rem;
$padding-xs-horizontal: $spec-padding-small;
$padding-small-vertical: $spec-padding-small;
$padding-small-horizontal: $spec-padding-small;
$padding-large-vertical: $spec-padding-large;
$padding-large-horizontal: $spec-padding;
$template-padding: 10px;

//margins
$spec-margin: $font-size-small;
$spec-margin-small: $font-size-micro;
$spec-margin-large: $font-size-base;
$editor-step-spacing: 1.5rem;
$StepField-icon-container-margin: 6px;
$editor-input-margin: 0.3125rem;
$template-left-indent: calc($template-padding / 2);

//borders:
$border-radius-standard: 0.4rem;
$border-radius-base: $border-radius-standard; // alias for bootstrap
$border-radius-small: $border-radius-standard; // alias for bootstrap
$border-radius-large: $border-radius-standard; // alias for bootstrap
$border-standard: 1px solid $grey;
$border-dashed-standard: 1px dashed $grey;
$border-obsidian: 1px solid $charcoal;
$border-light: 1px solid $light_grey;
$border-blue-dash: 2px dashed $blue;
$border-blue: 1px solid $blue;
$border-grey: 1px solid $light_grey;

//fixed dimensions:
$stepFieldHeader_height: 40px;
$titleEditor_height: 50px;
$header_height: 50px;
$editor_category_banner: 23px;
$editor_conditional_banner: 49px;
$template-StepField-height: 2.25rem;
$StepField-controls-height: 24px;
$editor-input-height: 1.25rem;
$editor-input-width: 18rem;
$editor-range-width: $editor-input-width;
$webex-margin-top: 20px;
$webex-margin-bottom: 20px;
$webex-joblist-title-height: 36px;
$webex-total-margin: $webex-margin-top + $webex-margin-bottom;
$editor-step-max-width: 900px;
$proxy-line-height: 25px;
$search-bar-width: 20rem;
$min-width-diffs: 700px;
$max-width-diffs: 1400px;
//transitions:
$editing-transition-opacity-1: 0.1s opacity ease;

//Calculations
$task-panel-height: calc(100% - 43px);

// Forms
$input-height: 38px;

$input-font-size-lg: 14px;
$input-height-lg: 40px;

$input-height-sm: 32px;
$input-font-size-sm: 12px;

$btn-height-lg: 40px;

$text-box-width: 20rem; // 640

// Status Banners
$pa-StatusBanner--height: 25px;

// Page Name
$pa-PageName--font_size: 20px;

// Print Report Header (Print Report, customize, email)
$pa-PrintReportHeader--height: 56px; //matches PageName height

// Horizontal Primary Nav (WebEx)
$pa-HorizontalNav--height: 50px; //fixed height allows to offset content body.

// Vertical Primary Nav (Admin)
$pa-VerticalNav--padding: 16px;
$pa-VerticalNav--width_expanded: 200px;
$pa-VerticalNav--width_collapsed: 50px;
$pa-VerticalNav--breakpoint: 1025px;

// NavLink
$pa-NavLink--background_color: transparent;
$pa-NavLink--background_colorHover: rgba(0, 0, 0, 0.2);
$pa-NavLink--background_colorActive: $blue;
$pa-NavLink--padding_horizontal: $pa-VerticalNav--padding;
$pa-NavLink--padding_vertical: $pa-VerticalNav--padding;
$pa-NavLink--font_weight: $font-weight-regular;
$pa-NavLink--font_size: $font-size-base;
$pa-NavLink--color_title: rgba(255, 255, 255, 0.8);
$pa-NavLink--color_titleHover: rgba(255, 255, 255, 1);
$pa-NavLink--color_titleActive: $pa-NavLink--color_titleHover;
$pa-NavLink--color_icon: $pa-NavLink--color_title;
$pa-NavLink--color_iconHover: $pa-NavLink--color_titleHover;
$pa-NavLink--color_iconActive: $pa-NavLink--color_iconHover;

// Team Identity
$pa-TeamIdentity--padding_horizontal: 18px;
$pa-TeamIdentity--padding_vertical: $pa-VerticalNav--padding;
$pa-TeamIdentity--logo_width: 11px;
$pa-TeamIdentity--text_size: 16px;
$pa-TeamIdentity--text_color: #ffffff;

// Utilities
$pa-HorizontalNav--shadow: 0 2px 3px rgba(0, 0, 0, 0.04);

// Breakpoints
$pa-breakpoint--medium: 1024px;

// Scaffolding
$pa-container--gutter_width: 20px;
$pa-container--width_minimum: $pa-breakpoint--medium - $pa-VerticalNav--width_expanded;
$pa-container--width_maxiumum: $pa-breakpoint--medium;

// Report
$pa-Report--width: 750px;
$pa-Report-Gallery--gap: 10px;
$pa-Report-Number--width: 58px;
