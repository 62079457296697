@import '~styles/variables';
@import '~styles/utilities';

.pa-LockHolderSection-Banner {
  color: $white;
  background-color: $charcoal;
  padding: 7px 30px;
  height: 56px;
  font-size: 13px;
  font-weight: $font-weight-regular;
  @include flexbox(row, center);

  .wi-Icon {
    margin-right: 8px;
  }

  .Button-pill {
    margin-left: 25px;
    margin-right: 10px;
  }

  .pa-LockHolderSection-Banner--LockHolder {
    font-weight: $font-weight-heavy;
  }

  .pa-LockHolderSection-Banner--dismiss {
    position: absolute;
    right: 9px;
    margin-top: 3px;
  }
}
