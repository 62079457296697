@import '~styles/variables';
@import '~styles/utilities';

.pa-InputButton {
  @include flexbox(row, flex-start, flex-start);

  button {
    align-self: normal;
    margin-left: 8px;
  }

  .wi-Input {
    flex: 1;
    height: 40px;
  }

  .pa-InputWithValue {
    display: flex;
    flex-grow: 1;
    height: 40px;
    justify-content: space-between;
    border: 1px solid $grey;
    border-radius: 4px;
    padding: 5px;
    align-items: center;
    padding-left: 40px;
    cursor: pointer;
  }

  input,
  input:focus {
    margin-bottom: 0px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
    border-color: $grey;
  }

  .Button-flatLeft {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    height: 40px;
    border-color: $grey;
    border-width: 1px;
    text-transform: uppercase;
  }

  .pa-clearInput {
    padding-right: 5px;
    padding-left: 5px;
  }
}
