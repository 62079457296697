@import '~styles/variables';
@import '~styles/utilities';

.wi-Lightbox {
  flex: 1 1 auto;
  text-align: center;

  span {
    outline: 0 !important;
  }

  .wi-Lightbox-ImageContainer .wi-ExifImage-Container {
    margin: 0 auto;

    img {
      max-height: 100%;
      max-width: 100%;
    }
  }

  .wi-ExifImage-Container {
    img {
      max-height: 100%;
      max-width: 100%;
    }
  }
}

.wi-Lightbox-IsOpen {
  $padding: 10px;

  .wi-LightBox-OpenState {
    display: flex;
    justify-content: center;
    position: fixed;
    z-index: 101;
    left: 0;
    right: 0;
    top: 85px;
  }

  .wi-LightBox-GreyBackdrop {
    height: 100vh;
    width: 100vw;
    position: fixed;
    -webkit-transform: translateZ(0);
    top: 0;
    right: 0;
    background-color: #000;
    opacity: 0.65;
    z-index: -1;
  }

  .wi-Lightbox-positioning {
    -webkit-transform: translateZ(0);
    padding: $padding;
  }

  .wi-Lightbox-Container {
    display: inline-block;
    background-color: #fff;
    padding: $padding;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);

    .wi-Lightbox-TitleContainer {
      overflow: hidden;
      position: relative;

      h4 {
        display: inline-block;
        margin: 0;
        max-width: calc(100% - 35px);
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        outline: 0 !important;
      }

      img {
        position: absolute;
        height: 36px;
        right: -8px;
        top: -8px;
        margin: 0;
        outline: 0 !important;

        &:focus {
          outline: 0;
        }
      }
    }

    .wi-Lightbox-LargeImage {
      margin: 0;
      max-height: calc(90vh - 200px);
      max-width: 80vw;
      outline: 0 !important;
    }

    .wi-ExifImage-Container {
      font-size: 0;

      img {
        max-width: 100%;
        max-height: calc(100vh - 200px);
      }
    }

    video {
      max-width: 100%;
      max-height: calc(100vh - 200px);
    }
  }
}
