@import '~styles/colors.scss';

.pa-AppBody.pa-AppBody--singleColumnLayout {
  padding-left: 0;
}

.pa-toast-toast {
  height: 50px;

  &.Toastify__toast--warning {
    background-color: #fa7c10; // PARSABLE_COLOR.ORANGE_600
  }

  .Toastify__toast-body {
  }

  .Toastify__progress-bar {
    height: 6px;
    opacity: 0.8;
  }
}
