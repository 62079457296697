@import '../TemplateDiffReportUtils';

.wi-Diff-MetadataList {
  margin-left: $template-left-indent;
  margin-top: 5px;
}

.wi-Diff-MetadataListWrapper,
.wi-Diff-MetadataList,
.wi-Diff-MetadataIsRequired {
  @include flexbox(column, flex-start, stretch);
}

.wi-Diff-MetadataListTitleWrapper {
  @include flexbox(row, flex-start);
}

.wi-Diff-MetadataValueWrapper {
  @include flexbox(column, flex-start, flex-start);
}

.wi-Diff-MetadataTitleWrapper {
  @include flexbox(row, flex-start, flex-start);
  border-bottom: $border-dashed-standard;
}

.wi-Diff-MetadataListTitleText {
  @include diffFont();
}

.wi-Diff-MetadataWrapper {
  @include flexbox(row, space-between, stretch);
  @include diffText();
  margin-bottom: 10px;

  &.wi-Diff-ModifiedMetadata {
    .wi-Diff-MetadataValueText {
      @include removedText();
    }

    .wi-Diff-MetadataOtherValueText {
      @include addedText();
    }
  }
}

.wi-Diff-MetadataListTitleText,
.wi-Diff-MetadataWrapper,
.wi-Diff-MetadataValueText,
.wi-Diff-MetadataTitleText {
  @include flexbox();
}

.wi-Diff-MetadataValueText,
.wi-Diff-MetadataOtherValueText {
  display: inline;
}

.wi-Diff-MetadataTitleWrapper,
.wi-Diff-MetadataValueWrapper {
  flex: 1 1 50%;
}

.wi-Diff-MetadataValueWrapper {
  display: inline-block;
  margin-left: 40px;
  border-bottom: $border-standard;
}
