@import '~styles/variables';
@import '~styles/utilities';

.wi-JobRoleTable {
  .wi-JobRoleTable-Role,
  .wi-JobRoleTable-Description {
    @include singleLineTextEllipsis();
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .wi-JobRoleTable-Icon {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
