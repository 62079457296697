@import '~styles/variables';

.d3-header-filters {
  display: flex;
  align-items: center;
  color: $charcoal;
}

.d3-subheader-filters {
  display: flex;
  align-items: flex-start;
  flex-grow: 1;

  > span {
    margin-top: 8px;
  }

  > * + * {
    margin-left: 5px;
  }
}
