@import '~styles/variables.scss';

.absoluteHeightContainer {
  display: block;
  position: relative;
  box-sizing: content-box;
  width: 100%;
  border: dotted 1px transparent;

  &.absoluteHeightContainer_isFocused {
    border-color: #c1c1c1;
  }
}

.absoluteHeightContainer-composed {
  position: absolute;
  width: 100%;
}
