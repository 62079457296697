@import '~styles/variables';

.parsabotTabContainer {
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  padding-left: $spec-padding-medium;
  font-size: $font-size-base;
}

.parsabotTab {
  padding: 6px $spec-padding-medium 4px $spec-padding-medium;
  border-radius: 4px 4px 0px 0px;
  border-bottom: 2px solid transparent;

  &:hover {
    cursor: pointer;
  }

  &.active {
    background: white;
    color: $windsurfing_blue;
  }
}
