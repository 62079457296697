@import '~styles/colors.scss';

.Select-Extended {
  .Select {
    width: 146px;

    .Select-control {
      border-radius: 4px;

      .Select-value {
        color: $parsable-grey-800;
        svg {
          filter: invert(28%) sepia(0%) saturate(46%) hue-rotate(143deg) brightness(99%)
            contrast(82%);
        }

        .Select-value-label {
          display: flex;

          .Select-value-text {
            padding-left: 5px;
            white-space: nowrap;
            width: auto;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .Select-menu-outer {
      margin-top: 1px;
    }
  }
}

.Select-menu-outer.extended {
  width: 368px !important;
  max-height: 584px !important;
  margin-left: -2px;

  .Select-menu {
    max-height: unset;
    height: 100%;
    width: 100%;

    .Select-option {
      max-height: 72px;
    }

    .Select-option.is-selected {
      .Select-holder {
        .Select-icon {
          filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(288deg) brightness(102%)
            contrast(102%);
        }

        .Select-title {
          color: $white;
        }
      }

      .Select-description {
        color: $white;
      }
    }

    .Select-holder {
      display: flex;

      .Select-icon {
        display: flex;
        align-items: center;
        svg {
          // $parsable-grey-800;
          filter: invert(28%) sepia(0%) saturate(46%) hue-rotate(143deg) brightness(99%)
            contrast(82%);
        }
      }

      .Select-title {
        padding-left: 4.5px;
        color: $parsable-grey-800;
      }
    }

    .Select-description {
      padding-left: 5px;
      color: $dark_grey;
      font-size: 12px;
    }
  }
}
