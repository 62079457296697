@import '~styles/variables.scss';
@import '~styles/typography.scss';
.wi-StepField-Constraints {
}
.wi-InputNumber {
  display: flex;
  flex-wrap: wrap;

  .wi-InputNumber-Input {
    width: 100%;
    margin-bottom: 5px;
    padding: auto 5px;
  }

  .wi-InputNumber-Unit {
    flex: 1 0 100%;
    width: 100%;
  }

  .wi-InputNumber-Header {
    @extend %type-step-input-headers;
    margin-top: 30px;
    flex: 1 0 100%;
  }

  .wi-InputNumber-Number {
    flex: 1 1;
    margin: 0 5px 0 0;
    width: 100%;

    &.wi-InputNumber-NumberPad {
      margin: 0 0 0 5px;
    }
  }
}
.wi-InputNumber-error {
  color: $red;
  flex: 1 0 100%;
}
