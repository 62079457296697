@import '~styles/variables';
@import '~styles/utilities.scss';

.d3-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.d3-tooltip-title {
  font-weight: $font-weight-mbold;
  font-size: 14px;
  line-height: 1;
  padding: 10px 10px 8px;
}

.d3-tooltip_right {
  transform-origin: top right;
}

.d3-tooltip-item {
  @include flexbox(null, null, null);
  border-top: 1px solid $grey;

  & strong {
    padding: 3px 10px;
    border-left: 1px solid $grey;
    max-width: 500px;
  }

  .d3-tooltip-name {
    width: 175px;
    margin: 5px;
  }
}
// line of code is required to display background colors in print preview screen
@media print {
  .d3-legend {
    // for chrome
    -webkit-print-color-adjust: exact;
    // for firefox
    color-adjust: exact;
  }
}
