@import '~styles/variables';

.wi-Input {
  position: relative;

  input[disabled] {
    background-color: $light_grey;
  }

  .radio,
  .checkbox {
    label {
      color: $text_color;
      font-size: $font-size-small;
      font-weight: normal;
      margin-bottom: 0.5rem;
      text-transform: uppercase;
      display: inline-block;
    }

    input.wi-Input-StdWidth {
      width: 2rem;
    }

    input[type='checkbox'],
    input[type='radio'] {
      width: auto;
      margin-right: 8px;
    }
  }

  .static {
    background: transparent;
    border: none;
    padding: 0;
  }

  .wi-Input-Notice {
    margin-top: 12px;
    margin-bottom: 12px;
  }
}

.wi-Input--With-Message {
  margin-bottom: 1.2rem;
}

.wi-Input-FullWidth {
  width: 100%;
}

.wi-Input-StdWidth {
  width: 2rem;
}

.wi-Input--Success input {
  border-color: $green !important;
}

.wi-Input--Danger input {
  border-color: $red !important;
}

.wi-Input-Message {
  color: $text_color;
  font-size: $font-size-small;
  position: relative;
  top: $font-size-base;
  text-align: left;
}

.wi-Input-Message--Info {
  color: $grey;
}

.wi-Input-Message--Success {
  color: $green;
}

// TODO when that var exists!
// .wi-Input-Message--Warning {
//   color: $brand-warning;
// }

.wi-Input-Message--Danger {
  color: $red;
}

.wi-Input-autoExpanded {
  resize: none;
  padding: 10px 10px 10px 40px;
  min-height: 40px;
  margin-bottom: 0;
}
