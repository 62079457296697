@import '~styles/variables';
@import '~styles/utilities.scss';
#wi-JobPlanning-Title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 5px;
  margin-bottom: 20px;
  .wi-JobPlanning-Title-Text {
    max-width: calc(100vw - 10vw);
    word-break: auto-phrase;
    overflow-wrap: anywhere;
    line-break: normal;
    hyphens: auto;
    @extend %type-extra-large-header;
  }
  .wi-JobPlanning-Title-Buttons {
    display: flex;
  }
  .Button {
    margin-left: 10px;
  }
}

// Clearly we shouldn't have to do this. I agree with @tom's original assertion
// that UI elements should fill the space they're in unless they're constrained.
// That's sort of what we're doing, but the search bar is getting the StdWidth
// class when we don't want it…
.wi-JobPlanning .wi-Input-StdWidth {
  width: 100%;
}

.wi-JobPlanning .wi-jobPlanning--404 {
  margin-top: 40px;
}

ul.wi-PlanningNav {
  font-size: 14px;
  margin: 0;
  padding: 0 10px;
  a {
    .wi-Icon {
      margin-right: 10px;
    }
    color: $charcoal;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    font-size: 16px;
    &.active {
      background-color: $white;
      cursor: default;
    }
  }
  li {
    display: inline-block;
    list-style: none;
    margin: 0 10px 0 0;
    padding: 2px 8px 8px 5px;
    position: relative;
    top: 1px;
    &.active {
      background-color: $white;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      border: $border-standard;
      border-bottom: none;
    }
    img {
      position: relative;
      left: 1px;
      top: 5px;
      border: none;
    }
    &.invalid a {
      color: $red;
    }
  }
}

.wi-expiresAfterContainer {
  margin-left: 17%;
}
.JobPlanning-Form-Group_withError {
  .wi-Input input,
  .Button,
  .Select-control {
    border-color: $red !important;
  }
}

.JobPlanning-Form-Group-Error {
  color: $red !important;
}

.wi-JobPlanning .well {
  @include flexbox(column, space-between, stretch);
}

// Treat like a modal footer (please please please please please)
.wi-WellFooter {
  @include flexbox(row, space-between, baseline);
  background-color: $light_grey;
  padding: 10px 12px;
  border-top: $border-standard;
  border-bottom-left-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
  .wi-LastSaved {
    color: $dark_grey;
  }
}

.wi-JobPlanningDocuments-Well {
  @include flexbox(column, space-between, stretch);
  flex: 1 1 auto;
  padding: 32px 32px 0 32px;
}

.wi-JobPlanningDetails {
  @include flexbox(column, space-between, stretch);
  flex: 1 1 auto;

  input {
    margin-bottom: 0;
  }

  .datepicker__input {
    width: 20rem;
  }
  .wi-JobPlanningDetails-RecurringNotice {
    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }
  .wi-JobPlanningDetails-NoDate {
    color: $grey;
  }
  .wi-JobPlanningDetails-dateTimeDisplay {
    display: inline-block;
    min-width: 175px;
    padding-right: 15px;
  }
  #wi-JobPlanning-pill {
    display: inline-block;
    margin-right: 10px;
    margin-top: 0px;
  }
  .wi-JobPlanningDetails-timeTracking {
    margin-top: 40px;
    margin-bottom: 40px;
    .wi-JobPlanningDetails-timeTracking-checkbox {
      display: flex;

      input[type='checkbox'] {
        margin-top: 5px;
        margin-right: 9px;
      }
    }

    .pilot {
      color: $dark_grey;
      white-space: nowrap;
      display: inline-flex;
      padding: 0px 5px;
      span {
        padding: 0px 2px;
      }
    }
  }

  .wi-JobPlanningDetails-status {
    display: flex;

    > div {
      align-self: baseline;
    }
  }

  .wi-JobPlanningDetails-status-label {
    align-self: baseline;
  }

  .wi-JobPlanningDetails-onDemand-checkbox {
    display: flex;

    input[type='checkbox'] {
      margin-top: 5px;
      margin-right: 9px;
    }

    label {
      font-weight: 400;
    }

    &-title {
      font-weight: 600;
    }
  }
  .wi-JobPlanningDetails-onDemand-roles {
    margin-top: 17px;
    margin-left: 60px;

    &--title {
      font-weight: 600;
    }

    &--select {
      margin-top: 10px;
    }
  }

  .wi-JobPlanningDetails-row--alignTop {
    align-self: flex-start;
  }

  .wi-JobPlanningDetails-Type {
    label {
      display: flex;
      flex-direction: row;

      .wi-JobPlanningDetails-Type-label {
        font-size: 14px;
        text-transform: none;
        font-weight: normal;
        line-height: normal;
        margin-bottom: 5px;

        .wi-JobPlanningDetails-Type-name {
          font-weight: 500;
          color: $charcoal;
        }
        .wi-JobPlanningDetails-Type-description {
          padding-top: 2px;
          color: $dark_grey;
        }
      }
    }
  }

  .wi-JobPlanningDetails-workflow {
    color: $dark_grey;
    margin-top: 35px;
    display: flex;
    flex-direction: row;

    .wi-Icon {
      margin: 0;
      margin-right: 8px;

      svg {
        width: 16px;
      }

      g#ic_warning_colour > g:first-child {
        fill: $dark_grey;
      }
    }
  }

  .wi-JobPlanningDetails-row {
    margin-bottom: 8px;
  }

  .wi-JobPlanningDetails-flexRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex: 1;
  }
  .wi-JobPlanningDetails-row-ExpirationAfterSeconds {
    justify-content: space-around;
    .inputExpiresAfter {
      width: 70px;
    }
    .expiresAfterContainer {
      display: inline-flex;
      align-items: center;
    }
    label {
      margin-left: 5px;
    }
  }
  .wi-Analytics-Form-RepeatInterval--post {
    margin-left: 5px;
  }

  .control-label {
    font-weight: 600;
  }

  label {
    font-weight: 400;
  }
}

.wi-JobPlanningDocuments {
  @include flexbox(column, space-between, stretch);
  flex: 1 1 auto;
}

.wi-JobPlanningInvites {
  @include flexbox(column, space-between, stretch);
  flex: 1 1 auto;
  margin-top: 15px;
  .wi-UserTableRole {
    background: $light_grey;
    color: $charcoal;
    border-top: 1px solid $grey;
  }
}

.wi-JobPlanningDocuments-Panel {
  background-color: $white;
  border: $border-standard;
  @include flexbox(column);
  flex: 1 1 auto;
  i {
    color: $blue;
  }
}

.wi-JobPlanningDocuments-Panel-Header {
  @include flexbox(row, space-between, stretch);
  span {
    @include singleLineTextEllipsis();
  }
  .wi-JPD-Documents-upper {
    flex: 1 0 auto;
    margin-right: 30px;
  }
  color: $text_color;
  font-size: 13px;
  border-bottom: $border-standard;
  width: 100%;
  padding: 10px 20px;
}

.wi-JobPlanningDocuments-Panel-Body {
  @include flexbox(column, space-between, stretch);
  background-color: $light_grey;
  width: 100%;
  flex: 1 1 auto;
  min-height: 100px;
  max-height: 240px;
  overflow-y: auto;
  .Document {
    img:not(.wi-Icon),
    img.wi-Icon:not(.wi-DocumentDelete),
    video:not(.wi-Icon) {
      max-height: 40px;
      max-width: 40px;
      padding: 4px;
      margin: 0;
    }
  }
}

.wi-MeshIcon {
  margin-left: 10px;
  vertical-align: middle;
}
