@import '../../TemplateDiffReportUtils';

.wi-Diff-InputFieldList {
  margin-left: $template-left-indent;
}
@media print {
  .wi-Diff-TemplateWrapper {
    width: 100% !important;
    margin: 0 !important;
  }
  .pa-Rev-AnnotatorWrapper,
  .wi-Diff-MetadataListWrapper,
  .wi-Diff-InputFieldWrapper,
  .pa-simpleImage {
    display: table-cell;
  }
  .wi-Diff-Step-HeadingWrapper {
    break-inside: avoid;
  }
}

.wi-Diff-InputFieldListWrapper,
.wi-Diff-InputFieldIsRequired,
.wi-Diff-InputFieldList {
  @include flexbox(column, flex-start, stretch);
}

.wi-Diff-InputFieldIsRequired {
  color: $red;
}

.wi-Diff-InputFieldList-TitleWrapper {
  @include flexbox(row, flex-start);
}

.wi-Diff-InputFieldList-TitleText {
  @include diffFont();
}

.wi-Diff-InputFieldLeft {
  flex: 2 2;
}

.wi-Diff-InputFieldRight {
  flex: 1 1;
  font-size: $font-size-micro;
}

.wi-Diff-InputFieldLeft,
.wi-Diff-InputFieldTextXorRequired,
.wi-Diff-InputFieldRight {
  @include flexbox(row, flex-start);
}

.wi-Diff-InputFieldTextXorRequired {
  flex: 1 1 auto;
}

.wi-Diff-InputFieldWithLogic {
  @include flexbox(row, space-between);
  align-items: flex-end;
  width: 100%;
  margin-right: 10px;
  border-bottom: $border-dashed-standard;
}

.wi-Diff-InputFieldList-TitleText,
.wi-Diff-InputFieldTitle,
.wi-Diff-InputFieldIcon {
  @include flexbox();
  margin-right: 10px;

  .wi-Icon {
    width: 22px;
    height: 22px;
  }
  &.wi-Diff-InputFieldIcon--calc .wi-Icon {
    height: 16px;
  }
}

.wi-Diff-InputField {
  @include flexbox(row, space-between, stretch);
  @include diffText();

  margin-bottom: 5px;
}

.wi-Diff-InputFieldWrapper {
  @include flexbox(column, space-between, stretch);
  margin-bottom: 10px;
}

.wi-Diff-InputFieldRight {
  border-bottom: $border-standard;
}

.wi-Diff-InputFieldRight,
.wi-Diff-InputFieldConstraints {
  @include flexbox(row, flex-end);
}

.pa-Diff-InputField-DisplayValue {
  display: inline-flex;
  align-items: center;
  .pa-Diff-InputField-DisplayValue-key {
    flex-basis: 110px;
  }
}
