@import '~styles/variables';
@import '~styles/utilities';

#Templates {
  #newTemplateButton {
    padding-left: 7px;
    padding-right: 7px;
  }
}

#CreateTemplateModal {
  text-align: center;
  button,
  input {
    width: 100%;
  }
}
