@import '~styles/variables';
@import '~styles/utilities';

.pa-workflow-revisions-select {
  .react-select__value-container {
    padding: 0;
  }

  .wi-workflow-revisions-ValueContainer {
    display: inline-block;
    max-width: 350px;
    min-width: 220px;
    padding-left: 10px;
    padding-top: 10px;
    padding-bottom: 10px;

    &.isFocused {
      background-color: $highlight_select;
    }

    &.isSelected {
      border-left: 5px solid $blue;
    }
  }

  .wi-Select-Container .react-select__option--is-selected {
    background-color: white;
  }
  .wi-Select-Container .react-select__option--is-focused {
    background-color: $highlight_select;
  }

  .wi-workflow-revisions-BoldText {
    font-weight: $font-weight-heavy;
    color: $text_color;
  }

  .wi-workflow-revisions-DarkGrayText {
    color: $charcoal;
  }
}
