@import '../TemplateDiffReportUtils';

.wi-Diff-Heading-Title {
  display: inline-block;
  width: 100%;
  margin-bottom: 1px;
}

.wi-Diff-Heading-TitleText {
  @include diffText();
  display: inline;
  line-height: $font-size-large + 2px;
  width: 100%;
}
