@import '~styles/variables';

.parsabotViewContainer {
  min-height: 100%;
  min-width: 100%;
  font-size: $font-size-base;
  overflow: hidden;
  padding: 0 $spec-padding-small;
}

.parsabotViewBanner {
  margin-right: -$spec-margin-small;
  margin-left: -$spec-margin-small;
}

.parsabotViewHistory {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  height: calc(100vh - 199px);
}

.parsabotViewMessage {
  max-width: 80%;
  min-width: 100px;
  border-radius: 4px;
  background: $light_grey;
  margin: 14px 6px;
  padding: 12px 16px 6px 16px;
  position: relative;
  a {
    text-decoration: underline !important;
  }

  &.userMessage {
    align-self: flex-end;
    background: $lochmara;
    color: white;
    overflow-wrap: break-word;
    a {
      color: white;
    }
  }

  & > p {
    margin-bottom: 16px;
  }

  & > .parsabotViewMessageBody {
    margin-bottom: 2rem;
  }

  & > .parsabotViewMessageBody > pre > code {
    text-wrap: wrap;
  }

  & > .parsabotViewMessageBody > p {
    margin-bottom: 1rem;
  }

  & > .parsabotViewMessageBody li {
    margin-bottom: 0;
  }

  & > .parsabotViewMessageBody ol,
  & > .parsabotViewMessageBody ul {
    margin: 0.5rem 0 0.5rem 1rem;
  }

  & > .parsabotMessageDateContainer {
    position: absolute;
    bottom: 2px;
    right: 16px;

    & > .parsabotMessageCreatedDate {
      opacity: 0.5;
      font-size: $font-size-micro;
      display: inline-block;
    }

    & > .parsabotFeedbackText {
      color: $blue;
      font-size: $font-size-micro;
      opacity: 0.5;
      margin-right: 6px;
      display: inline-block;

      &:hover {
        cursor: pointer;
        color: black;
        & > {
          opacity: 1;
        }
      }
    }

    & > .parsabotFeedbackIcon {
      position: relative;
      top: 2px;
      height: 12px;
      width: 12px;
      margin-right: 6px;
      display: inline-block;
      opacity: 0.5;

      svg {
        height: 12px;
        width: 12px;
      }
    }
  }

  & > .loadingMessage {
    position: absolute;
    bottom: 6px;
    right: 16px;
    opacity: 0.5;
  }
}

.parsabotFeedbackThumbsIcon {
  color: transparent;
  text-shadow: 0 0 0 $dark_grey;
  cursor: pointer;
  margin-right: $spec-margin-small/2;
  font-size: $font-size-large;

  &.active {
    color: initial;
  }
}

.parsabotViewSubmitContainer {
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  background: white;
  height: 38px;
  width: 100%;
  padding: 0 16px;
  margin-bottom: 10px;
  button {
    max-height: 42px;
    height: 100%;
    align-self: center;
  }
  .parsabotViewSubmitChatInputWrapper {
    height: auto;
    width: 100%;
    label {
      position: fixed;
    }
    textarea {
      width: 100%;
      max-width: 100%;
      resize: none;
      min-height: 100%;
    }
  }
}
