@import '~styles/variables';

.wi-StepList {
  color: $charcoal;
  position: relative;
}

.wi-StepListItem {
  // text-overflow: ellipsis;
  white-space: nowrap;
  // overflow-x: hidden;
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */

  /* No support for these yet, use at own risk */
  -o-user-select: none;
  user-select: none;
  cursor: pointer;

  &:last-of-type {
    padding-bottom: 3px;
  }

  &.hoverOver {
    border: 2px solid $blue;
  }
  &.hoverBelow {
    border-top: 2px solid $blue;
  }
  &.hoverAbove {
    border-bottom: 2px solid $blue;
  }
}

.wi-StepListItem-Title {
  display: flex;
  justify-content: space-between;
}

.wi-StepListItem-TitleLeft {
  z-index: 1;

  .wi-Icon {
    margin-right: 5px;
  }
  .pa-help {
    display: flex;
  }
  .pa-StepField-RequiredFieldOverlay {
    width: 200px;
  }
}

.wi-StepListItem-TitleText {
  flex-shrink: 5;
  overflow-x: hidden;
  text-overflow: ellipsis;
  z-index: 1;
  margin-left: 3px;
  margin-right: 8px;
}

.wi-StepListItem-StepGroup {
  cursor: pointer;
}

.wi-StepListAssignee {
  float: right;
  font-size: $font-size-small;
  padding-left: 0.3rem;
  padding-top: 0.3rem;
  text-transform: uppercase;
  z-index: 1;
  overflow: hidden;
  text-overflow: ellipsis;
}

.wi-StepListChildren {
  margin-left: $font-size-base;
}
