@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-NoticeSimplified {
  display: flex;

  .wi-EditorNoticeIconWidget {
    .wi-EditorNoticeIconWidget-Arrow {
      bottom: 0px;
    }

    &:not(.wi-EditorNoticeIconWidget-Editable) {
      .wi-EditorNoticeIconWidget-Arrow {
        display: none;
      }
    }
  }

  .pa-RefField-Multi {
    flex-grow: 100;
    border-left: 1px solid #c1c1c1;
    flex: 1;
    overflow-y: auto;
    min-height: 40px;
    max-height: calc(100vh - 300px);
    padding: 5px;
    border: 0;

    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      max-height: calc(100vh / 3.5);
    }

    .wi-Notice-Text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;
      font-size: 16px;
      resize: none;
      margin-bottom: 0;
      border: 0;
      padding: 6px 9px;
      min-height: 44px;
      white-space: pre-wrap;
      word-wrap: break-word;
    }
  }

  .pa-EditorNoticeIconWidget {
    align-self: flex-start;
    margin-top: 16px;
    margin-left: 16px;
  }

  .wi-Notice-TextContainer {
    border: 0;
    .wi-Notice-Text {
      font-size: 16px;
    }
  }

  &.wi-Notice-Caution {
    background-color: $light_orange;
    border: 1px solid $chamois;

    color: $brown;
  }

  &.wi-Notice-Warning {
    background-color: $pink;
    border: 1px solid $red;
    color: $dark_red;
  }

  &.wi-Notice-Info {
    background-color: $twilight_blue;
    border: 1px solid $lochmara;
    color: $dark_blue;
  }
}
