@import '~styles/variables';
@import '~styles/utilities';

$padding-editable: 3px;

.wi-Editable-boundingInput {
  @include flexbox(row, flex-start);

  .wi-Editable-innerInput {
    @include flexbox(row, flex-start);
    margin-left: -$padding-editable;
    padding: ($padding-editable - 1px) $padding-editable;

    input {
      flex: 1 1 auto;
      width: 100%;
    }
  }

  .wi-Editable-hover {
    background-color: $light_grey;
  }

  .wi-Editable-parentInput {
    flex: 1 1 auto;
  }

  .wi-Editable-iconWrap {
    @include flexbox(row, center, center);
    // IE appends an additional child svg upon clicking, so hide all children svgs except the last one
    // Since other browsers don't append child svg inside svg, these rules won't be applied
    span {
      svg {
        svg {
          visibility: hidden;
          &:last-child {
            visibility: visible;
          }
        }
      }
      &.wi-Icon-Hidden {
        svg {
          svg {
            visibility: hidden;
          }
        }
      }
    }
  }
}

.wi-dummy {
  position: absolute !important;
  top: -999em;
  left: -999em;
  visibility: hidden !important;
  overflow: scroll;
  white-space: pre;
}
