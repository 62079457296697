@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.pa-editorLayout {
  height: 100%;
  display: flex;
  flex-direction: column;
  border-top: 1px solid $grey;

  header {
    min-height: 50px;
    background-color: $light-grey;
    border-bottom: 1px solid $grey;
    padding: 0 $pa-container--gutter_width;
    @include flexbox(row, space-between, center);

    // IE11 fix because align-items: center + min-height doens't work correctly in IE11
    &:after {
      content: '';
      min-height: inherit;
      font-size: 0;
      display: block;
    }
  }

  .pa-editorLayout-title {
    flex: 1;
    color: $charcoal;
    font-size: 22px;
    font-weight: 600;
    .wi-Icon {
      margin-left: 15px;
    }
  }

  .pa-editorLayout-controls {
    display: flex;
  }

  .pa-editorLayout-body-container {
    height: 100%;
  }

  .pa-editorLayout-content {
    display: flex;

    .pa-editorLayout-sidebar {
      @include flexbox(column, null, null);
      width: 25%;
      background-color: $white;

      @media (max-width: 1300px) {
        width: 320px;
      }

      .tabs {
        border-bottom: 1px solid $grey;
        height: 45px;
        line-height: 45px;
        flex: none;
        padding: 0px 0px 0px 0px;

        @include flexbox(null, null, stretch);

        .tab-link {
          color: $charcoal;
          display: inline-block;
          font-weight: $font-weight-semibold;
          text-decoration: none;
          padding: 0px 10px;
          flex: 1 0 85px;
          text-align: center;
          cursor: pointer;
          text-transform: capitalize;
        }
        .tab-link-active {
          color: var(--primaryA, $blue);
          font-weight: 600;
          border-bottom: 2px solid var(--primaryA, $blue);

          &:active {
            color: var(--primaryA, $blue);
          }
          &:hover {
            color: var(--primaryA, $blue);
          }
        }
      }
    }

    .pa-editorLayout-body {
      position: relative;
      z-index: 1;
      width: 75%; /* IE doesn't like width-less flex boxes */
      background-color: #f7f7f7;

      @media (max-width: 1300px) {
        width: calc(100vw - 320px);
      }

      @include flexbox(column, space-between, stretch);
    }
  }
}
