@import '~styles/variables';

.wi-Tooltip {
  position: relative;
  display: inline-block;
  line-height: 0;

  .wi-Tooltip-content {
    position: absolute;
    z-index: 1000000;
    top: 38px;
    transform: translate3d(0, 0, 0);
    transition: 250ms ease-in-out;
    background-color: $dark_blue;
    border-radius: 5px;
    padding: 10px 15px;
    white-space: nowrap;
    font-size: $font-size-base;
    line-height: $line-height-base;
    font-weight: $font-weight-regular;
    color: $white;
    font-style: normal;
    text-align: left;
    text-transform: none;
  }

  &:not(.wi-Tooltip-open) {
    .wi-Tooltip-content {
      display: none;
    }
  }
}

.wi-PrintReport-Controls .wi-Tooltip-content {
  left: 10px;
  background-color: $white;
  color: $charcoal;
  border-radius: 0;
  box-shadow: 0 5px 17px 7px rgba(0, 0, 0, 0.15);
  padding: 0;
  min-width: 300px;
}
