@import '~styles/variables';
@import '~styles/utilities';

#wi-TemplateView {
  height: 100%;

  .wi-templateView-cardContainer {
    z-index: 1;
    position: absolute;
    left: calc(100% + 15px);
    top: 0;
    bottom: 0;
  }

  .wi-TemplateView-Container {
    @include flexbox(row, space-around, stretch);
    overflow: scroll;
  }

  .wi-TemplateView-Right {
    min-width: 220px;
    max-width: 440px;
    padding: 10px;

    .wi-TemplateView-RightHeading {
      font-size: 2rem;
      border-bottom: $grey;
      margin-bottom: 10px;
    }

    li,
    label {
      font-size: 1.2rem;
      color: $text_color;
      cursor: pointer;
    }

    ul {
      list-style-type: none;
    }
  }

  .wi-TemplateView-Main {
    width: 852px;
    position: relative;
  }

  .wi-JobReportSteps-Container {
    min-height: calc(100% - 5px);
    margin-top: -10px;
    background-color: $white;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    padding: 0 30px 30px;
    color: $black;
    h1 {
      padding: $spec-padding 0 0;
      margin: 0;
      color: $black;
      line-height: 1.2;
    }
    h3 {
      color: $black;
      margin-bottom: 0;
      line-height: 1.3;
    }

    //first level
    > .wi-JobReportNode > .wi-JobReportNode-StepGroup > .wi-JobReportNode-Step {
      margin-top: 35px;
    }
    > .wi-JobReportNode > .wi-JobReportNode-Body.wi-JobReportNode-StepGroup {
      border: 0;
      background: transparent;
    }
    > .wi-JobReportNode > .wi-JobReportNode-Header-Container {
      margin-bottom: 30px;
      .step-heading-title {
        padding-left: 10px;
      }
    }
  }
  .wi-TemplateView-Pill {
    border-radius: 16px;
    height: 26px;
    padding: 0 10px;
    border: 1px solid black;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
    margin-left: 10px;
    vertical-align: bottom;
    white-space: nowrap;
  }
}

.wi-JobReportFields {
  .col-field-value-template {
    & > div {
      text-align: left;
    }
    border-bottom: 1px solid black;
    width: 40%;
  }
}

@media screen and (min-width: 1200px) {
  #wi-TemplateView {
    .wi-TemplateView-Left {
      min-width: 180px;
    }
  }
}
