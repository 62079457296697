@import '~styles/variables';

.d3-filters {
  .Select,
  .wi-Input input {
    margin-bottom: 0;
  }

  .wi-Input input {
    height: 36px;
  }

  .d3-filter {
    position: relative;
    margin: 0 20px 5px 0;
  }

  .d3-filter-addNew {
    padding: 6px;
    display: inline-flex;
    align-items: center;

    > * + * {
      margin-left: 10px;
    }
  }

  hr {
    margin: 10px 0;
  }

  .d3-filter-delete {
    position: absolute;
    top: 8px;
    right: -20px;
  }
}

.d3-filter-option {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.Select-value-label .d3-filter-option-label {
  flex-basis: calc(100% - 23px);
  max-width: calc(100% - 23px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.d3-filter-option-icon {
  display: inline-flex;
  margin-top: 2px;
  margin-right: 10px;

  svg {
    width: 16px;
    height: 16px;

    .Select-option.is-selected & {
      path {
        fill: $white;
      }
    }
  }

  .Select & {
    margin-top: 8px;
  }
}
