@import '../TemplateDiffReportUtils';

$icon_square: 40px;
.wi-Diff-Template-Heading {
  @include flexbox(row, flex-start, flex-start);
  width: 100%;
}

.wi-Diff-Template-IconWrapper,
.wi-Diff-Template-InfoWrapper {
  @include flexbox(column, center, flex-start);
  .pa-Rev-AnnotatorWrapper {
    max-width: 100%;
  }
}

.wi-Diff-Template-IconWrapper {
  margin-top: -7px;
}

.wi-Diff-Template-IconWrapper {
  margin-top: -7px;
}

.wi-Diff-Template-InfoWrapper {
  flex: 1 1 auto;
  flex-basis: 100%;
}

.wi-Diff-Template-InfoWrapper {
  .wi-Diff-Heading-TitleText {
    @extend %type-extra-large-header;
  }
}

.wi-Diff-Template-IconEle {
  @include flexbox();
  .wi-Icon {
    height: $icon_square;
    width: $icon_square;
  }
}

.wi-Diff-Template-TimeOfEditWrapper {
  @include flexbox(row);
}

.wi-Diff-Template-NumOfEdits {
  color: $blue;
  font-size: 13px;
}
