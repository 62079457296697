@import '~styles/colors';

.directory-group-selector {
  .selector-value {
    background-color: $blue;
    border-radius: 2px;
    border: 1px solid $blue;
    color: $white;
    display: inline-block;
    font-size: 0.9em;
    line-height: 1.4;
    margin-left: 5px;
    margin-top: 5px;
    vertical-align: top;
    .selector-value-icon {
      cursor: pointer;
      border-right: 1px solid $blue;
      padding: 2px 5px 2px;
    }
  }
  .disabled {
    @extend .selector-value;
    border: 1px solid $dark_grey;
    background-color: $dark_grey;
  }
}
