@import '~styles/variables';
@import '~styles/utilities';

.wi-TemplateTitleEditor {
  .wi-TemplateTitleEditor-Title {
    display: flex;
    justify-content: flex-start;

    .wi-Icon {
      margin-left: 10px;
    }
    /* how to detect IE browser */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      /* IE10+ CSS styles go here */
      .wi-Icon {
        display: inline;
        align-self: center;
      }
    }

    .wi-RequiredBadge {
      height: auto;
      width: auto;
      left: auto;
      font-size: 1.9rem;
    }
  }

  header {
    @include flexbox(row-reverse, space-between, center);
    min-height: 50px;
    background-color: $light_grey;
    border-bottom: 1px solid $grey;
    padding: 0 10px;
  }

  .wi-EditorTitle-TitleText {
    @extend %type-extra-large-header;
    padding: 3px;
    margin: 0;
    min-width: 280px;
  }

  .wi-EditorTitleCategory {
    margin-top: 10px;
    cursor: pointer;
  }

  .wi-Split-Button .wi-Dropdown-Wrapped {
    min-width: 240px;
    left: 0;
  }
}

.wi-TemplateEditor-container {
  border-top: 1px solid $grey;
}

.wi-TemplateTitleEditor-controls {
  @include flexbox();
  flex: 0 0 auto;
  align-items: center;

  .wi-Dropdown-Item {
    padding: 6px 8px;
    font-size: 14px;
    cursor: pointer;

    &:hover {
      background-color: $highlight_grey;
    }
  }

  .wi-Split-Button {
    margin-right: 0px;

    .wi-Icon {
      margin-right: 0;
    }

    .wi-Dropdown-Wrapped {
      right: 0px;
      top: 40px;
    }

    .wi-Dropdown-Internal {
      min-width: 200px;
      border-radius: 5px;

      ul {
        width: 100%;

        .wi-Dropdown-Item {
          text-align: unset;
          height: auto;
          padding: 10px;
        }
      }
    }

    .wi-Dropdown-Title {
      font-size: 13px;
      font-weight: $font-weight-mbold;
      margin-bottom: 5px;
      color: $charcoal;
    }

    .wi-Dropdown-Desc {
      color: $dark_grey;
      font-size: 12px;
      font-weight: $font-weight-regular;
      max-width: 300px;
      white-space: pre-line;
    }
  }
}
