@import '~styles/variables';
@import '~styles/utilities';

.pa-IntegrationResolver {
  .pa-RefField-Single {
    //background-color: $light_grey;
    //border: solid 1px $grey;
    color: $dark_grey;
    line-height: 26px;
    padding-left: 0px;
  }
  label {
    text-transform: capitalize;
  }
}
