@import '../TemplateDiffReportUtils';

$icon_square: 25px;
.wi-Diff-StepGroup-Heading {
  @include flexbox();
  .wi-Diff-StepGroup-Position,
  .wi-Diff-Heading-TitleText {
    font-size: $font-size-medium;
    font-weight: bold;
  }
}

.wi-Diff-StepGroup-HeadingWrapper {
  background-color: $light_grey;
  padding: $template-padding;
}

.wi-Diff-StepGroup-Heading > div {
  margin-right: 10px;
}

.wi-Diff-StepGroup-IconWrapper,
.wi-Diff-StepGroup-InfoWrapper {
  @include flexbox(column);
}

.wi-Diff-StepGroup-IconWrapper {
  img {
    height: 35px;
    width: 35px;
  }
}

.wi-Diff-StepGroup-InfoWrapper {
  margin-left: 5px;
}

.wi-Diff-StepGroup-IconEle {
  @include flexbox();

  .wi-Icon {
    height: $icon_square;
    width: $icon_square;
  }
}

.wi-Diff-StepGroup-TitleWrapper {
  @include flexbox();
}
