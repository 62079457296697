@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.pa-InfoPanel {
  @include flexbox(row, flex-start, center);
  background-color: $charcoal;
  border-radius: 2px;
  color: $white;
  padding: 10px 10px 10px 10px;

  .pa-InfoPanel--content {
    flex: 1;
    padding: 0px 10px 0px 10px;
  }
}
