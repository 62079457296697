@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-Notice {
  break-inside: avoid;

  .wi-Icon:not(.pa-Notice-NoticeIcon) {
    visibility: hidden;
    margin-right: 8px;
  }

  &:hover {
    .wi-Icon:not(.pa-Notice-NoticeIcon) {
      visibility: visible !important;
      cursor: pointer;
    }
  }

  .wi-Notice-Content {
    margin-top: 10px;
    margin-bottom: 10px;
    @include flexbox();

    .wi-Notice-EditablePanel {
      flex-grow: 100;
      width: 100%;
      background-color: #fff;
      border: 1px solid #c1c1c1;
      @include flexbox();

      .wi-Notice-IconContainer {
        align-self: center;
        background: transparent;

        img {
          height: 30px !important;
          width: 30px !important;
        }

        .wi-Document-Uploading {
          img {
            margin: 9px;
            height: 20px;
            width: 20px;
            visibility: visible;
          }
          span:first-of-type {
            display: none;
          }
        }
      }
    }

    .wi-Notice-TextContainer {
      flex-grow: 100;
      border-left: 1px solid #c1c1c1;
      flex: 1;
      overflow: visible;
      min-height: 40px;
      max-height: calc(100vh - 300px);
      padding: 5px;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        max-height: calc(100vh / 3.5);
      }

      @media print {
        overflow: initial;
        max-height: 100%;
        height: 100%;
        width: 100%;
        .wi-Notice-TextContainer-Scroll {
          display: block;
          width: auto;
          height: auto;
          overflow-y: initial;
        }
      }
      .wi-Notice-Text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        resize: none;
        margin-bottom: 0;
        border: 0;
        padding: 6px 9px;
        min-height: 44px;
        white-space: pre-wrap;
        word-wrap: break-word;
      }
    }

    .wi-Notice-TextContainer-Scroll {
      overflow-y: auto;
    }
  }
  .wi-Notice-IconContainer {
    background-color: $white;
    display: flex;
  }
  &:not(.wi-Notice-Editable) {
    .wi-Notice-IconContainer {
      .Document a {
        cursor: default;
      }
    }
  }

  &.wi-Notice-Caution {
    .wi-Notice-Content {
      .wi-Notice-EditablePanel {
        background-color: $light_orange;
        border: 1px solid $chamois;
        color: $charcoal;
        .wi-Notice-IconContainer {
          align-self: flex-start;
          margin-top: 16px;
          margin-left: 16px;
        }
      }
      .wi-Notice-TextContainer {
        border: 0;
        .wi-Notice-Text {
          font-size: 16px;
        }
      }
    }
  }

  &.wi-Notice-Warning {
    .wi-Notice-Content {
      .wi-Notice-EditablePanel {
        background-color: $pink;
        border: 1px solid $red;
        color: $charcoal;
        .wi-Notice-IconContainer {
          align-self: flex-start;
          margin-top: 16px;
          margin-left: 16px;
        }
      }
      .wi-Notice-TextContainer {
        border: 0;
        .wi-Notice-Text {
          font-size: 16px;
        }
      }
    }
  }

  &.wi-Notice-Info {
    .wi-Notice-Content {
      .wi-Notice-EditablePanel {
        background-color: $twilight_blue;
        border: 1px solid $lochmara;
        color: $charcoal;
        .wi-Notice-IconContainer {
          align-self: flex-start;
          margin-top: 16px;
          margin-left: 16px;
        }
      }
      .wi-Notice-TextContainer {
        border: 0;
        .wi-Notice-Text {
          font-size: 16px;
        }
      }
    }
  }
}
