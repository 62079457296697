@import '~styles/variables.scss';
.public-DraftEditorPlaceholder-root {
  position: absolute;
  pointer-events: none;
  color: $dark_grey;
}

.wi-StepDescription-Controls-Delete {
  align-self: center;
  color: $blue;
  margin-right: 10px;
  text-align: right;
  flex: 1;

  span {
    padding: 4px;
  }
}

.RichEditor-controls {
  font-size: 14px;
  user-select: none;

  display: flex;
  justify-content: center;
  align-items: center;

  // pushes last set of control ( media ) to the right
  &:last-of-type {
    margin-left: 16px !important;
  }

  > span {
    margin-right: 16px;
  }

  &.font-selector {
    padding: 10px 15px 10px 10px;
  }

  .wi-Select-Container {
    .react-select__control {
      height: 30px;
    }
  }

  .RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 6px;
    padding: 2px 0;
    display: inline-block;
  }

  .RichEditor-activeButton {
    color: #5890ff;
  }
}

// Styles for font-selector options. They are tethered hence global class
.RichEditor-controls-fontSelectorItems {
  h1,
  h2,
  p {
    padding: 0px 0px;
    margin: 0px 0px;
  }

  h1 {
    font-size: 21px;
    line-height: 25px;
    font-weight: 400;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }
}

.RichEditor-editor {
  ul,
  ol,
  li {
    margin-bottom: 0px;
  }

  // normal paragraph should have 6px bottom padding
  div > .public-DraftStyleDefault-block {
    padding-bottom: 6px;
  }

  h1 {
    font-size: 21px;
    line-height: 25px;
    font-weight: 400;
    letter-spacing: auto;
    padding-top: 8px;
    padding-bottom: 4px;
    margin-block-end: 0px;
    margin-block-start: 0px;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: auto;
    padding-top: 4px;
    padding-bottom: 2px;
    margin-block-end: 0px;
    margin-block-start: 0px;
  }

  ul li > div,
  ol li > div {
    display: inline;
  }

  /**
 * Default spacing for list container elements. Override with CSS as needed.
 */
  .public-DraftStyleDefault-ol,
  .public-DraftStyleDefault-ul {
    margin: 10px 0;
    padding: 0;
  }

  /**
  * Default counters and styles are provided for five levels of nesting.
  * If you require nesting beyond that level, you should use your own CSS
  * classes to do so. If you care about handling RTL languages, the rules you
  * create should look a lot like these.
  */

  .public-DraftStyleDefault-unorderedListItem {
    /**
    * Only use `square` list-style after the first two levels.
    */

    list-style-type: square;
    position: relative;

    &.public-DraftStyleDefault-depth0 {
      list-style-type: disc;
    }

    &.public-DraftStyleDefault-depth1 {
      list-style-type: circle;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
      margin-left: 1em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
      margin-right: 1em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
      margin-left: 2.5em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
      margin-right: 2.5em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
      margin-left: 3em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
      margin-right: 3em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
      margin-left: 4em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
      margin-right: 4em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
      margin-left: 5.5em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
      margin-right: 5.5em;
    }
  }

  .public-DraftStyleDefault-orderedListItem {
    /**
    * Ordered list item counters are managed with CSS, since all list nesting is
    * purely visual.
    */
    list-style-type: none;
    position: relative;

    /* @noflip */
    &.public-DraftStyleDefault-listLTR:before {
      left: -36px;
      position: absolute;
      text-align: right;
      width: 30px;
    }

    /* @noflip */
    &.public-DraftStyleDefault-listRTL:before {
      position: absolute;
      right: -36px;
      text-align: left;
      width: 30px;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
      margin-left: 2.5em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
      margin-right: 2.5em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
      margin-left: 4em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
      margin-right: 4em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
      margin-left: 4.5em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
      margin-right: 4.5em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
      margin-left: 5em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
      margin-right: 5em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
      margin-left: 6em;
    }

    /* @noflip */
    &.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
      margin-right: 6em;
    }

    /**
  * Counters are reset in JavaScript. If you need different counter styles,
  * override these rules. If you need more nesting, create your own rules to
  * do so.
  */
    &:before {
      content: counter(ol0) '. ';
      counter-increment: ol0;
    }

    &.public-DraftStyleDefault-depth1:before {
      content: counter(ol1, lower-alpha) '. ';
      counter-increment: ol1;
    }

    &.public-DraftStyleDefault-depth2:before {
      content: counter(ol2, lower-roman) '. ';
      counter-increment: ol2;
    }

    &.public-DraftStyleDefault-depth3:before {
      content: counter(ol3) '. ';
      counter-increment: ol3;
    }

    &.public-DraftStyleDefault-depth4:before {
      content: counter(ol4, lower-alpha) '. ';
      counter-increment: ol4;
    }
  }

  .public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
    counter-reset: ol0;
  }

  .public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
    counter-reset: ol1;
  }

  .public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
    counter-reset: ol2;
  }

  .public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
    counter-reset: ol3;
  }

  .public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
    counter-reset: ol4;
  }
}

.wi-StepDescription.wi-StepDescription-Editing .wi-StepDescription-Controls {
  height: 40px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}
