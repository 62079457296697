@import '~styles/variables';
@import '~styles/utilities';

.wi-TemplateViewTitleV2 {
  @include flexbox(row, space-between, center);
  padding: 7px 12px;
  background-color: $light_grey;
  border-bottom: 1px solid $grey;

  &__title {
    @extend %type-extra-large-header;
  }

  &__version {
    @extend %type-header-title;
    margin-left: 10px;
    color: $dark_grey;
  }
}
