@import '~styles/variables.scss';
@import '~styles/utilities.scss';

$border-radius: 4px;

.pa-IntegrationForm-Generic {
  [class*='flexboxgrid--col'] {
    padding-bottom: 0;
  }
  .pa-RefField-Single {
    display: flex;
    flex: 1;
    align-items: center;
    .DraftEditor-root {
      flex: 1;
    }
    &.disabled {
      border: solid 1px $grey;
    }
  }
  .pa-FormLabel-WithHint {
    display: flex;
    justify-content: space-between;
    .hint {
      color: $dark_grey;
      font-style: italic;
    }
  }

  select,
  input[type='text'] {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;

    &.error {
      border: 1px solid $red;
    }

    &::-ms-clear {
      display: none;
    }
  }

  .row {
    display: flex;
    padding: 7px 0;
    margin-bottom: 10px;
    flex-wrap: wrap;

    .pa-IntegrationForm-Api-index {
      text-align: right;
      padding-top: 4px;
    }
    &.pa-IntegrationForm-Api-email,
    & .pa-IntegrationForm-Api-name {
      display: flex;
      align-items: center;
      justify-content: center;
      .wi-Input,
      .pa-RefField-Single,
      .Select {
        flex-basis: 100%;
        min-height: 38px;
        word-break: break-all;
      }
      #pa-IntegrationForm-EmailBody {
        max-width: 840px;
      }
    }
  }
  .wi-RequiredLegend {
    margin-top: 20px;
  }

  .pa-Pill-Static-Variable {
    border: 1px solid $grey;
    color: $charcoal;
    background-color: $light_grey;
    @include zoomAnimation();
  }

  .pa-RefPicker-Container {
    margin-bottom: 50px;
  }
}

.pa-IntegrationForm-Email {
  .pa-RefField-Multi {
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border: solid 1px $grey;
    margin: 0 0 1em 0;
    padding: 1em;
    background-color: $white;
    flex: 1;
    min-height: 200px;
    color: $black;
    font-weight: 500;
    &.active {
      border-color: $blue;
    }
    .DraftEditor-root {
      flex: 1;
    }
  }
}

.pa-IntegrationForm-Api {
  .pa-Icon--collapse {
    margin-right: 10px;
  }

  .pa-IntegrationForm-Api-responseInfo {
    margin-bottom: 0px;
    padding-bottom: 0px;
    .flexboxgrid--col-xs-9 {
      padding-left: 0px;
    }
  }

  .pa-IntegrationForm-Api-response,
  .pa-IntegrationForm-Api-headers {
    align-items: center;
    border-radius: 5px;
    &:hover {
      background-color: $highlight_table_row;

      .pa-IntegrationForm-Api-Controls {
        opacity: 1;
      }
    }
    &.row--invisible {
      opacity: 0;
    }
  }

  .pa-IntegrationForm-Api-responseError {
    margin: 0px;
    padding: 0px;
  }

  // creating IDE like style
  .pa-RefField-Multi {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
    font-family: Courier, 'New Courier', monospace;
    font-size: 14px;
    color: #98c379;

    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    margin: 1em 0;
    padding: 1em;
    border: 1px solid $charcoal;

    background-color: #1c2022;
    min-height: 200px;
    display: flex;

    .pa-Pill-Static-Variable {
      border: 1px solid $grey;
      color: $charcoal;
      background-color: $light_grey;
      @include zoomAnimation();
    }

    .pa-RefPicker-Container {
      font-family: $font-family;
    }

    .DraftEditor-root {
      flex: 1;
    }

    .public-DraftEditor-content {
      min-height: 200px;
      display: block;
    }

    &.active {
      border: 1px solid $blue;
    }
  }

  .pa-IntegrationForm-ApiMethod {
    .Select-control {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }
    .Select {
      width: 90px;
    }
    .pa-RefField-Single {
      margin-left: -1px;
      flex: 1;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;

      display: flex;
      align-items: center;
      justify-content: stretch;
    }
  }

  .pa-IntegrationForm-Api-Controls {
    opacity: 0;
    padding-top: 4px;
    padding-right: 15px;
    text-align: right;

    .wi-Icon {
      margin-left: 12px;
    }
  }
}
.pa-apiForm-selectOption {
  display: flex;
  align-items: center;

  .wi-Icon {
    padding-right: 8px;

    svg {
      height: 12px;
      width: 12px;
    }
  }
}
