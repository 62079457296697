//----------------------------
// Global Styles (WebEx + Admin)
//----------------------------

@import '~styles/variables';
@import '~styles/utilities';
@import '~styles/colors';
@import './vendor';

* {
  box-sizing: border-box;
}
body {
  @media print {
    background-color: $white;
  }

  @media screen {
    background-color: $white;
  }
  margin: 0;
  padding: 0;

  .container {
    padding-left: $pa-container--gutter_width;
    padding-right: $pa-container--gutter_width;
    width: 100%;
    max-width: $pa-container--width_maxiumum;
    min-width: $pa-container--width_minimum;
  }
}

html {
  height: 100%;
  font-family: $font-family;
  font-size: 16px;
  font-weight: $font-weight-regular;
  color: $charcoal;
}

body {
  height: 100%;
  font-family: $font-family;
  font-size: 14px;
  font-weight: $font-weight-regular;
  color: $charcoal;
}
.wi-Admin,
.wi-App,
.wi-Unauthenticated {
  height: 100%;
}
.pa-jobReportWrapper {
  height: 100%;
  display: flex;

  iframe {
    flex: 1;
    height: 100%;
  }
}

a {
  color: $link_color;
  text-decoration: none !important;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    text-decoration: none !important;
  }

  > .wi-Icon:first-child {
    margin-right: 6px;
  }
}

// HEADERS
h1 {
  @extend %type-extra-large-header;
}
h2 {
  @extend %type-header-title;
}
h3 {
  @extend %type-header-medium;
}
h4,
.wi-tinyHeader {
  @extend %type-header-small;
}

// TABLES
th,
td {
  padding: $font-size-small;
}
.u-text-center {
  text-align: center;
}

// FORMS
input[type='email'],
input[type='number'],
input[type='search'],
input[type='text'],
input[type='tel'],
input[type='url'],
input[type='password'],
input[type='link'],
input[type='unitNumber'],
textarea,
select {
  height: 38px;
  padding: 6px 10px;
  /* The 6px vertically centers text on FF, ignored by Webkit */
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  color: $charcoal;
  font-weight: $font-weight-regular;
  @extend %type-text-regular;

  &::placeholder {
    color: $dark_grey;
    font-family: $font-family;
  }
}

textarea {
  font-family: $font-family;
  min-height: 65px;
  padding-top: 6px;
  padding-bottom: 6px;
}

input[type='email']:focus,
input[type='number']:focus,
input[type='search']:focus,
input[type='text']:focus,
input[type='tel']:focus,
input[type='url']:focus,
input[type='password']:focus,
input[type='unitNumber']:focus,
textarea:focus,
select:focus {
  border: 1px solid var(--secondaryA, $blue);
  outline: 0; //this is bad for accessibility
}

fieldset {
  padding: 0;
  border-width: 0;
}

input[type='checkbox'],
input[type='radio'] {
  display: inline;
}

label > .label-body {
  display: inline-block;
  margin-left: 0.5rem;
  font-weight: normal;
}

@media print {
  .avoid-break,
  pre,
  blockquote,
  img,
  video,
  audio {
    page-break-inside: avoid !important;
  }

  .avoid-break-after,
  h1,
  h2,
  h3 {
    page-break-after: avoid !important;
  }

  .Header {
    display: none;
  }

  .wi-JobReportHeader {
    border: 0;
  }

  .wi-JobDetailReport {
    background-color: transparent !important;
    .container {
      box-shadow: none !important;
      max-width: 100% !important;
    }
  }
}

//Job Report Styles.
.u-for-print {
  background: $white;
}

.u-for-print #report {
  border: none;
}

.u-for-print .pa-AppBody,
.u-for-print .pa-AppBody #react-root {
  background-color: $light_grey;
}

.u-for-print .wi-JobDetailReport {
  background-color: $light_grey;
  padding-top: 20px;
  padding-bottom: 20px;
  .container {
    max-width: 852px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 2px;
    border: 1px solid #ddd;
    background: #fff;
    padding: 0 50px;
    @media print {
      border: none;
    }
  }
}

.u-hidden {
  display: none;
}
.u-inline {
  display: inline-block;
}
.u-full-bleed {
  @include headerAtTop();
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  height: 100%;
}

.u-full-bleed.wi-Fail {
  position: fixed;
}

.u-clickable {
  cursor: pointer;
}

.u-no-margin-bottom {
  margin-bottom: 0;
}

// FORMS

label,
legend {
  @extend %type-text-medium;
}

.wi-FormGroup {
  margin-bottom: 2rem;
}
.wi-FormGroup--Fixed {
  // Super magical number. We need to be able to swap out form components at a
  // fixed height, and we need a better way to do it.
  height: 59px;
}
.wi-ArchiveToggler {
  text-align: center;
  cursor: pointer;
  font-size: $font-size-base;
}
.wi-Text-Obsidian {
  color: $charcoal;
}

.wi-List,
.wi-List ul {
  margin: 0 0 1rem 1rem;
  font-size: 100%;
  list-style: disc outside;
}

.wi-List {
  ul {
    margin-bottom: 0;
  }

  li {
    margin: 0;
  }
}

.sweet-alert {
  //https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  h2 {
    word-break: normal;
    hyphens: none;
  }
}

// SECTIONS
.pa-Section {
  margin-bottom: 30px;
}
// Short term solution to make metadata form
// labels match attributes.
.wi-MetadataLabel {
  font-size: 14px;
  font-weight: 500;
}

.flex {
  display: flex;
}
.flex__column {
  flex-direction: column;
}

@for $i from 1 through length($iconColors) {
  .wi-Icon--#{'' + nth($iconColorNames,$i)} {
    @extend %wi-Icon--#{'' + nth($iconColorNames,$i)};
  }
}

.type-extra-large-header {
  @extend %type-extra-large-header;
}

.type-header-title {
  @extend %type-header-title;
}

.type-header-medium {
  @extend %type-header-medium;
}

.type-step-input-headers {
  @extend %type-step-input-headers;
}

.type-header-small {
  @extend %type-header-small;
}

.type-header-title-placeholder {
  @extend %type-header-title-placeholder;
}

.type-text-medium {
  @extend %type-text-medium;
}

.type-text-medium--bold {
  @extend %type-text-medium--bold;
}

.type-text-large--xtra-bold {
  @extend %type-text-large--xtra-bold;
}

.type-text-regular {
  @extend %type-text-regular;
}

.type-nav {
  @extend %type-nav;
}

.type-nav-selected {
  @extend %type-nav-selected;
}
