@import '~styles/variables';
@import '~styles/utilities';

.wi-Rev-HeadingContainer {
  @include flexbox(row, center, stretch);
  height: 100%;
  background-color: $white;
  flex-basis: 150px;
  border: 1px solid grey;
  margin-bottom: 5px;
}

// if user prints template diff view, turn off
// overflows so the entire template can print
// clean up once FF fix the flex issue, ref: ATR-328
@media print {
  .pa-AppBody,
  .wi-Diff-Step-Wrapper {
    display: block !important; // needed for FF to display all pages
  }

  .pa-PageContent,
  #Templates,
  #wi-Rev,
  .wi-Rev-MainContainer,
  .wi-Rev-MainWrapper,
  .wi-Rev-Main,
  .wi-Diff-TemplateWrapper,
  .pa-Rev-AnnotatorWrapper,
  .wi-Diff-StepGroup-Container,
  .wi-Diff-Template-BodyWrapper,
  .wi-Diff-StepWrapper {
    display: block !important; // needed for FF to display all pages
    overflow: visible !important;
    min-height: auto !important;
    height: auto !important;
  }
  .no-print,
  .no-print * {
    display: none !important;
  }

  .pa-Rev-AnnotatorWrapper {
    position: relative !important;
  }

  .wi-Annotation-Wrapper {
    top: 25%;
    left: -20% !important;
  }

  nav {
    display: none !important;
  }

  .pa-PageName {
    padding-left: 0;
  }
}

.wi-Rev-Heading-MainWrapper {
  .Select {
    width: 320px;
  }
}

.wi-Rev-HeadingWrapper {
  @include flexbox(column, center, stretch);

  @media (max-width: 920px) {
    flex-basis: 90%;
  }
  @media (min-width: 921px) {
    flex-basis: 75%;
  }

  max-width: $max-width-diffs;
  min-width: $min-width-diffs;
  padding-bottom: 3px;
}

.wi-Rev-Heading-TitleWrapper {
  @include flexbox(row, flex-start);
}

.wi-Rev-Heading-MainWrapper,
.wi-Rev-Heading-TitleWrapper,
.wi-Rev-Heading-BottomBar {
  margin: 5px 0;
}

.wi-Rev-Heading-MainWrapper,
.wi-Rev-Heading-LeftWrapper,
.wi-Rev-Heading-RightWrapper {
  @include flexbox();
}

.wi-Rev-Heading-TitleText,
.wi-Rev-Heading-DD1Wrapper,
.wi-Rev-Heading-AndWrapper,
.wi-Rev-Heading-DD2Wrapper,
.wi-Rev-Heading-RestoreWrapper,
.wi-Rev-Heading-RestoreText,
.wi-Rev-Heading-LatestText,
.wi-Rev-Heading-EditButtonWrapper,
.wi-Rev-Heading-PublishButtonWrapper,
.wi-Rev-Heading-Ellipsis {
  @include flexbox();
}

.wi-Rev-Heading-RestoreText {
  cursor: pointer;
  color: $blue;

  &:hover {
    color: $light_blue;
    text-decoration: underline;
  }

  &:active {
    color: $primary-blue-hover;
  }
}

.wi-Rev-Heading-LatestText {
  color: $text_color;
  pointer-events: none;
  font-style: italic;
}

.wi-Rev-Heading-AndWrapper {
  margin: auto 10px;
}

.wi-Rev-Heading-EditButtonWrapper,
.wi-Rev-Heading-PublishButtonWrapper {
  @media (max-width: 920px) {
    margin-right: 5px;
  }
  @media (min-width: 921px) {
    margin-right: 10px;
  }
}

#wi-Rev-Heading-DD1Ele .Select-value-label,
#wi-Rev-Heading-DD2Ele .Select-value-label,
.wi-Rev-Heading-DD-option {
  color: $text_color;
}

#wi-Rev-Heading-DD1Ele,
#wi-Rev-Heading-DD2Ele {
  .Select-input {
    display: none !important;
  }
  .Select-control {
    @include flexbox();
    height: 51px;
  }
  @media (max-width: 920px) {
    .Select-input {
      padding: 2px;
    }
  }
  @media (min-width: 921px) {
    .Select-input {
      padding: 5px;
    }
  }
}
