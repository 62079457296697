@import '~styles/variables';
@import '~styles/utilities';

.pa-SignatureWrapper {
  flex: 1 1 0;
  .buttonWrapper {
    float: right;
    :nth-child(1),
    :nth-child(2) {
      margin-right: 5px;
    }
  }
  canvas {
    width: 100%;
    height: 200px;
    display: none;
    &.showSignature {
      display: block;
      margin: 0px 0px 5px 0px;
      border: 1px solid $grey;
      background: white;
      background: -webkit-linear-gradient(to top, #dfe8ec 0%, white 8%) 0 57px;
      background: -moz-linear-gradient(to top, #dfe8ec 0%, white 8%) 0 57px;
      background: linear-gradient(to top, #dfe8ec 0%, white 8%) 0 57px;
      -webkit-background-size: 100% 30px;
      -moz-background-size: 100% 30px;
      -ms-background-size: 100% 30px;
      background-size: 100% 30px;
      &::before {
        content: '';
        z-index: -1;
        margin: 0 1px;
        width: 706px;
        height: 10px;
        position: absolute;
        bottom: -3px;
        left: 0;
        background: white;
        border: 1px solid #b5b5b5;
      }
      &::after {
        content: '';
        position: absolute;
        width: 0px;
        top: 0;
        left: 39px;
        bottom: 0;
        border-left: 1px solid #f8d3d3;
      }
    }
  }
}
