@import '~styles/variables';
@import '~styles/utilities.scss';

.pa-Tooltip-container {
  @extend %type-text-large--xtra-bold;
  color: $charcoal;
  text-align: center;
  position: absolute;
  min-width: 250px;
  max-width: 400px;
  z-index: 100;
  background: $white;
  border: 1px solid $charcoal;
  border-radius: 6px;
  box-sizing: content-box;
  padding: 10px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.25);
  white-space: normal;
}

.pa-Tooltip-container-left {
  @include calloutArrow(-18px, 30px, bottom, 30px, $white);
}

.pa-Tooltip-container-right {
  @include calloutArrow(-250px, 30px, bottom, 30px, $white);
}

.pa-Tooltip-container-center {
  @include calloutArrow(-185px, 30px, bottom, 30px, $white);
}
