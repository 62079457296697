@import '~styles/utilities';
@import '~styles/variables';

.pa-JobList-FilterNav {
  display: inline-block;

  a,
  span {
    &:first-child {
      margin: 0 0.5rem;
    }
  }

  .wi-Dropdown {
    margin-right: -20px;
    align-items: center;
    display: inline-flex;
    position: relative;
    cursor: pointer;

    &.activeItem {
      .wi-DropdownLabel {
        font-weight: 700;
      }
    }

    &:after {
      content: '';
      position: absolute;
      right: 20px;
      top: 50%;
      margin-top: -2.5px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid var(--secondaryA, $blue);
      clear: both;
    }

    .wi-Dropdown-Wrapped {
      left: 0px;
      min-width: 200px;
    }

    .wi-Dropdown-Internal {
      a {
        white-space: nowrap;
        min-width: 100px;
        padding: 5px 30px;
        text-align: left;
        display: block;
        color: inherit;
        line-height: 28px;
        margin: 0px;
      }

      .wi-Dropdown-Item:hover {
        background-color: $blue;
        color: $white;
      }
    }
  }
}
