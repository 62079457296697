@import '~styles/colors.scss';
@import '~styles/variables';

.pa-InputSelection {
  .pa-InputSelection__Container {
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .pa-InputSelection__Title {
    line-height: 1;
  }

  .pa-InputSelection__Container__InputTypeSelector {
    display: flex;

    > *:nth-of-type(n + 2) {
      margin-left: 16px;
    }

    label {
      input {
        vertical-align: top;
      }
    }
  }

  .wi-StepFieldValidation-OptionSelection {
    .react-select__control,
    .react-select__menu {
      text-transform: none;
    }
  }

  .pa-InputRow {
    border: 1px solid $grey;
    padding: 8px 16px;
    display: flex;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;

      a > .wi-Icon:first-child {
        margin-right: 0px;
      }
    }

    .pa-Pill {
      max-width: 150px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-right: 5px;
    }

    .pa-InputRow__Name {
      color: $black;
      width: 60%;
      overflow-wrap: break-word;
      margin-right: 4px;
    }

    .pa-InputRow__References {
      width: 60%;
      margin-right: 4px;
      overflow-wrap: break-word;
    }

    .pa-InputRow__Notice {
      flex-grow: 1;
      justify-content: flex-start;
    }

    .pa-InputRow__Icon {
      margin-right: 8px;
    }

    .pa-InputRow__Message {
      color: #999999;
      font-size: 12px;
      margin-right: 4px;
    }

    .pa-InputRow__RemoveIcon {
      flex-grow: 1;
      display: flex;
      cursor: pointer;
      transform: scale(0.9, 0.9);
      justify-content: flex-end;
    }
  }

  .pa-InputRow + .pa-InputRow {
    border-top-width: 0px;
  }

  .pa-AllOptions {
    color: $charcoal;
    margin-top: 5px;
    cursor: text;
  }

  .pa-AddButton {
    align-items: center;
    cursor: pointer;
    padding: 8px 0px;
    display: flex;
  }
}
