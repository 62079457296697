@import '~styles/variables';
@import '~styles/utilities';

.wi-JobDisplayTemplate {
  background-color: $white;
  padding-bottom: 10px;

  .wi-JobDisplay-indent {
    padding: 10px 10px 0 10px;
  }

  .wi-JobDisplayTemplateHeader {
    align-items: center;
    display: flex;
    border: 1px solid $grey;
    background-color: $white;
    padding-bottom: 2px;
    padding-left: 20px;
    padding-top: 4px;
    position: relative;
    margin-bottom: 10px;
    flex-wrap: wrap;

    &::after {
      content: '';
      position: absolute;
      background-color: $light_grey;
      height: 16px;
      width: 16px;
      left: 20px;
      border-right: $border-standard;
      border-top: $border-standard;
      bottom: -7.5px;
      transform: rotate(135deg);
      clip-path: polygon(100% 100%, 0 0, 100% 0);
    }

    div {
      display: inline-block;
    }

    .wi-template-heading {
      margin-bottom: -2px;
      font-size: 15px;
    }
    .wi-template-subheading {
      flex-basis: 100%;
      color: $grey;
      margin-left: 23px;
      margin-right: 23px;
    }
    .completion-info {
      margin-top: 4px;
      font-size: 1rem;
      float: right;
      margin-left: 15px;
    }
  }

  .wi-MetadataLabel {
    color: $dark_grey;
    padding-top: 10px;
  }

  .equipment-row {
    background-color: $white;
    border: $border-standard;
  }

  .equipment-item {
    display: inline-block;
    background-color: $white;
  }

  .wi-JobDisplay-steps {
    border-bottom: $border-standard;
    border-right: $border-standard;
    border-left: $border-standard;
    margin-bottom: 10px;

    .wi-JobDisplay-stepElement {
      background-color: $white;

      .wi-JobDisplay-stepGroup,
      .wi-JobDisplay-step {
        border-top: $border-standard;
      }

      .wi-JobDisplay-stepElement-Header,
      .wi-JobDisplay-stepGroup,
      .wi-JobDisplay-step {
        @include flexbox(row, space-between, stretch);
      }

      .wi-JobDisplay-stepElement-Header {
        flex: 1 1 auto;
        align-content: center;
        .wi-JobDisplay-stepElement-Header-Title {
          flex: 2 2 auto;
          font-size: 14px;
          color: black;
          padding-left: 20px;
          word-break: break-all;
        }
        .wi-JobDisplay-stepElement-Header-JobRole {
          color: $charcoal;
          font-size: $font-size-small;
          padding-right: 20px;
          max-width: 15vw;
          white-space: nowrap;
        }
      }

      .wi-JobDisplay-stepGroup,
      .wi-JobDisplay-step {
        @include flexbox(row, space-between, center);
        padding: 10px;
      }

      .wi-JobDisplay-stepGroup {
        cursor: pointer;
        &:hover {
          background-color: $white;
        }
      }
    }
  }

  .wi-JobDisplay-BreadCrumbs {
    @include flexbox(row, flex-start, stretch);
    padding-left: 20px;
    background-color: $white;
    border-bottom: $border-standard;
    margin-bottom: 20px;

    .wi-JobDisplay-BreadCrumb,
    .wi-JobDisplay-FirstCrumb,
    .wi-JobDisplay-LastCrumb,
    .wi-JobDisplay-FollowingCrumbs,
    .wi-JobDisplay-LastCrumb,
    .wi-JobDisplay-Bread {
      @include flexbox(row, flex-start, center);
      padding: 3px;
      font-size: 14px;
    }

    .wi-JobDisplay-FirstCrumb,
    .wi-JobDisplay-FollowingCrumbs {
      color: $blue;
      cursor: pointer;

      &:hover {
        color: $primary-blue-hover;
      }
    }

    .wi-JobDisplay-LastCrumb {
      color: $charcoal;
    }
  }

  .wi-JobDisplay-BackArea {
    .wi-JobDisplay-BackIcon {
      height: 25px;
      padding-right: 5px;
    }

    @include flexbox(row, flex-start, stretch);
    margin-bottom: 20px;
    padding: 3px 23px;
    font-size: 14px;
    background-color: $white;
    border-bottom: $border-standard;
    color: $blue;
    cursor: pointer;

    &:hover {
      color: $dark_blue;
    }
  }

  .wi-JobReportNode-Header {
    @include flexbox(column, flex-start, stretch);
  }

  .wi-JobDisplay-groupHeader-icon {
    padding-right: 5px;
    padding-top: 0;
  }
}
#wi-JobDisplay-Notice {
  .wi-Notice-Content {
    margin-bottom: -1px;
  }
}

.wi-template-icon {
  height: 23px;
  width: 23px;
  z-index: 1;
  padding-top: 5px;
}

.wi-DraggableStep-Content .wi-template-icon {
  padding-top: 0;
}
