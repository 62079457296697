@import '~styles/variables.scss';

.wi-Select-Container {
  .react-select__control {
    min-height: unset;
    height: 34px;

    .react-select__control,
    .react-select__value-container,
    .react-select__value-container input[type='text'] {
      height: auto;
      align-items: center;
      margin-bottom: 0;
    }

    &.react-select__control--is-focused {
      border: 1px solid $light_blue;
      box-shadow: unset;
    }

    .react-select__value-container {
    }
    .react-select__indicators {
    }

    .react-select__indicator-separator {
      display: none;
    }
  }

  .react-select__menu {
    margin-top: 0px;
  }

  .react-select__option--is-focused {
    &:not(.react-select__option--is-selected) {
      background-color: $highlight_select;
    }
  }
  .react-select__option--is-selected {
    background-color: $blue;
    color: $white;
  }
}
