@import '~styles/utilities';
@import '~styles/variables';

.Form-Group {
  margin-bottom: 1.5rem;

  .control-label.required::after {
    content: '*';
    color: $red;
    margin-left: 2px;
  }
}

.Form-Group-content {
  &.Form-Group-content_inline {
    display: flex;

    > *:first-child {
      flex-grow: 1;
    }

    // some style overrides to remove border radius
    // for seamless alignments between children
    &:not(.Form-Group-content_singleLady) {
      > .wi-Input:first-child input,
      > *:first-child .Button {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      > .wi-Input:last-child input,
      > *:last-child .Button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: -1px;
      }
    }
  }

  // make button the full
  // height to match input
  .Button {
    height: 100%;
    min-width: 0;
    border-color: $grey;

    .wi-Icon {
      max-width: 25px;
      max-height: 25px;
      margin: 0;
    }
  }
}

.Form-Group_withError {
  .wi-Input input,
  .Button,
  .Select-control {
    border-color: $red !important;
  }
}

.Form-Group-Error {
  padding: 6px 0 0;
  color: $red !important;
}
