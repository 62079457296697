@import '~styles/variables';
@import '~styles/utilities';

.wi-JobReportMetadata {
  .metadatafield {
    display: flex;
    padding-top: 6px;
  }
  .metadatafield-label {
    font-weight: $font-weight-mbold;
  }
  .metadatafield-value {
    padding-left: 6px;
    word-break: break-word;
  }
}
