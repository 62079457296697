@import '~styles/variables';

.wi-Insights-LookList-Table-TitleCell {
  display: flex;
  align-items: center;

  & > .icon-content-loader {
    display: inline-block;
    width: 24px;
    height: 24px;
    animation-duration: 3.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-repeat: repeat;
    background-size: 1600px;
    border-radius: 2px;
    box-sizing: border-box;
  }

  & > .wi-Icon {
    flex-shrink: 0;

    svg {
      width: 24px;
      height: 24px;
      color: $dark_grey;
    }
  }

  & > .no-icon {
    display: inline-block;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  & > .link {
    display: inline-block;
    flex-shrink: 0;
    width: calc(100% - 24px - 16px);
    margin-left: 16px;
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
