@import '~styles/variables';
@import '~styles/utilities.scss';

.pa-Thread {
  @include flexbox(column, space-between, inherit);
  display: flex !important;
}

.pa-Thread--Body {
  flex: 0 1 auto;
  overflow-y: auto;
  padding: $spec-padding $font-size-h1;
  max-height: 300px;
  text-align: left;
}

.pa-Thread--Footer {
  width: 100%;
  flex: 0 0 auto;
  padding: 0.5rem 1rem;
  background-color: $light_grey;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top: 1px solid $grey;
}

.pa-Thread--Header {
  @include flexbox(row, space-between, inherit);
  flex: 0 0 auto;
  padding: 15px;

  > span {
    color: $charcoal;
    font-size: 10px;
    text-transform: uppercase;
  }

  .closeThread {
    border-color: $green;
    &:hover,
    &:focus,
    &:active {
      background-color: $dark_green;
      color: $white !important;
    }
  }

  .openThread {
    border-color: $grey;
    &:hover,
    &:focus,
    &:active {
      background-color: $dark_grey;
      color: white !important;
    }
  }
}
