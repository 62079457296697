@import '~styles/variables.scss';

.pa-StretchablePhotosRow__Container {
  display: flex;
  flex-flow: wrap;
  align-items: center;
  line-height: initial;
  width: 100%;
  max-height: 100%;

  > div {
    margin-right: 2px;
  }

  img {
    width: 28px;
    height: 28px;
    object-fit: cover;
  }
}

.pa-StretchablePhotosRow__NumberPhotoNotDisplayed {
  color: $blue;
  font-weight: $font-weight-mbold;
  margin-left: auto;
}
