@import '~styles/variables';

.d3-filter-pseudo-pill {
  line-height: 27px;
  margin: 0 5px 5px 0;
}

.d3-filter-pill {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  margin-right: 5px;
  text-align: left;

  &.d3-filter-pill_tooltip {
    display: flex;
    align-items: flex-start;
    padding: 5px 10px;

    + * {
      border-top: 1px solid $light_grey;
    }

    .d3-filter-pill-value {
      white-space: normal;
      font-weight: $font-weight-mbold;
    }
  }

  .d3-filter-pill-content {
    max-width: 160px;
    overflow: hidden;
    text-overflow: ellipsis;

    .d3-filter-tooltip & {
      max-width: none;
    }
  }

  .d3-filter-pill-value {
    > span:not(:last-child):after {
      content: ', ';
      display: inline;
    }
  }

  .d3-filter-pill-delete {
    flex: 0 0 auto;
    padding: 0 5px;
    margin: 1px -5px 0 5px;
    color: $dark_grey;
    cursor: pointer;
    font-size: 150%;
    line-height: 1;

    &:hover {
      color: $charcoal;
    }
  }
}

.d3-filter-pill-tooltip {
  > span {
    display: block;
    padding: 5px 10px;

    &:not(.last-child) {
      border-top: 1px solid $light_grey;
    }
  }
}

.d3-filter-pill_tooltipHeader {
  font-size: 14px;
}

.d3-graph-filter-pill {
  background: $light_grey;
  border: 1px solid #d7dadb;
  color: $charcoal;

  padding: 3px 10px;
  border-radius: 50px;
  font-size: 90%;

  .d3-filter-pill-value {
    max-width: 90px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.d3-filter-pills {
  display: flex;
  align-items: flex-start;
  flex: 1 1 auto;
  padding-top: 4px;

  .d3-filter-pills-title {
    margin-top: 3px;
  }

  .d3-filter-pills-list {
    > * {
      margin-bottom: 5px;

      &:not(:last-child) {
        margin-right: 5px;
      }
    }
  }

  .d3-filter-pills-children {
    flex-wrap: wrap;
    flex: 1 1 auto;
  }

  > * + * {
    margin-left: 5px;
  }
}
