@import './vendor/react-select.css';
@import './vendor/skeleton.css';
@import './vendor/sweetalert';
@import '~styles/variables.scss';

// Skeleton overrides

@media (min-width: 0) {
  .container {
    width: $pa-container--width_minimum;
  }
}

// React Bootstrap Panels
.panel {
  margin-bottom: $font-size-h3;
  border: 1px solid $grey;
  border-radius: $border-radius-standard $border-radius-standard 0px 0px;

  .panel-heading {
    background: $light_grey;
    //border-bottom: $border-standard;
    color: $text_color;
    text-align: center;
    font-weight: normal;
    margin: 0;
    padding: $spec-padding $spec-padding-small;
    text-transform: uppercase;
    border-radius: $border-radius-standard $border-radius-standard 0px 0px;

    h4 {
      margin-bottom: 0;
    }

    .panel-title {
      a {
        font-weight: normal;
      }
    }

    a {
      font-weight: normal;
      text-decoration: none;
      color: $grey;
      letter-spacing: normal;
    }
  }

  .panel-body {
    background: $white;
    padding: $spec-padding-large $spec-padding;
  }
}

.panel-group {
  .panel {
    margin-bottom: 3px;
  }
}

.wi-Panel--Flush .panel-body {
  padding: 0;
}

// Required for instances where we have flush tables
.wi-PanelPadding {
  padding: 0 $spec-padding $font-size-h2;
}

.fade {
  opacity: 0;
  transition: opacity 0.15s linear;
  &.in {
    opacity: 1;
  }
}

.collapse {
  display: none;

  &.in {
    display: block;
  }
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition-property: height, visibility;
  transition-duration: 0.08s;
  transition-timing-function: ease;
  border: none;
}

.Table-fill {
  background-color: $white;
  width: 100%;
  border-spacing: 0;

  th:first-child,
  td:first-child {
    // Undoing skeleton's trickery;
    padding-left: $spec-padding-small;
  }

  td {
    padding: $spec-padding-small;
  }
}

.Table-SubheaderRow {
  background: $light_grey;
  color: $text_color;
  font-size: $font-size-micro;
  font-weight: normal;
  height: 45px;
  margin: 0;
  text-transform: uppercase;

  td {
    padding: 1.3rem $spec-padding-small 0;
  }
}

// React Drop

.dropzone.active {
  background: $light_grey;
}

.JobDetailPanel .dropzone {
  background-color: $white;
}
.JobDetailPanel .dropzone.active {
  background-color: $light_grey;
}

// Font Awesome
.fa-pencil,
.fa-spinner {
  color: $charcoal;
}

.fa-check {
  color: $green;
}
.fa-times {
  color: $red;
}

.well {
  background-color: $white;
  border: $border-standard;
  border-radius: $border-radius-standard;
  margin-bottom: 20px;
  min-height: 20px;
  padding: 2rem;
}

input[disabled] {
  cursor: not-allowed;
  background-color: $grey;
  color: $charcoal;
}

.wi-ModalLabel {
  color: $grey;
  font-size: $font-size-small;
  font-weight: $font-weight-regular;
}

.wi-FormGroup.u-hidden {
  display: none !important;
}

// React Select Disabled State
.Select.is-disabled .Select-placeholder,
.Select.is-disabled .Select-arrow-zone,
.Select.is-disabled .Select-arrow {
  cursor: not-allowed;
  background-color: $light_grey;
}

.panel {
  margin-bottom: 18px;
  border: 1px solid #d2d5d6;
  border-radius: 0.4rem 0.4rem 0px 0px;

  .panel-heading {
    background: #eff1f0;
    color: #000b0f;
    text-align: center;
    font-weight: normal;
    margin: 0;
    padding: 12px 11px;
    text-transform: uppercase;
    border-radius: 0.4rem 0.4rem 0px 0px;
  }

  .panel-body {
    background: #ffffff;
    padding: 14px 12px;
    &.flush {
      padding: 0;
    }
  }
}

.Toastify__toast {
  border-radius: 4px;
  padding: 8px;
  min-height: 32px;
}
.Toastify__toast-container {
  position: absolute;
}
