// Set new names using this tool https://colornamer.robertcooper.me/
$light_blue: #19baf2;
$blue: #0086bf;
$dark_blue: #003d57;
$windsurfing_blue: #366f95;
$red: #db2c33;
$dark_red: #a70209;
$light_red: #feefef;
$green: #38a91e;
$dark_green: #017b08;
$light_orange: #fff5e7;
$orange: #fa7c10;
$dark_orange: #e96926;
$teal: #0da890;
$white: #ffffff;
$light_grey: #f5f5f5;
$grey: #d6d6d6;
$dark_grey: #999999;
$charcoal: #545454;
$black: #010101;
$brown: #945502;
$pink: #ffefef;

$chamois: #eed5b2;
$twilight_blue: #f8fdff;
$lochmara: #0086bf;

$cosmos: #ffefef; // pinkish
$gossip: #d5f9cd; // pale green
$light_pattens_blue: #edfaff;

// new parsable styles
$parsable-grey-900: #010101;
$parsable-grey-800: #545454;
$parsable-grey-400: #595b62;
$parsable-grey-300: #d6d6d6;
$parsable-white: #ffffff;
$parsable-grey-200: #efefef;
$parsable-green-100: #dfffcf;
$parsable-green-800: #1a6808;

// color uses

$highlight_table_row: $light_pattens_blue;
$highlight_select: $light_pattens_blue;
$highlight_red: $cosmos;
$highlight_green: $gossip;
$highlight_grey: $parsable-grey-200;

// icon colors
$iconColors: $blue, $dark_blue, $light_blue, $red, $dark_red, $green, $dark_green, $orange,
  $dark_orange, $white, $light_grey, $grey, $dark_grey, $charcoal, $black;
$iconColorNames: blue, dark_blue, light_blue, red, dark_red, green, dark_green, orange, dark_orange,
  white, light_grey, grey, dark_grey, charcoal, black;

@for $i from 1 through length($iconColors) {
  %wi-Icon--#{'' + nth($iconColorNames,$i)} {
    path,
    rect,
    polygon {
      fill: nth($iconColors, $i) !important;
    }
    color: nth($iconColors, $i) !important;
  }
}
