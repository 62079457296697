@import '~styles/utilities';
@import '~styles/variables';

.pa-JobList-MenuItems {
  flex: 1;
}

.pa-SubNav .Button-pill--transparent span {
  padding-left: 0px;
  padding-right: 0px;
}
