@import '~styles/variables';

.wi-Dropdown {
  height: 40px;
  margin-right: -20px;
  display: flex;
  align-items: center;

  > a {
    display: flex;
    align-items: center;
  }
}

.wi-Dropdown-Wrapped {
  position: absolute;

  // NOTE: These magic numbers reflect the specific use case of the header and
  // button in which they are first being used (WebEx). We can return to these
  // when we next use the Dropdown
  top: 35px;
  right: 0;
  z-index: 9999;
}

.wi-Dropdown-Internal {
  background-color: $white;
  border: 1px solid $grey;
  font-size: $font-size-small;
  line-height: $line-height-base;
  border-radius: 3px;
  overflow: auto;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.06);

  ul {
    margin-bottom: 0;
  }

  li {
    border-bottom: $border-standard;
    margin-bottom: 0;
    align-items: stretch;
    display: flex;
    flex-direction: column;
    height: 38px;

    &:last-child {
      border-bottom: 0;
    }
  }

  .wi-Dropdown-Item {
    white-space: nowrap;
    text-align: left;
    display: flex;
    color: inherit;
    padding: 5px 12px;
    height: 37px;
    line-height: 28px;
    cursor: pointer;

    a {
      color: $charcoal;
    }

    &:hover {
      background-color: var(--secondaryA_100, $highlight_table_row);
    }

    &[disabled] {
      color: $dark_grey;

      &:hover {
        cursor: default;
        background-color: $white;
        color: $dark_grey;
      }
    }
  }

  .wi-Dropdown-Separator {
    height: 1px;
    background-color: $grey;
  }
}

.wi-Dropdown ul {
  list-style: none;
  margin-bottom: 0;
}
