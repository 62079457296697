@import '~styles/variables';
@import '~styles/utilities';

.pa-EditorTasks-Modal .well {
  overflow: visible !important;
  .pa-StepGroupTitle-RefField {
    padding: 7px 5px;
    margin-bottom: 10px;
  }
}
