@import '~styles/variables';
@import '~styles/utilities';

$role-margin-space: 6px;
$breakpoint-tablet: 768px;

@media (max-width: $breakpoint-tablet) {
  .wi-RoleDropdown ul {
    max-height: 100px;
    overflow: 'auto';
    overflow-x: hidden;
  }
}

.wi-RoleDetails {
  .wi-RoleDetails-categories {
    @include flexbox(column, flex-start, flex-start);
    flex: 1 1 auto;
    margin: 1%;
    width: 98%;
  }

  .wi-RoleDetails-category-container {
    @include flexbox(column, flex-start, flex-start);
    flex: 1 1 auto;
    width: 100%;
  }

  .wi-RoleDetails-cateogry-title-container {
    @include flexbox(row, flex-start, center);
    margin-top: $role-margin-space;
    text-transform: uppercase;
    width: 100%;
  }

  .wi-RoleDetails-category-title {
    color: $charcoal;
    font-size: $font-size-base;
    font-weight: $font-weight-mbold;
  }

  .wi-RoleDetails-fields {
    @include flexbox(column, space-between, flex-start);
    margin: 0 0 $role-margin-space;
  }

  .wi-RoleDetails-field {
    @include flexbox(row, flex-start, center);
    margin: 2px 4px;
  }

  .wi-RoleDetails-field-title {
    text-align: left;
    color: $charcoal;
    font-size: $font-size-base;
  }

  .wi-RoleDetails-field-toggle {
    @include flexbox(row, flex-end, center);

    input,
    .checkbox > label {
      margin: 0;
    }
  }

  .wi-RoleDetails-moreInfo {
    margin-left: 8px;
  }
}
