@import '../TemplateDiffReportUtils';

.wi-Diff-CategoryTextWrapper {
  @include flexbox(row);
  display: inline-block;
  float: right;
  background-color: $white;
  -webkit-print-color-adjust: exact;
  border-radius: 20px;
  margin: 5px;
  top: 2px;
  border: 3px black solid;
  color: black;
  font-size: 10px;
  padding: 0 8px;
  font-weight: 700;
  letter-spacing: 1.5px;
}

.wi-Diff-CategoryText {
  @include diffText();
  display: inline;
}
