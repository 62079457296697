@import '~styles/variables';
@import '~styles/utilities';

.wi-HelperText {
  display: inline-block;
  vertical-align: middle;
  margin-left: 8px;
  margin-top: -2px;
}

.wi-HelperText--content {
  display: block;
  width: 400px;
  white-space: normal;

  p {
    margin-bottom: 1.3rem;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}
