@import '~styles/variables.scss';
@import '~styles/utilities.scss';
.wi-NoticeDescription {
  position: relative;

  &.wi-NoticeDescription-Focus {
    .wi-Notice-EditablePanel,
    .wi-Notice-IconContainer {
      border-color: $blue;
    }
  }

  input.wi-DocumentDescription-Input {
    display: inline;
    border: 0;
    outline: 0;
    padding: 0;
    height: auto;
    width: 5px;
    font-size: 18px;
    position: absolute;
    right: -9px;
    bottom: 2px;
    margin-bottom: 0;

    &:focus {
      border: 0;
    }
  }

  .wi-NoticeDescription-Display {
    display: none;
  }

  //NOT EDITIBLE IN STEP
  &:not(.wi-NoticeDescription-EditingStep) {
    div.wi-NoticeDescription-Display div.wi-NoticeDescription-Controls {
      visibility: hidden !important;
    }

    input[type='text'].wi-NoticeDescription-ControlInput {
      display: none;
    }
  }
}
