@import '~styles/variables.scss';

.pa-FormulaDisplay {
  display: flex;
  color: $charcoal;
  flex-direction: column;

  .pa-FormulaDisplay-header {
    font-size: 12px;
    font-weight: 600;
    margin-left: 3px;
  }
  .pa-FormulaDisplay-content {
    display: flex;
    flex-wrap: wrap;
  }
  .pa-FormulaDisplay-TokenDisplay {
    display: inline-flex;
    margin: 3px;
    line-height: 1.4;
    font-size: 12px;
    font-weight: 400;

    i {
      padding: 8px 4px;
      border-radius: 100px;
      background-color: $light_grey;
      color: $charcoal;
      font-weight: 600;
      font-style: normal;
      font-size: 16px;
      line-height: 0px;
    }
  }
}
