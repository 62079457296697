@import '~styles/variables';

.blank-slate {
  text-align: center;
  color: $dark_grey;
}

.blank-slate__title {
  margin-top: 5px;
}
