@import '~styles/variables';
@import '~styles/utilities';

#CreateIntegrationModal {
  text-align: center;
  button,
  input {
    width: 100%;
  }
}
