.pa-PageLoader-Wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .text {
    padding: 10px;
  }
}
