@import '~styles/variables';
@import '~styles/utilities';

%pa-RefField-Single {
  padding: 3px 10px;
  border: 1px solid $grey;
  border-radius: 4px;
  box-shadow: none;
  box-sizing: border-box;
  color: $black;
  @extend %type-text-regular;

  &.disabled {
    border: none;
  }

  &.active {
    border: 1px solid $light_blue;
  }
}

// Common padding and max-hight for containers that live in the Well.
// Inner containers should extend this and opt-in to scrolling if they need it
%pa-WebEx--WellInner {
  padding: $padding-large-vertical $padding-large-horizontal;
  max-height: 100%;
}

// Used for description typography in flat mode ( reports, diffs, flat template view )
%pa-Markdown--FlatDescription {
  h1 {
    font-size: 21px;
    line-height: 25px;
    margin-top: 8px;
    margin-bottom: 4px;
  }

  h2 {
    font-size: 18px;
    line-height: 24px;
    margin-top: 4px;
    margin-bottom: 4px;
  }

  p {
    font-size: 16px;
    margin-bottom: 0px;
    padding-bottom: 6px;
    line-height: 24px;
  }

  li {
    margin-bottom: 0px;
  }

  ul,
  ol,
  ul ul,
  ul ol,
  ol ul {
    margin-left: 8px;
    font-size: 100%;
    margin-bottom: 0px;
    margin-top: 0px;
  }

  ul {
    li {
      list-style-type: disc;
      list-style-position: outside;
      margin-left: 14px;
      padding-left: 8px;
    }

    li li {
      list-style-type: circle;
    }

    li li li {
      list-style-type: square;
    }
  }

  ol {
    li {
      list-style-type: decimal;
      list-style-position: outside;
      margin-left: 12px;
      padding-left: 10px;

      li {
        list-style-type: lower-alpha;
        li {
          list-style-type: lower-roman;
          li {
            list-style-type: decimal;
            li {
              list-style-type: lower-alpha;
            }
          }
        }
      }
    }
  }
}
