@import '~styles/variables';
@import '~styles/utilities';

// Controls positioned absolutely until we can move
// these into the PageHeader component
.wi-PrintReport-Controls {
  position: absolute;
  bottom: 15px;
  right: 20px;
  z-index: 200;
  display: flex;
  button {
    margin-left: 10px;
    margin-right: 5px;
  }

  .wi-Deviation-none,
  .wi-Deviation-some {
    font-size: 14px;
    margin-right: 10px;
    display: flex;
    align-items: center;

    .wi-Icon {
      @extend %wi-Icon--red;
      margin-right: 6px;
      width: 16px;

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  .wi-Deviation-none {
    color: $green;
  }

  .wi-Deviation-some {
    color: $red;
  }
}
