@import '~styles/variables.scss';
@import '~styles/utilities.scss';
.wi-TaskGroup {
  &:hover {
    .wi-TaskGroup--ChildLink .wi-TaskGroup-HoverIcon {
      .wi-TaskGroup-Arrow {
        display: none;
      }

      .wi-TaskGroup-Edit {
        display: flex;
      }
    }
  }

  &.wi-TaskGroup-ReadOnly:hover {
    .wi-TaskGroup--ChildLink .wi-TaskGroup-HoverIcon {
      .wi-TaskGroup-Arrow {
        display: flex;
      }

      .wi-TaskGroup-Edit {
        display: none;
      }
    }
  }

  &.wi-TaskGroup-Editing {
    border: 1px solid $grey;
  }

  .wi-TaskGroup--Title {
    flex-grow: 1;
    flex-basis: 100%;
  }

  .wi-TaskGroup--ChildLink {
    text-transform: capitalize;
    color: $text_color;
    margin-right: 1.125rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    -ms-flex-pack: start; /* fix display in IE10 */

    &:hover {
      cursor: pointer;
    }

    .wi-TaskGroup-TextAlign {
      position: relative;
      top: 1px;
    }

    .wi-TaskGroup-HoverIcon {
      width: 20px;
      margin-left: 8px;
      .wi-TaskGroup-Arrow {
        display: block;
      }

      .wi-TaskGroup-Edit {
        display: none;
      }
    }

    .wi-TaskGroup-Arrow {
      font-size: 1.875rem;
      margin-left: 0.4rem;
    }
  }

  .wi-TaskGroup--Title input {
    background-color: transparent;
    font-size: 1.25rem;
    margin-bottom: 0;
    border-color: transparent;
    text-overflow: ellipsis;
    width: 100%;
    color: $text_color;
  }

  .wi-TaskGroup--Title input:focus {
    border-color: $grey;
    background-color: $white;
  }

  .wi-TaskGroup--Title input:hover {
    border-color: $dark_grey;
  }

  .wi-TaskGroup--Controls {
    display: flex;
    align-items: center;
    flex: 0 0 auto;

    .Button {
      margin-right: 5px;
    }

    .wi-Icon {
      margin: 0 5px;
    }
  }
}
