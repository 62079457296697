@import '~styles/variables';
@import '~styles/utilities';

.par-ThreadsPanelHeader {
  @include flexbox(row, space-between, center);
  padding: 5px 10px;
  flex-shrink: 0;

  .par-ThreadsPanelHeader--SearchBox {
    flex: 1;
    margin-right: 10px;
    input {
      margin: 10px 5px 10px 0px !important;
    }
  }
}

.par-ThreadsPanelDivider {
  @include flexbox(row, flex-start, center);
  background-color: $light_grey;
  text-transform: uppercase;
  font-size: 12px;
  color: $charcoal;
  padding: 8px 10px;
  height: 25px;
  border-bottom: solid 1px $grey;
  .wi-Icon {
    margin: 0px 8px;
  }
}

.par-ThreadsPanel {
  @include flexbox(column, flex-start, stretch);
  height: 100%;
}

.par-ThreadsPanelOpenContainer,
.par-ThreadsPanelCloseContainer {
  flex: 1 1 100%;
  overflow-y: scroll;
  min-height: 70px;
}
