@import '~styles/variables';

.d3-legend {
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 12px;
  color: $black;
}

.d3-legend-item {
  margin: 0 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.d3-legend-item_hidden {
    color: $dark_grey;

    .d3-legend-item-color {
      opacity: 0.75;
    }
  }
}

.d3-legend-item-color {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  margin-top: -1px;
  margin-right: 4px;
  display: inline-block;
  flex-shrink: 0;
  border: 2px solid transparent;
  background-color: $white;
}

.d3-legend-item-title {
}
