@import '../../TemplateDiffReportUtils';

.wi-Diff-DescriptionList {
  margin-top: 5px;
  margin-left: $template-left-indent;

  border-left: 3px solid $grey;

  & > div {
    margin-left: 30px;
  }
}

.wi-Diff-DescriptionList > div {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.wi-Diff-DescriptionListWrapper {
  margin-bottom: 10px;
}

.wi-Diff-DescriptionListWrapper,
.wi-Diff-DescriptionList {
  @include flexbox(column, flex-start, stretch);
}

.wi-Diff-DescriptionList-TitleWrapper,
.wi-Diff-Description-LinkWrapper,
.wi-Diff-Description-LinkText,
.wi-Diff-Description-Doc {
  @include flexbox(row, flex-start);
}

.wi-Diff-Description-LinkWrapper {
  @include diffImg();
}

.wi-Diff-Description-LinkText {
  margin-left: 10px;
}

.wi-Diff-DescriptionList-TitleText {
  @include diffFont();
}

.wi-Diff-Description-TextWrapper {
  @include diffText();
}

.wi-Diff-DescriptionList-TitleText,
.wi-Diff-Description-TextWrapper,
.wi-Diff-Description-TextText,
.wi-Diff-Description-LinkIcon {
  @include flexbox();
}

.wi-Diff-Description-Doc .wi-Document-content-wrapper {
  padding: 10px 0;
}

.wi-Diff-Description-LinkWrapper {
  border: $border-standard;
  padding: 5px;
}

.wi-Diff-Description-Doc {
  .wi-Document-Video,
  .wi-ExifImage-Container,
  .wi-Document-ThumbnailContainer {
    @include flexbox();
  }

  &.wi-Diff-ImageRemoved {
    @include diffImgRemoved();

    .wi-ExifImage-Container,
    .wi-Document-border-wrapper,
    video {
      @include diffImgRemoved();
    }

    .wi-Document-Border {
      padding: 0 2px;
    }
    .wi-Document-title {
      @include removedText();
    }
  }

  &.wi-Diff-ImageAdded {
    @include diffImgAdded();

    .wi-ExifImage-Container,
    .wi-Document-border-wrapper,
    video {
      @include diffImgAdded();
    }

    .wi-Document-Border {
      padding: 0 2px;
    }
  }

  img,
  video {
    max-width: 550px;
    height: 100%;
    width: 100%;
  }
}

.wi-Diff-Description-TextText {
  line-height: $line-height-small;
}

.wi-Diff-StepFieldDescriptions {
  @include diffText();
  border-left: 3px solid $grey;

  .pa-Rev-AnnotatorWrapper {
    margin-left: 30px;
  }
}
