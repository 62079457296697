@import '~styles/variables';
@import '~styles/utilities.scss';

#TemplateEditor #EditorDocumentsPanel {
  background-color: $dark_grey;
  position: relative;

  a {
    text-decoration: none;
    color: $charcoal;
  }

  .dropzone.active a {
    color: $blue;
  }

  .wi-EditorDocumentsPanel--Upload {
    padding: 0;
    cursor: pointer;
  }
  .EditorDocumentsPanel--DocumentList {
    .Table-fill {
      background-color: inherit;
      table-layout: fixed;
      min-width: 160px;
    }

    tr td {
      border-bottom: 0;
    }
  }
  .panel-header {
    display: none;
  }
  .dropzone {
    margin-top: 10px;
    max-width: 200px;
    margin-left: auto;
    margin-right: auto;
  }

  .Document {
    img,
    video {
      max-height: 40px;
      max-width: 40px;
      padding: 4px;
      margin: 0;
    }
  }
}
