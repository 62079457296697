@import '~styles/variables';
@import '~styles/utilities';

.wi-EditorDocumentContainer {
  display: flex;
  justify-content: center;
  padding: 5px;
  .wi-Document-Uploading {
    text-align: center;
  }
}

.wi-Document-Deleting {
  display: flex;
  justify-content: space-between;

  &--Icon {
    transition: opacity 150ms ease-in-out;
  }
}
