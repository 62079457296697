@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-StepFieldValidation-NumberSelection,
.wi-StepFieldValidation-DateTimeSelection {
  margin-top: 8px;
}
.wi-StepFieldValidation-DateTimeSelection-Timezone {
  margin-bottom: 8px;
}
.wi-StepFieldValidation-NumberSelection-Between,
.wi-StepFieldValidation-DateTimeSelection-Between {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
  align-items: center;

  & > :first-child {
    width: 100%;
    display: inline-block;
    margin-right: 10px;
  }
  & > :last-child {
    width: 100%;
    display: inline-block;
    margin-left: 10px;
  }
}
.wi-InputNumber-Input-Any.pa-RefField-Single {
  margin-top: 5px;
}
.wi-StepFieldValidation-Criteria {
  .wi-StepField-Add {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.wi-StepFieldValidation-IfCondition,
.wi-StepFieldValidation-ThenCondition {
  margin-bottom: 10px;
}
.wi-StepFieldValidation-Then-Condition {
  margin-top: 8px;
}
.wi-StepFieldValidation-ExactMatchesReferences {
  display: flex;
  justify-items: center;
  align-content: center;
  width: 100%;
}
