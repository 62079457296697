.wi-CompleteJobModal {
  .verticalTop {
    align-self: flex-start;
  }

  textarea {
    width: 100%;
    min-height: 100px;
  }
}
