@import '~styles/variables';
@import '~styles/utilities';

.wi-StandardInteractions {
  margin-bottom: $spec-margin;

  .wi-FilterStatus {
    padding: 4px;
    font-size: 13px;
    padding-left: 0.92857rem;
    background-color: $white;
    color: $black;
    max-width: 423px;

    .wi-FilterStatus-header {
      display: flex;
      justify-content: space-between;
      background-color: $light_grey;
      padding: 2px 4px;
    }

    .wi-FilterStatus-content {
      display: flex;
      flex-direction: column;
      max-height: 200px; // XXX 200 for real
      overflow: auto;
      .wi-FilterElement {
        margin-left: 10px;

        .wi-FilterElement-label {
          margin-right: 4px;
        }
        .wi-FilterElement-value {
          font-weight: 800;
        }
      }
    }

    .wi-ClearFilter {
      margin-left: 10px;
      color: var(--secondaryA, $blue);
    }
  }
}

.wi-StandardInteraction-HelperText,
.wi-StandardInteractions-Query {
  flex-grow: 1;
}
.wi-StandardInteraction-HelperText {
  align-items: center;
}
.wi-StandardInteractions-Buttons {
  display: flex;
  justify-content: flex-end;
}

.wi-StandardInteractions--Top {
  @include flexbox(row, auto, center);
  margin-bottom: $spec-margin-small;

  .wi-SearchBarContainer {
    margin-right: 10px;
  }

  .wi-StandardInteractions-Query {
    @include flexbox(row, flex-start, center);
  }

  input.wi-SearchBar {
    margin-bottom: 0;
    min-width: 280px;
  }
}

.wi-StandardInteractions--Text {
  // TODO: This is a bit hacky. We're trying to ensure that the text without a
  // button shows the same height as the text with a button so that if you
  // navigate between tabs the page doesn't jump. This part will likely change.
  line-height: 2.375rem;
  color: $light-grey;
}
.wi-StandardInteractions--Header {
  font-size: $font-size-h3;
  font-weight: bold;
}
