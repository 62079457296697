@import '../TemplateDiffReportUtils';

.wi-Diff-Template-DescriptionWrapper {
  margin-top: 5px;
  display: inline-block;
}

.wi-Diff-Template-DescriptionText {
  @include diffText();
  display: inline;
  line-height: $font-size-base + 2px;
}
