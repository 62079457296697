@import '~styles/variables';

.wi-TableActionMenu {
  display: inline-block;

  .wi-TableActionMenu-Label {
    position: relative;
    display: inline-flex;
    justify-items: center;
    height: 100%;
    padding: 5px 15px;
    color: var(--secondaryA, $blue);
    font-weight: $font-weight-semibold;
    cursor: pointer;

    &::after {
      content: '';
      position: absolute;
      right: 5px;
      top: 47%;
      margin-top: -2.5px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid var(--secondaryA, $blue);
      clear: both;
    }
  }
}

.wi-TableActionMenu-Popover {
  .wi-PopoverMenuItem {
    &:hover {
      background-color: var(--secondaryA_100, #bfecff);
    }
  }
}
