@import '~styles/colors.scss';

.pa-SectionTitle {
  margin-top: 0;
  font-size: 15px;
  padding-bottom: 5px;
  margin-bottom: 20px;
  border-bottom: 1px solid $light_grey;
  font-weight: 600;
}
