@import '~styles/variables';
@import '~styles/utilities';

@media screen {
  .print-only {
    display: none !important;
  }
}

$max-width-author: 300px;
$min-width-author: 160px;
#wi-Rev {
  height: 100%;

  .wi-Rev-added-text {
    color: $green !important;
  }

  .wi-Rev-removed-text {
    color: $red !important;
    text-decoration: line-through;
  }

  .wi-JobReportSteps-Container {
    min-height: calc(100% - 5px);
    background-color: #fff;
    padding-bottom: 20px;
    border: 1px solid grey;
    margin: auto 50px 5px 30px;
  }
}

#wi-Rev {
  @include flexbox(column, flex-start, stretch);
  height: calc(100vh - #{$header_height});
  padding-bottom: 10px;
}

.wi-Rev-MainContainer {
  @include flexbox(row, center, stretch);
  overflow: auto;
  height: 100%;
}

.wi-Rev-MainWrapper {
  @media screen {
    @include flexbox(row, space-between, stretch);
  }
  @media print {
    @include flexbox(column, space-between, stretch);
  }
  flex-basis: 75%;
  height: 100%;
  max-width: $max-width-diffs;
  min-width: $min-width-diffs;
}

.wi-Rev-Main {
  position: relative;
  overflow: auto;
  max-width: $max-width-diffs - $max-width-author;
  min-width: $min-width-diffs - $max-width-author;

  @media print {
    margin-left: 0;
    flex-basis: 100%;
  }
  @media screen {
    // this is to account for the stickies making the whole thing 45 px left in .wi-Rev-TemplateWrapper
    margin-left: -45px;
    flex-basis: 80%;
    padding-bottom: 50px;
  }
}

.wi-Rev-Right {
  @media (max-width: 920px) {
    margin-right: -40px;
  }
  max-width: $max-width-author;
  min-width: $min-width-author;
  flex: 1 1 auto;
  margin-top: 10px;
  margin-left: 10px;
}

@media print {
  .wi-Rev-AuthorListWrapper {
    @include flexbox(row, flex-start, flex-start);
    flex-wrap: nowrap;
    align-items: center;
  }
  .wi-Rev-AuthorListText {
    margin-top: 5px;
    font-weight: 500;
    font-size: 18px;
  }
}

.wi-Rev-AuthorList {
  @include flexbox(row, flex-start);
  flex-wrap: wrap;
  margin-left: $template-left-indent;
}

.wi-downloadable {
  cursor: pointer;
}

.wi-Rev-FilterAuthor,
.wi-Rev-AuthorWrapper {
  @include flexbox(row, flex-start, center);
  margin-top: 5px;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
}

.wi-Rev-AuthorCheck {
  @include flexbox();
}

.wi-Rev-AuthorWrapper:not(:last-child)::after {
  content: ',';
}

.wi-Rev-AuthorName,
.wi-Rev-FilterAuthor {
  margin-left: 5px;
}

@media screen {
  .wi-Rev-AuthorListText {
    font-weight: 500;
    font-size: 18px;
    border-bottom: $border-standard;
    padding-bottom: 5px;
  }
}

$removed-red: $red;
$added-green: $green;
.wi-Rev-RemovedDocName {
  background-color: $removed-red;
  text-decoration: line-through;
}

.wi-Rev-AddedDocName {
  background-color: $added-green;
}

.wi-Rev-RemovedDocSubtext,
.wi-Rev-AddedDocSubtext {
  @include diffSubtext();
}

@media screen and (min-width: 1200px) {
  #wi-Rev {
    .wi-Rev-Left {
      min-width: 180px;
    }
  }
}

.wi-Rev-Print-VersionWrapper {
  display: block;
  margin: 10px 0;
  font-size: $font-size-base;
  .wi-Rev-ValueRenderContainer {
    display: inline;
    padding-left: 0;
  }
}
