@import '~styles/variables';
@import '~styles/colors';
@import '~styles/utilities.scss';
@import '~styles/typography';

// remove border left from the wi-LogoutMenu .wi-Dropdown a and add it to notifications anchor.
.pa-Notifications + #wi-LogoutMenu {
  .wi-Dropdown {
    a {
      border-left: none;
    }
  }
}

// Visibility is passed as Prop. Hidden by default.
.pa-Notifications.pa-Notfications--is_visible {
  visibility: visible;
}

.pa-Notifications {
  display: flex;
  flex-direction: column;
  position: absolute;
  min-width: 400px;
  max-width: 500px;
  z-index: 100;
  visibility: hidden;
  background-color: $white;
  border: 1px solid $grey;
  box-shadow: 1px 0px 2px rgba(0, 0, 0, 0.05);
  @include animated(150ms);

  // Positioning of panel is handled by JS inline.
  ul {
    color: $charcoal;
    margin: 0;
    li {
      @include flexbox(column, null, null);
    }
  }

  li {
    border-bottom: 1px solid $grey;
    margin-bottom: 0;
    text-align: right;
    a {
      align-self: flex-end;
      color: $blue;
      font-weight: 600;
      padding: 5px;
      &:hover {
        color: $highlight_grey;
        padding: 5px;
      }
    }
  }

  li.no-unread-notifications {
    height: 500px;
    @include flexbox(column, center, center);
    cursor: default;
    span {
      path {
        stroke: $light_grey;
      }
    }
    p {
      height: 19px;
      width: 266.41px;
      font-family: $font-family;
      font-size: 16px;
      font-weight: 500;
      line-height: 45px;
      text-align: center;
    }
  }
}

.pa-Notifications-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
.pa-Notifications-title {
  display: block;
  color: $charcoal;
  font-size: 20px;
  font-weight: 500;
}

ul.pa-Notifications-tabs {
  display: flex;
  flex-grow: 1;
  align-items: center;
  padding-top: 5px;
  > li {
    flex-grow: 1;
    flex-shrink: 0;
    display: flex;
    &:hover {
      cursor: default;
    }
  }
  > li > a {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: $font-weight-semibold;
    font-size: 14px;
    color: $charcoal;
    text-transform: capitalize;
    &:hover {
      cursor: default;
      color: $charcoal;
    }
  }
  .pa-active {
    border-bottom: 2px solid var(--primaryA, $blue);
    & a,
    & a:hover {
      color: var(--primaryA, $blue);
    }
  }
}

.pa-Notifications-list {
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $light_grey;
  // list item
  li {
    padding: 10px;
    cursor: pointer;
    &:last-child {
      border-bottom: 0;
    }
    &.item-read {
      background-color: $light_grey;
    }
    &:hover {
      background-color: $highlight_grey;
    }
    // container for item
    div {
      @include flexbox(row, null, center);

      &.pa-firstRow {
        padding-bottom: 6px;
        font-size: 16px;
        color: $black;
        font-weight: 300;
        line-height: 22px;
        .event-title {
          font-weight: bold;
        }
        span {
          align-self: center;
        }
        span:nth-child(2) {
          text-align: left;
          padding-left: 5px;
          width: 65%;
          p {
            margin: 0;
            display: inline;
          }
          p:nth-child(2) {
            padding-left: 5px;
          }
        }
        span:last-child {
          flex: 1;
          a {
            padding-right: 0;
            padding-top: 0;
          }
        }
      }

      &.pa-secondRow {
        text-align: left;
        margin-bottom: 10px;
        color: $charcoal;
        line-height: 1.4;
      }

      &.pa-thirdRow {
        @include flexbox(row, space-between, null);
        flex-wrap: wrap;
        color: $dark_grey;
        font-size: 13px;

        span:first-child {
          text-align: left;
          width: 66%;
          p {
            margin: 0;
            display: inline;
          }
          p:nth-child(2) {
            padding-left: 5px;
          }
        }
        span:last-child {
          padding-right: 0;
        }
        .tag-name {
          color: $dark_grey;
          font-weight: normal;
        }
        .pa-danger {
          color: $red;
        }
        .pa-normal {
          color: $black;
          font-weight: bold;
        }
        .pa-priority-urgent {
          color: $dark_red;
        }
        .pa-priority-high {
          color: $red;
        }
        .pa-priority-medium {
          color: $dark_orange;
        }
        .pa-priority-low {
          color: $orange;
        }
      }
    }
  }
}

.pa-Notifications-icon {
  border-bottom: none;
  position: relative;
  padding: 0;
  line-height: normal;

  & p {
    text-align: center;
    width: 100%;
    margin: -5% 0 -5% 0;
    letter-spacing: 0;
  }
}

.pa-Notifications-alert {
  position: absolute;
  left: 11px;
  bottom: 15px;
  width: 15px;
  height: 15px;
  background: $red;
  border-radius: 50%;
  display: block;
  letter-spacing: normal;
  line-height: 1.3;
  font-size: 11px;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.pa-hide-scrollBar {
  overflow-y: hidden;
}
