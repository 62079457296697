@import '~styles/colors.scss';

// Typography
$font-family-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
$font-family: 'proxima-nova', 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif !important;
$font-size-base: 14px;
$font-size-medium: 16px;
$font-size-small: 12px;
$font-size-micro: 11px;
$font-size-large: 18px;
$font-size-h1: 22px;
$font-size-h2: 20px;
$font-size-h3: $font-size-large;
$font-size-h4: 16px;
// USE THESE FONT WEIGHTS
$font-weight-light: 300;
$font-weight-regular: 400;
$font-weight-semibold: 500;
$font-weight-mbold: 600;

// DEPRECATED!!!
$font-weight-heavy: 700;
$line-height-base: 1.333333;
$line-height-large: 1.333333 !default;
$line-height-small: 1.5 !default;
$line-height-micro: 1 !default;
$link-hover-decoration: none !default;
$letter-spacing-gentle: 0.1rem;
$letter-spacing-relaxed: 0.2rem;
$letter-spacing-wide: 0.35rem;

// typography guide: https://projects.invisionapp.com/d/main#/console/10706096/227349690/inspect

%type-extra-large-header {
  font-size: 22px;
  font-weight: $font-weight-mbold;
}

%type-header-title {
  font-size: 18px;
  font-weight: 500;
}

%type-header-medium,
%type-step-input-headers {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  color: $dark_grey;
}

%type-header-small {
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  color: $dark_grey;
}

%type-header-title-placeholder {
  font-size: 18px;
  font-style: italic;
  color: $dark_grey;
}

%type-text-medium {
  font-size: 14px;
  font-weight: 500;
}

%type-text-medium--bold {
  font-size: 14px;
  font-weight: $font-weight-mbold;
}

%type-text-large--xtra-bold {
  font-size: 15px;
  font-weight: 800;
}

%type-text-regular {
  font-size: 14px;
  font-weight: 400;
  color: $charcoal;
}

%type-nav {
  font-size: 14px;
  text-transform: uppercase;
}

%type-nav-selected {
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
