@import '~styles/variables';
@import '~styles/utilities';

.wi-DocumentList {
  flex: 1 1 auto;
  @include flexbox(column, flex-start, stretch);

  .wi-Lightbox {
    flex: 0 0 auto;
    border: 1px solid $grey;
    min-width: 50px;
    min-height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Table-fill {
    table-layout: fixed;
    min-width: 160px;
  }

  .wi-DocumentList-drag-preview {
    padding: 11px;
  }
}

.wi-DocumentList-emptyText {
  font-size: $font-size-large;
  color: $text_color;
  text-align: center;
}
