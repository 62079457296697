@import '~styles/variables';
@import '~styles/utilities';

.Header {
  z-index: 100;
  background-color: var(--primaryB, $dark_blue);

  .wi-PrintReport-Controls {
    @include flexbox(row, flex-end);
  }
}
.Header-Banner {
  background-color: $dark_blue;
  color: $white;
  height: $header_height;
}
.Header-PrintControl {
  height: 50px;
  @include flexbox(row, space-between, center);
}
.Header--Items {
  @include flexbox();
  height: $header_height;
}

.Header--TeamName {
  color: $white;
  @extend %type-header-medium;
}

.Header--Links {
  display: flex;

  .pa-NavLink,
  .pa-NavLink-icon {
    padding: 0;
    flex-grow: 0;
    margin-top: 1px;
    &:hover {
      background-color: transparent;
    }
  }
  .pa-NavLink .wi-Icon svg {
    path,
    rect,
    polygon {
      fill: white !important;
    }
  }
  .pa-NavLink:hover {
    .wi-Icon svg {
      path,
      rect,
      polygon {
        fill: $light_blue !important;
      }
    }
    .pa-Notifications-alert {
      color: $light_blue;
    }
  }
}

a.Header--Link--active,
.pa-Menu-Dropdown.Header--Link--active {
  box-shadow: inset 0 -5px 0 var(--primaryC, $light_blue);
  color: var(--primaryC, $light_blue);
  font-weight: $font-weight-mbold;
}
