@import '~styles/variables';

.d3-header-wrapper {
  position: relative;
  z-index: 2;
}

.d3-chart-row {
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &.d3-chart-row_wrap {
    flex-wrap: wrap;
  }

  & .nowrap {
    margin-left: 30px;
    &:first-child {
      margin-left: 0;
    }
  }

  & .dateRange-picker {
    border-radius: 4px;
    height: 36px;
  }

  > *:not(:last-child) {
    margin-right: 10px;
  }
}

.d3-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .d3-graph_editing & {
    border-bottom: 1px solid #979797;
  }

  .d3-header-title {
    font-size: 18px;
    flex: 1 1 auto;
    font-weight: $font-weight-light;

    a {
      color: $black;

      &:hover {
        color: $blue;
      }
    }

    .d3-graph_editing & {
      font-size: 30px;
      color: $black;
    }

    .d3-graph:not(.d3-graph_editing) & {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .d3-header-icons {
    display: flex;
    margin-left: 10px;
    justify-content: flex-start;
    align-items: center;

    .d3-graph:not(.d3-graph_editing) & svg {
      width: 14px;
      height: 14px;
    }

    > * + * {
      margin-left: 10px;
    }
  }
}

.d3-subheader {
  margin-top: 20px;
  justify-content: space-between;
  align-items: flex-start;
}

.chart-dateRange {
  display: flex;
  align-items: center;

  .dateRange {
    margin-left: 5px;
  }
}

.d3-header-dateRange {
  padding: 4px 10px;
  display: inline-block;
}

.chart-dateRange-title {
  white-space: nowrap;
  margin-right: 5px;
}

.d3-dropdown {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;

  &.pa-dropdown_isOpen path {
    fill: $blue;
  }
}

.d3-filter-tooltip {
  max-width: 400px;
}

$carrot: 5px;

.pa-dropdown-body {
  margin-top: $carrot + 3;
  min-width: 100px;
  text-align: center;

  &:after,
  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    top: -$carrot * 2;
    right: 50%;
    margin-right: -5px;
    width: 0;
    height: 0;
    border: 5px solid transparent;
    border-bottom-color: #979797;
  }

  &:before {
    z-index: 2;
    margin-top: 1px;
    border-bottom-color: $white;
  }

  &.pa-dropdown-body_alignright {
    text-align: left;
    margin-right: -14px;

    &:after,
    &:before {
      right: 8px;
      margin-right: 0;
    }
  }

  .d3-dropdown-link {
    display: block;
    padding: 5px 10px;
    color: #5f6568;

    &:hover {
      color: $blue;
    }

    + .d3-dropdown-link {
      border-top: 1px solid $grey;
    }
  }
}

.d3-action {
  cursor: pointer;
}
