@import '~styles/variables';
@import '~styles/utilities';

.pa-AccountSettings-form {
  @include flexbox(column, space-between, stretch);

  .errors {
    color: $red;
    display: flex;
    flex-direction: column;
  }
}
