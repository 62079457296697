@import '~styles/variables';

.swal-modal {
  font-family: $font-family;
}

.swal-icon {
  transform: scale(0.8);
  &.swal-icon--warning {
    border-color: $orange !important;
    animation: none !important;
    * {
      animation: none !important;
    }

    .swal-icon--warning__body,
    .swal-icon--warning__dot {
      background-color: $orange !important;
    }
  }

  &.swal-icon--success {
    border-color: $green !important;
    animation: none !important;
    * {
      animation: none !important;
    }

    .swal-icon--success__ring {
      border-color: $green !important;
    }

    .swal-icon--success__line {
      background-color: $green !important;
    }
  }
}

.swal-title {
  padding-top: 0;
  word-wrap: break-word;
}

.swal-text {
  padding: 0 20px;
  word-wrap: break-word;
}

.swal-footer {
  margin-top: 30px;
  text-align: center;
  background-color: white;

  .swal-button {
    border-radius: 3px;
    padding: 0 24px;
    font-weight: $font-weight-semibold;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    min-height: 30px;

    &.swal-button--cancel {
      color: var(--secondaryA, $btn-default-color);
      background-color: $btn-default-bg !important;
      border: 1px solid var(--secondaryA, $btn-default-border);
      transition: all 0.4s;
      &:hover {
        color: var(--secondaryA_800, $btn-default-color-active);
        background-color: $btn-default-bg-active !important;
        border: 1px solid var(--secondaryA_800, $btn-default-border-active);
      }
    }

    &.swal-button--confirm {
      color: var(--secondaryC, $white);
      background-color: var(--secondaryA, $blue) !important;
      transition: all 0.4s;
      &:hover {
        background-color: var(--secondaryA_800, $primary-blue-hover) !important;
      }

      &.pa-SweetAlert--destructive {
        background: $red !important;

        &:hover {
          background: $dark_red !important;
        }
      }

      &.pa-SweetAlert--send-for-review {
        background: #7ed321;
      }

      &.pa-SweetAlert--warning {
        background: #f5a623;
      }
    }
  }

  .swal-button--loading ~ .swal-button__loader {
    opacity: 0;
  }
}
