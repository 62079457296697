@import '~styles/utilities';
@import '~styles/variables';

.pa-FilterManagement {
  margin-bottom: 60px;

  .pa-FilterManagement-FormHeader {
    margin: 21px 0px;
    color: $charcoal;
  }
  .pa-FilterManagement-Inactive {
    opacity: 0.5;
    pointer-events: none;
  }
  .pa-FilterManagement-Invisible {
    opacity: 0;
    cursor: move;
  }
  .pa-FilterManagement-Current {
    pointer-events: none;
    opacity: 1;
    font-weight: $font-weight-mbold;
    color: $dark_blue;
    border-bottom: none !important;
    padding-left: 0px !important;
  }

  .pa-FilterManagement-NewFilter {
    margin: 16px 0px;
    padding: 0px;
  }

  .pa-FilterManagement-NoFilters {
    font-style: italic;
    border-bottom: solid 1px $grey;
    padding: 16px 0px;
  }

  .pa-FilterManagementRow {
    @include flexbox(row, flex-start, baseline);
    position: relative;
    border-bottom: solid 1px $grey;
    padding: 16px 0px 16px 25px;
    transition: all 0.5s;
    cursor: move;

    .title {
      flex: 1;
    }

    &:hover {
      .dragHandle {
        opacity: 1;
        left: 0px;
      }
    }

    .dragHandle {
      opacity: 0;
      position: absolute;
      left: -15px;
      top: 20px;
      transition: all 0.2s;
    }

    .controls {
      .wi-Icon {
        width: 17px;
        height: 17px;
        margin-left: 22px;
      }
    }
  }
}
