@import '~styles/variables';
@import '~styles/utilities';

.pa-TeamIdentity {
  display: flex;
  align-items: start;
  padding-left: $pa-TeamIdentity--padding_horizontal;
  padding-right: $pa-TeamIdentity--padding_horizontal;
  padding-top: $pa-TeamIdentity--padding_vertical - 4;
  padding-bottom: $pa-TeamIdentity--padding_vertical;
  height: 72px;
  overflow: hidden;
}
.pa-TeamIdentity-logo {
  min-width: $pa-TeamIdentity--logo_width;
  width: $pa-TeamIdentity--logo_width;
  height: 100%;
  display: flex;
  align-items: center;
  > img {
    max-width: 100%;
    flex-shrink: 0; // to avoid img stretch on IE.
  }
}
.pa-TeamIdentity-name {
  padding-top: 4px; //centers the name with the logo so that it looks good as 1 line or multi line.
  color: $pa-TeamIdentity--text_color;
  font-family: $font-family-system;
  font-size: $pa-TeamIdentity--text_size;
  font-weight: $font-weight-mbold;
  padding-left: 24px; //aribtrary val based on current logo.
  line-height: 1.2;

  height: 100%;
  display: flex;
  align-items: center;
}
