@import '~styles/variables';

div.wi-HelpCenter {
  padding: 0;
  margin: 0 0 2px 0;
  display: flex;

  &:hover {
    .wi-Icon {
      @extend %wi-Icon--light_blue;
    }
  }
}
