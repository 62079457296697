@import '~styles/utilities';
@import '~styles/variables';

.Filters-container {
  form,
  input,
  .Select,
  .wi-Input--With-Message {
    margin-bottom: 0;
  }

  .Form-Group-Error-Set {
    margin-top: -1.5rem;
    margin-bottom: 1.5rem;
  }

  .pa-Section {
    margin-bottom: 0px;
  }
}

.Analytics-Description {
  @extend %type-text-regular;
  margin-bottom: 1.5rem;
}
