@import '~styles/variables';
@import '~styles/utilities';

#wi-JobPlanningTemplates {
  @include flexbox(column, flex-start, stretch);
  flex: 1 1 auto;
  height: 100%;

  .wi-SectionHeader {
    margin-bottom: 35px;
  }

  .wi-WellBody {
    @include flexbox(row, space-between, stretch);
    flex: 1 1 auto;
  }

  .wi-StandardInteractions-Query {
    width: 100%;
  }

  .wi-SearchBarContainer {
    flex: 1 1 auto;

    .wi-Input,
    input[type='text'] {
      width: 100%;
    }
  }

  #wi-JobPlanning-Title {
    margin-top: 17px;
  }
}

#wi-JobPlanningTemplates-LeftPane {
  @include flexbox(column, flex-start, stretch);
  flex: 1 1 auto;
  margin-bottom: 0;

  .well {
    padding: 4px;
    @include flexbox(column, flex-start, stretch);
    height: 400px;
    min-height: 400px;
    overflow-y: hidden;
    flex: 1 1 auto;
    margin-bottom: 0;
  }
}

#wi-JobPlanningTemplates-RightPane {
  @include flexbox(column, space-between, stretch);
  flex: 1 1 auto;
  margin-bottom: 0;
  .wi-JobPlanningTemplates-RightPane-Header {
    @include flexbox(row, space-between, center);
  }
}
