@import '~styles/variables';
@import '~styles/typography';
@import '~styles/colors';

#pa-Settings {
  .pa-uppercase {
    @extend %type-header-medium;
  }

  .wi-SectionHeader {
    .pa-SubNav {
      .container {
        background-color: inherit;
      }
    }
  }

  .pa-Settings-container {
    padding-top: 20px;
    padding-left: 15px;

    .pa-Settings-user {
      border-bottom: 1px solid $dark_grey;
      padding-bottom: 10px;
      margin-bottom: 20px;

      div {
        font-size: $font-size-h1;
        font-weight: $font-weight-mbold;
        color: $black;
      }
      span {
        font-weight: $font-weight-light;
      }
    }

    .pa-Settings-account {
      padding-top: 25px;
      border-bottom: 1px solid $dark_grey;

      div {
        width: 600px;
        label {
          font-weight: $font-weight-light;
          display: block;
        }
        input {
          width: 100%;
          height: 30px;
        }
        p {
          font-weight: $font-weight-light;
        }
      }
    }
  }
}

.pa-SettingPageHeader-subtitle {
  color: $dark_grey;
  font-size: 15px;
  margin-bottom: 0;
}

.pa-NotificationSettings {
  padding-left: 22px;
  padding-right: 22px;

  .content {
    font-size: 14px;
  }

  .pa-settingsRow.top {
    border-top: 1px solid $grey;
  }
  .pa-settingsRow {
    padding-top: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid $grey;
    align-items: baseline;

    .topText {
      color: rgb(151, 151, 150);
    }

    table {
      margin-bottom: 0;
      border: 0;
      table-layout: fixed;
      width: 100%;

      th,
      td {
        border-bottom: 0;
        padding-bottom: 0;
        padding-top: 0;
        text-align: center;
        width: 25%;
      }
    }

    .titleContainer {
      align-self: start;
      padding-bottom: 0;
      padding-left: 0;
      .title {
        font-size: 16px;
        font-weight: 600;
      }
      table {
        float: right;
      }
    }

    .toogleContent {
      border-bottom: 0;

      .wi-Input {
        .checkbox {
          height: 22px;
        }
      }

      .description,
      .description-checkbox {
        padding-bottom: 0;
      }

      .toggles {
        text-align: center;
        justify-content: center;
      }
    }
  }
}
