@import '~styles/colors.scss';

.pa-MultiPhotoGalleryModal {
  .pa-MultiPhotoGalleryModal__Description {
    padding-bottom: 24px;
    line-height: 1;
  }

  .pa-MultiPhotoGalleryModal__WarningMessage {
    color: $red;
  }

  .wi-ModalFooter__Right {
    display: flex;
    align-items: center;

    & .wi-Icon {
      margin: 0 !important;
    }
  }
}
