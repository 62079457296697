@import '~styles/variables.scss';
@import '~styles/utilities.scss';
.wi-LinkDescription {
  background-color: $white;
  border: 1px solid $grey;
  margin: 10px 0;
  padding: 8px;
  position: relative;
  z-index: 3;
  width: 99%;

  &.wi-LinkDescription-Focus {
    border-color: $blue;
  }

  .wi-LinkDescription-InputContainer {
    display: none;
  }

  .wi-LinkDescription-Display {
    display: none;
  }

  //NOT EDITIBLE IN STEP
  &:not(.wi-LinkDescription-EditingStep) {
    div.wi-LinkDescription-Display div.wi-LinkDescription-Controls {
      visibility: hidden !important;
    }

    input[type='text'].wi-LinkDescription-ControlInput {
      display: none;
    }
  }

  //NOT LINK EDITING STATE
  &:not(.wi-LinkDescription-EditingLink) {
    padding: 0 4px;
    .wi-LinkDescription-Display {
      @include flexbox();

      &:hover .wi-LinkDescription-Controls {
        visibility: visible !important;
      }
    }

    .wi-LinkDescription-Controls {
      display: inherit;
      visibility: hidden;

      img {
        cursor: pointer;
      }
    }

    button,
    .Button {
      display: none;
    }

    .wi-LinkDescription-Link {
      @include flexbox(row, flex-start);
      text-decoration: none;
    }
  }

  // LINK EDITING STATE
  &.wi-LinkDescription-EditingLink {
    @include flexbox(row, space-between, flex-end);

    .wi-LinkDescription-Input {
      @include flexbox();
      span {
        font-weight: $font-weight-regular;
        color: $charcoal;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .wi-Input {
        width: 90%;

        input {
          margin-bottom: 0;
          width: 95%;
        }
      }

      .wi-Input-Message {
        top: 2px;
      }
    }

    .wi-LinkDescription-InputContainer {
      width: 100%;
      display: block;
    }

    button,
    .Button {
      position: relative;

      &[disabled] {
        //color is too light to notice a difference
        //might change back to defaults, or change defaults to this
        opacity: 0.45 !important;
      }
    }
  }

  input[type='text'].wi-LinkDescription-ControlInput {
    border: 0;
    height: auto;
    padding: 0;
    font-size: 18px;
    width: 5px;
    position: absolute;
    right: -9px;
    bottom: 0;
    margin-bottom: 0;
  }

  .wi-Input {
    input {
      margin-bottom: 0px;
    }
  }

  .wi-Input--With-Message {
    margin-bottom: 0px;
  }

  .wi-Input-Message {
    top: 2px;
  }

  .flexboxgrid--start-xs {
    align-items: flex-start;
  }

  .flexboxgrid--col-xs-2 {
    padding-top: 10px;
  }

  .flexboxgrid--col-xs-4 {
    padding-top: 5px;
  }
}

//use to intercept click events
.wi-LinkDescription-Intercept {
  height: 100%;
  top: 0;
  position: fixed;
  width: 100%;
  left: 0%;
  z-index: 2;
}
