@import '~styles/utilities';
@import '~styles/variables';

.wi-Welcome {
  font-size: 16px;

  .wi-Onboarding {
    padding-top: 0rem;

    .Logo {
      width: 120px;
      margin: 22px auto 50px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 26px;
    color: $text-color;
  }

  p {
    font-size: 20px;
    line-height: 26px;
    color: $text-color;
  }

  .Button {
    margin-bottom: 35px;
  }

  .wi-StoreLinks {
    margin-left: -2px;
    a {
      display: block;

      img {
        margin-top: 10px;
        height: 60px;
      }
    }
  }

  .well {
    background-color: white;
    border: 0px;
    padding: 1rem 1rem;
  }

  .stepper {
    color: $blue;
    font-size: 48px;
    font-weight: normal;
    margin-right: 24px;
    text-align: center;
    width: 27px;
  }

  .stepperText {
    line-height: 20px;
  }

  .titleText {
    font-size: 24px;
    line-height: 29px;
    margin-bottom: 26px;
    font-weight: bold;
  }

  .headerText {
    font-size: 16px;
    line-height: 22px;
    color: $dark_grey;
  }

  .headerTextDark {
    font-size: 16px;
    line-height: 22px;
  }

  .orText {
    font-size: 16px;
    line-height: 22px;
    color: $dark_grey;
    margin-top: 24px;
    margin-bottom: 20px;
  }

  .footerText {
    border-top: 1px solid $dark_grey;
    padding-top: 16px;
    padding-left: 22px;
    padding-right: 22px;
    padding-bottom: 16px;
    margin-top: 50px;
    font-size: 14px;
    color: $dark_grey;
    border-bottom: 1px solid $dark_grey;
  }
}
