@import '~styles/colors.scss';

.wi-PillV2 {
  display: inline-block;
  max-width: 256px;
  padding: 0 12px;
  line-height: 24px;
  background-color: currentolor;
  border-radius: 9999px;
  font-size: 14px;
  font-weight: 400;
  color: $white;
  user-select: none;
}
