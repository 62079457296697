@import '../TemplateDiffReportUtils';

.wi-Diff-StepGroup-BodyWrapper,
.wi-Diff-StepGroup-Container,
.wi-Diff-StepWrapper {
  @include flexbox(column, center, stretch);
}

.wi-Diff-StepGroup-BodyWrapper {
  margin-left: $template-left-indent;
}

.wi-Diff-Template-BodyWrapper {
  margin-left: 50px;
  margin-right: 35px;
}

.wi-Diff-Template-BodyWrapper > div {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.wi-Diff-StepListWrapper > div,
.wi-Diff-StepGroup-Container > div {
  margin-bottom: 10px;
}

.wi-Diff-StepGroup-HeadingWrapper {
  @include flexbox(row, space-between, flex-start);
}

.wi-Diff-StepTitleWrapper {
  @include flexbox();
}

.wi-Diff-StepTitleText {
  @include diffFont();
}

.wi-Diff-StepListWrapper {
  margin-left: $template-left-indent;
}

.wi-Diff-StepGroup-Condition-TitleText {
  @include diffFont();
}

.wi-Diff-StepGroup-Condition-Wrapping {
  @include flexbox(row, space-between);
  margin-left: 5px;

  &.wi-Diff-RemovedCondition {
    .wi-Diff-StepGroup-Condition-YesText {
      color: $red;
      text-decoration: line-through;
    }
  }
}

.wi-Diff-StepGroup-Condition-Text {
  @include diffText();
}

.wi-Diff-StepGroup-Condition-TextWrapping {
  flex-basis: 50%;
}

.wi-Diff-StepGroup-Condition-YesNoWrapper {
  @include flexbox(row, center);
  border-bottom: $border-standard;
  flex-basis: 50%;

  .wi-Diff-StepGroup-Condition-YesText {
    font-weight: $font-weight-heavy;
  }
}

.wi-Diff-EquipmentListTitleWrapper,
.wi-Diff-StepGroup-Condition-TextWrapping,
.wi-Diff-StepGroup-Condition-TitleWrapping {
  @include flexbox(row, flex-start);
}

.wi-Diff-EquipmentListTitleWrapper {
  margin-bottom: 8px;
}

.wi-Diff-EquipmentListTitleText {
  @include diffFont();
}
