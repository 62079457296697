// Copied from http://flexboxgrid.com/ but name-spaced
// to avoid conflicts with dangerously generic classnames

@import '~styles/variables';
@import '~styles/utilities';

$flexbox-grid-columns: 24 !default; // column count

$flexbox-gutter-width: 20px !default; // Set the gutter between columns.
$flexbox-outer-margin: $flexbox-gutter-width * 2 !default; // Set a margin for the container sides.
$flexbox-gutter-width-tight: $flexbox-gutter-width / 2 !default;
$flexbox-outer-margin-tight: $flexbox-gutter-width-tight * 2 !default;
$flexbox-gutter-width-loose: $flexbox-gutter-width * 2 !default;
$flexbox-outer-margin-loose: $flexbox-gutter-width-loose * 2 !default;

$flexbox-max-width: 1200px !default;
$flexbox-prefix: 'flexboxgrid--' !default;

$flexbox-breakpoints: sm 48em 46rem, md 62em 61rem, lg 75em 71rem !default;

$flexbox-gutter-compensation: $flexbox-gutter-width * 0.5 * -1;
$flexbox-half-gutter-width: $flexbox-gutter-width * 0.5;
$flexbox-gutter-compensation-tight: $flexbox-gutter-width-tight * 0.5 * -1;
$flexbox-half-gutter-width-tight: $flexbox-gutter-width-tight * 0.5;
$flexbox-gutter-compensation-loose: $flexbox-gutter-width-loose * 0.5 * -1;
$flexbox-half-gutter-width-loose: $flexbox-gutter-width-loose * 0.5;

@mixin flexboxgrid-sass-col-common {
  box-sizing: border-box;

  // split @include flex(0, 0, auto) into individual
  // props so other classes can change them easily
  flex-grow: 0;
  flex-shrink: 0;

  // we leave `flex-basis: auto` out of common because
  // in some spots we need it and some we dont
  // more why here: https://github.com/kristoferjoseph/flexboxgrid/issues/126
  padding-right: $flexbox-half-gutter-width;
  padding-left: $flexbox-half-gutter-width;
  padding-bottom: $flexbox-half-gutter-width;

  &.#{$flexbox-prefix}tight,
  .#{$flexbox-prefix}tight & {
    padding-right: $flexbox-half-gutter-width-tight;
    padding-left: $flexbox-half-gutter-width-tight;
    padding-bottom: $flexbox-half-gutter-width-tight;
  }

  &.#{$flexbox-prefix}loose,
  .#{$flexbox-prefix}loose & {
    padding-right: $flexbox-half-gutter-width-loose;
    padding-left: $flexbox-half-gutter-width-loose;
    padding-bottom: $flexbox-half-gutter-width-loose;
  }
}

@mixin flexbox-create-breakpoint($name) {
  .#{$flexbox-prefix}col-#{$name} {
    @include flexboxgrid-sass-col-common;
    flex-basis: auto;
  }

  @for $i from 1 through $flexbox-grid-columns {
    .#{$flexbox-prefix}col-#{$name}-#{$i} {
      @include flexboxgrid-sass-col-common;
      flex-basis: 100% / $flexbox-grid-columns * $i;
      max-width: 100% / $flexbox-grid-columns * $i;
    }
  }

  @for $i from 1 through $flexbox-grid-columns {
    .#{$flexbox-prefix}col-#{$name}-offset-#{$i} {
      @include flexboxgrid-sass-col-common;
      margin-left: 100% / $flexbox-grid-columns * $i;
    }
  }

  .#{$flexbox-prefix}col-#{$name}-offset-0 {
    @include flexboxgrid-sass-col-common;
    margin-left: 0;
  }

  // TODO: Add support for customizing these flex props:
  // flex-order
  // flex-direction
  // flex-wrap
  // flex-grow
  // flex-shrink
  // align-self
  // align-content

  .#{$flexbox-prefix}col-#{$name} {
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
  }

  .#{$flexbox-prefix}col-#{$name}-auto {
    @include flexboxgrid-sass-col-common;
    flex-grow: 1;
    flex-basis: auto;
    max-width: 100%;
  }

  .#{$flexbox-prefix}col-#{$name}-fixed {
    @include flexboxgrid-sass-col-common;
    flex-basis: auto;
  }

  .#{$flexbox-prefix}start-#{$name} {
    justify-content: flex-start;
    text-align: start;
  }

  .#{$flexbox-prefix}center-#{$name} {
    justify-content: center;
    text-align: center;
  }

  .#{$flexbox-prefix}end-#{$name} {
    justify-content: flex-end;
    text-align: end;
  }

  .#{$flexbox-prefix}around-#{$name} {
    justify-content: space-around;
  }

  .#{$flexbox-prefix}between-#{$name} {
    justify-content: space-between;
  }

  .#{$flexbox-prefix}top-#{$name} {
    align-items: flex-start;
  }

  .#{$flexbox-prefix}middle-#{$name} {
    align-items: center;
  }

  .#{$flexbox-prefix}bottom-#{$name} {
    align-items: flex-end;
  }

  .#{$flexbox-prefix}stretch-#{$name} {
    align-items: stretch;
  }

  .#{$flexbox-prefix}first-#{$name} {
    order: -1;
  }

  .#{$flexbox-prefix}last-#{$name} {
    order: 1;
  }
}

.#{$flexbox-prefix}wrapper {
  box-sizing: border-box;
  max-width: $flexbox-max-width;
  margin: 0 auto;
}

.#{$flexbox-prefix}container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-right: $flexbox-outer-margin;
  padding-left: $flexbox-outer-margin;
}

.#{$flexbox-prefix}row {
  box-sizing: border-box;
  @include flexbox();
  flex: 0 1 auto;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: $flexbox-gutter-compensation;
  margin-left: $flexbox-gutter-compensation;

  &.#{$flexbox-prefix}tight {
    margin-right: $flexbox-gutter-compensation-tight;
    margin-left: $flexbox-gutter-compensation-tight;
  }

  &.#{$flexbox-prefix}loose {
    margin-right: $flexbox-gutter-compensation-loose;
    margin-left: $flexbox-gutter-compensation-loose;
  }
}

.#{$flexbox-prefix}row.#{$flexbox-prefix}reverse {
  flex-direction: row-reverse;
}

.#{$flexbox-prefix}col.#{$flexbox-prefix}reverse {
  flex-direction: column-reverse;
}

@include flexbox-create-breakpoint(xs);

@each $breakpoint in $flexbox-breakpoints {
  $name: nth($breakpoint, 1);
  $size: nth($breakpoint, 2);
  $container: nth($breakpoint, 3);

  @media only screen and (min-width: $size) {
    .#{$flexbox-prefix}container {
      width: $container;
    }

    @include flexbox-create-breakpoint($name);
  }
}
