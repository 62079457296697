@import '~styles/variables';
@import '~styles/utilities';

.wi-EditorTools-Modal,
.wi-EditorTools,
.GroupHeaderIcon,
.equipment {
  .wi-ExifImage-Container {
    @include flexbox(column, center, center);
    margin: 5px;

    img {
      margin: 0 !important;
    }
  }
}
.equipment {
  .wi-ExifImage-Container {
    width: 65px;
    height: 65px;
  }
}

.wi-ExifImage-Container {
  font-size: 0;

  img.pa-signature {
    width: 300px;
    height: auto;
  }
}

.wi-JobDetailReport-Appendix {
  .wi-ExifImage-Container.pa-simpleImage {
    font-size: 0;
    display: flex;

    img.pa-simpleImage {
      max-width: 100%;
    }
  }
}
