@import '~styles/variables';

.pa-CalcFieldDisplay {
  > div {
    align-items: baseline;
  }
  &.pa-CalcFieldDisplay-noFormula {
    display: flex;
  }
  .pa-CalcFieldDisplay-key {
    font-weight: $font-weight-mbold;
  }
  .pa-FormulaDisplay {
    flex-direction: row;
    align-items: baseline;
    padding-left: 40px;
    .pa-FormulaDisplay-header {
      margin-left: 0;
      font-weight: $font-weight-mbold;
    }
  }
  .pa-FormulaDisplay * {
    color: $black !important;
    font-size: $font-size-base !important;
  }
  .dotted-connector {
    min-width: 0;
    &:after {
      margin-top: 14px;
    }
  }
}

.pa-CalculatedValue {
  &.pa-CalculatedValue--error {
    color: $black;
  }

  .pa-CalculatedValue-units {
    font-size: 0.9em;
    margin-left: 3px;
  }
}
