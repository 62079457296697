@import '~styles/variables';

.pa-TrueFalse {
  .ButtonSet {
    .Button:first-child {
      border-bottom-right-radius: 0px;
      border-top-right-radius: 0px;
      margin-right: 0px;
    }

    .Button:last-child {
      border-bottom-left-radius: 0px;
      border-top-left-radius: 0px;
      border-left: none;
      margin-left: 0px;
    }

    .Button-primary {
      border: solid 1px $blue;
    }

    .Button-secondary {
      border: solid 1px $grey;
      color: $charcoal;
    }

    .Button {
      height: 40px;
      flex: 1;
      margin-left: 0px;
      margin-right: 0px;
    }

    .Button-primary.Button-disabled {
      background-color: $charcoal;
      color: $white;
    }

    .Button-disabled {
      border-color: $black;
    }
  }

  &.disabled {
    opacity: 0.6;
    pointer-events: none;
  }
}
