@import '~styles/variables.scss';
@import '~styles/utilities.scss';
@import '~styles/extends';
@import './StepFieldGroup.scss';

$select-height: 30px;
$stepField-labelWidth: 150px;
$label-flexBasis: 350px;

.wi-StepField.pa-CalculatedStepField {
  &.wi-StepField-Editing.wi-stepField-childContainer &:hover {
    background-color: $light_pattens_blue;
  }
  &.wi-StepField-Editing.wi-stepField-childContainer:hover {
    background-color: #ffffff;
  }
  flex-direction: row;
  .wi-stepField__calcLabel {
    flex-basis: 270px;
    flex-shrink: 0;
  }
  .wi-stepField__calcUnit {
    flex-basis: 80px;
    flex-shrink: 0;
  }
  .wi-StepField-inputWrapper {
    flex-basis: auto;
  }
  .wi-stepField__calcUnit,
  .wi-stepField__calcUnit .pa-RefField-Single.disabled {
    font-size: 12px;
    font-weight: $font-weight-semibold;
  }
  .wi-stepField__calcUnit .pa-RefField-Single.disabled {
    font-weight: $font-weight-regular;
  }
}
//Editing Mode
//-------------------------------------
.wi-StepField.pa-CalculatedStepField.wi-StepField-Editing {
  .wi-stepField__calcUnit {
    font-size: 14px;
  }
  .wi-stepField__calcUnit {
    font-weight: $font-weight-regular;
  }
}

.wi-StepField {
  // these styles are a result of us using the same component
  // when in edit & non-edit mode. So hacky.
  display: flex;
  justify-content: space-between;
  align-items: center;

  .wi-StepField-labelWrapper {
    display: none;
  }
  .pa-Select-Label {
    display: none;
  }
  .wi-StepFieldDescription {
    display: none;
  }

  // hover & drag state
  .wi-StepFieldGroup-Editable:not(.wi-StepFieldGroup-Dragging) &:not(.wi-StepField-Editing):hover,
  .wi-StepFieldGroup-Editable &.wi-StepField-Dragging {
    .wi-stepField-childContainer:hover {
      background-color: var(--secondaryA_100, $light_pattens_blue);
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
      cursor: move;
    }
  }

  .row {
    display: flex;
    align-items: flex-start;
    width: 100%;
  }

  .wi-StepField-Icon {
    margin-right: 12px;
  }

  .wi-StepField-Key {
    display: flex;

    input {
      margin-bottom: 0px;
    }
    .wi-Input-Message--Danger {
      top: 0px;
    }
    .wi-StepField-KeyContent {
      margin-left: 8px;
      margin-right: 8px;
      display: flex;
      flex-wrap: nowrap;
    }
  }

  .StepField-metaHeading {
    font-weight: 500;
  }

  .pa-StepField-Icon {
    display: flex;
    align-items: center;
  }

  .wi-StepFieldDescription {
    .wi-StepFieldDescriptionHeader {
      @include flexbox();

      span:first-child {
        font-weight: $font-weight-mbold;
        font-size: $font-size-h1;
      }
    }
  }

  .wi-RequiredField {
    @include flexbox(row);
    flex-shrink: 0;

    .pa-help {
      display: flex;
    }

    .wi-Input {
      width: auto;
      margin-right: 5px;

      input {
        margin: 0 8px 0 0;
        &:focus {
          outline: 0;
        }
      }
    }

    label {
      margin-bottom: 0;
    }
  }

  .wi-StepField-Controls_Editing {
    @include flexbox(row, space-between, flex-start);
    flex-shrink: 0;
  }

  .wi-RequiredField {
    white-space: nowrap;
    margin-right: 20px;
  }

  .wi-StepField-Controls {
    flex-shrink: 0;
  }

  /*
  there is a lot of "alignment" going on here, if you see a magic number
  its probably related to aligning items in a step field, these aren't
  captured as variables because they are specific to this component
  */
  .wi-StepField-KeyInput,
  .wi-StepField-Key {
    height: auto;
    font-size: $font-size-small;
    input {
      margin-bottom: 0px;
    }
  }

  .wi-Input {
    display: inline-block;
    margin-bottom: 0;
    width: 100%;

    .form-group {
      display: inline;
    }
  }

  //Pretty much everything below is a magic number used to align
  //the select with the input, change when needed
  .wi-StepField-TypeInput {
    .Select-menu-outer {
      overflow-x: hidden;
      overflow-y: hidden;
      position: absolute;
      top: 28px;
    }
    .Select-menu {
      position: relative;
      div {
        .Select-menu-outer {
          position: relative;
          border: 0;
          top: 0;
        }
      }
    }
  }

  .Select-control {
    padding: 0 5px;
    height: $select-height;

    .Select-input {
      height: $select-height;
    }

    .Select-value {
      line-height: 31px;
    }

    .Select-placeholder {
      padding: 0 10px;
      font-weight: $font-weight-regular;
    }
  }

  .Select-menu {
    max-height: 200px;
  }

  .wi-StepField-Key {
    border-color: transparent;
    background-color: transparent;
    text-overflow: ellipsis;
  }

  .container {
    margin-left: 0;
    width: 100%;
  }

  .wi-StepField-EditIcon,
  .wi-StepField-DuplicateIcon,
  .wi-StepField-DeleteIcon {
    &:hover {
      cursor: pointer;
    }
  }

  .wi-StepField-EditIcon,
  .wi-StepField-DuplicateIcon {
    position: relative;
    top: 1px;
  }

  .wi-StepField-DraggableIcon {
    padding-right: 4px;
    position: relative;
    top: -4px;
    height: 19px;

    &:hover {
      cursor: move;
    }
  }

  .pa-NumberOptions {
    @include flexbox(row, flex-start, center);
    flex-wrap: wrap;

    .wi-StepField-label {
      margin-right: 10px;
      @include flexbox(row, flex-start, center);
    }

    .pa-StepField-MeasurementUnitSymbol {
      @extend %pa-RefField-Single;
      border: none;
    }
  }

  .wi-StepField-Controls {
    .wi-Icon {
      margin-right: 12px;
    }
  }

  .pa-StepField-NotificationsPill {
    background-color: $grey;
    align-self: baseline;
    color: $charcoal;
    border-radius: 2px;
    border: none;
    margin-top: 0;
  }

  .wi-StepField-MinusIcon {
    vertical-align: text-bottom;
    margin-right: 0.5rem;
    width: 1.4rem;
  }

  .wi-StepField-Params {
    display: flex;
    align-items: center;
    flex: 1;
  }
  .wi-StepField-Params .pa-RefField-Single,
  .wi-StepField-Params {
    font-size: 12px;
  }
}

.wi-StepField.wi-StepField-NonEditing .wi-StepField-Controls {
  visibility: hidden;
  display: none;
}

.pa-StepField-CalcUnits {
  display: flex;
  flex-direction: column;
}

.pa-StepField-RequiredFieldOverlay {
  width: 200px;
}

.wi-StepTitle-Ref .pa-RefField-Single {
  background-color: #ffffff;
}
.pa-RefField-Single.disabled {
  padding-left: 0px;
  font-weight: $font-weight-semibold;
}

.wi-StepField-Actions {
  margin-top: 20px;
  margin-bottom: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

//StepField Rules (Applet + Validation)
.pa-StepField-RuleWrapper {
  padding: 4px 0;
}
.pa-StepField-RuleContent {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 5px;
  margin-bottom: 4px;
  &:hover {
    background-color: $light_pattens_blue;
  }
  .pa-info {
    margin-right: 8px;
    margin-top: 2px;
    fill: $blue;
  }
  .pa-help {
    margin-right: 8px;
    margin-top: 2px;
  }
}
.pa-StepField-RuleControls {
  flex-basis: 48px;
  display: flex;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;
  margin-left: 15px;

  //If only one icon align right.
  .wi-Icon:only-child {
    margin-left: auto;
  }
}

//Wrappers for stepField labels and inputs
// TODO: Remove these overrides once grid supports flex-shrink, etc.
.wi-stepField-row {
  display: flex;
  margin-bottom: 0;
}
.wi-StepField-labelWrapper {
  flex-basis: $stepField-labelWidth;
  flex-shrink: 0;
  padding-top: 4px; //aligns label vertically with right element.
}
.wi-StepField-inputWrapper {
  flex-basis: $label-flexBasis;
}

//StepField Options overrides (Temporary)
// TODO: Remove these overrides once grid supports flex-shrink, etc.
.wi-InputNumber-MeasureUnits .wi-StepField-labelWrapper {
  flex-basis: 100px;
}
.wi-StepField-Constraints .wi-StepField-labelWrapper {
  flex-basis: 70px;
}
.wi-StepField-Constraints .wi-StepField-inputWrapper {
  flex-basis: 225px;
  flex-grow: 0;
}
.wi-StepField-inputWrapper__inline {
  display: flex;
}
.wi-StepField-inputWrapper__inline > div {
  flex-basis: 50%;
}
.wi-InputNumber-Conjunction {
  display: flex;
  align-items: center;
  margin-right: 10px;
  margin-left: 10px;
}

//Editing Mode
//-------------------------------------
.wi-StepField.wi-StepField-Editing {
  display: block;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  .wi-StepField-labelWrapper {
    display: flex;
  }
  .wi-StepFieldDescription {
    display: block;
  }
  .wi-StepField-Editing {
    .wi-StepField-TypeInput,
    .wi-StepField-KeyInput {
      border-color: $blue;
      width: 100%;
      margin: 0;
    }
  }
  .wi-StepField-Key {
    flex-direction: column;
  }
  .wi-StepField-Content {
    display: block;
  }
  .wi-stepField-row {
    flex-grow: 1;
  }
  .wi-stepField-section__inputType,
  .wi-stepField-section__dataValidation,
  .wi-stepField-section__description {
    display: flex;
  }
  .wi-stepField-section__inputType {
    flex-direction: column;
  }
  .wi-StepField-inputWrapper {
    flex-grow: 1;
  }
}

.wi-StepField-Content {
  display: flex;
  align-items: center;
}
.wi-StepField-Content,
.wi-stepField-section {
  flex-grow: 1;
}
.wi-stepField-section__inputType,
.wi-stepField-section__dataValidation,
.wi-stepField-section__description {
  display: none;
}
