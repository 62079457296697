@import '~styles/variables';

.d3-graph {
  position: relative;
  margin-top: 20px;

  text {
    font-family: $font-family;
  }

  &.d3-graph_editing {
    margin-top: 0;
  }

  .wi-Dropdown {
    margin: 0;
  }

  svg {
    shape-rendering: geometricPrecision;
  }
}

.d3-svg-container {
  height: 100%;
}

.d3-tip {
  font-size: 12px;
  background: $white;
  border: 1px solid #979797;
  border-radius: 3px;
}
