@import '~styles/variables';
@import '~styles/utilities';

.wi-SourceTemplates {
  @include flexbox(column, space-between, stretch);
  flex: 1 1 auto;

  .wi-SourceTemplates-Spinner {
    flex: 1;
  }
}

.wi-SourceTemplates-Title {
  flex: 0 0 auto;
}

.wi-SourceTemplates-List {
  overflow: auto;
  flex: 1 1 auto;
}

.wi-SourceTemplates-Pagination {
  margin: 4px 0 0;
}
