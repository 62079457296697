@import '../TemplateDiffReportUtils';

$ele_height: 30px;
$base_wrap_padding: 5px;
$icon_padding: $base_wrap_padding;
$base_wrap_padding: $base_wrap_padding ($base_wrap_padding * 2);

.wi-Diff-NoticeList,
.wi-Diff-NoticeListWrapper {
  @include flexbox(column, flex-start, stretch);
}

.wi-Diff-NoticeListWrapper {
  margin-left: $template-left-indent;
}

.wi-Diff-NoticeListTitleWrapper {
  @include flexbox(row, flex-start);
  margin-bottom: 10px;
}

.wi-Diff-NoticeListTitleText {
  @include diffFont();
}

.wi-Diff-Notice-NoticeWrapper {
  @include flexbox(row, flex-start);

  &.wi-Diff-RemovedBorder {
    @include diffImgRemoved();

    .wi-Diff-NoticeIconWrapper {
      @include diffImgRemoved();
      border-left: 0;
      border-top: 0;
      border-bottom: 0;
    }
  }

  &.wi-Diff-AddedBorder {
    @include diffImgAdded();

    .wi-Diff-NoticeIconWrapper {
      @include diffImgAdded();
      border-left: 0;
      border-top: 0;
      border-bottom: 0;
    }
  }

  margin-bottom: 5px;
  border: $border-standard;
}

.wi-Diff-Notice {
  @include flexbox(row, flex-start);
}

.wi-Diff-NoticeDescripWrapper {
  display: inline-block;
  flex-grow: 1;
  padding: $base_wrap_padding;

  .wi-Annotation-Wrapper {
    margin-top: 7px;
  }
}

.wi-Diff-NoticeDescripText {
  display: inline;
  @include diffText();
}

.wi-Diff-NoticeIconWrapper {
  @include flexbox(column);
  border-right: $border-standard;
  padding: $icon_padding;
}

.wi-Diff-NoticeIconModifiedWrapper {
  @include flexbox(column);
  border-right: $border-standard;
  padding: $icon_padding ($icon_padding - 1px);

  .wi-Annotation-Wrapper {
    margin-top: 32px;
  }
}

.wi-Diff-NoticeIconEle {
  @include flexbox();
  @include diffImg();

  &.wi-Diff-ImageAdded {
    margin-top: 5px;
  }

  .wi-ExifImage-Container,
  .Document-Thumbnail,
  .Document {
    @include flexbox();
    margin: 0;
  }

  img {
    height: $ele_height;
    width: $ele_height;
  }
}
