@import '~styles/variables';
@import '~styles/utilities.scss';

.pa-Threaded-Decorator {
  @include flexbox(row, space-between, top);
  flex-basis: 100%;

  .pa-Threaded-Decorated {
    flex: 1 1 100%;
  }
}
