@import '~styles/colors';

.pa-AttributeSelector {
  .selectAll {
    color: #0086bf;
    cursor: pointer;
    font-weight: 500;
    font-size: 12px;
    float: right;
    clear: both;
    margin-top: -28px;
  }

  &-Label {
    &-Spinner {
      display: inline-block;
      margin-left: 8px;
      vertical-align: middle;
    }
  }

  &-SelectWrapper {
    position: relative;

    &-Curtain[data-visible='true'] {
      position: absolute;
      inset: 0;
      background-color: rgba(0, 0, 0, 0.1);
      border-radius: 4px;
    }
  }

  .Select {
    &.is-disabled {
      .Select-value-label {
        color: $charcoal;
      }
    }
    clear: both;
  }
}
