@import '~styles/variables';
@import '~styles/utilities';

.par-ThreadCard {
  @include flexbox(row, space-between, center);
  flex-wrap: wrap;
  height: 67px;
  background-color: $white;
  border-bottom: solid 1px $grey;
  border-left: 5px solid $white;
  padding: 10px;
  overflow: hidden;
  width: 100%;

  &.active {
    border-left: 5px solid $blue;
  }

  .par-ThreadCard--Subject {
    font-size: 14px;
    font-weight: bold;
    color: $black;
    flex: 1;
  }

  .par-ThreadCard--Date {
    font-size: 14px;
    color: $dark_grey;
  }

  .par-ThreadCard--Anchor {
    @include singleLineTextEllipsis();
    flex-basis: 100%;
    color: $charcoal;
    font-size: 14px;
  }
}
