@import '../TemplateDiffReportUtils';

.wi-Diff-Step-Body {
  margin-top: 2.5px;
  border: $border-standard;
  padding: $template-padding;
}

.wi-Diff-Step-Wrapper {
  &:not(:last-child) {
    margin-bottom: 10px;
  }
}

.wi-Diff-Step-Wrapper,
.wi-Diff-Step-Body {
  @include flexbox(column, center, stretch);
}

.wi-Diff-Step-HeadingWrapper,
.wi-Diff-Step-LeftWrapper,
.wi-Diff-Step-IconEle {
  @include flexbox();
}

.wi-Diff-Step-IconWrapper {
  margin-left: -5px;
  flex-grow: 0;
}

.wi-Diff-Step-LeftWrapper > div {
  margin-right: 5px;
}

.wi-Diff-Step-LeftWrapper {
  .wi-Diff-Heading-Title,
  .wi-Diff-Step-IconWrapper,
  .wi-Diff-HierarchyWrapper,
  .wi-Diff-Step-AssigneeWrapper {
    flex-shrink: 0;
  }
}

.wi-Diff-ConditionalLogic-TitleText {
  @include diffFont();
}
