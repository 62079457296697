@import '~styles/variables';
@import '~styles/utilities';

.wi-TemplateEditor-container {
  position: relative;
}

.swal-text {
  text-align: center;
}

#TemplateEditor {
  display: flex;

  .wi-EditorDocumentsPanel--Title {
    border-bottom: 1px solid $grey;
  }

  .editor-panel {
    flex: 1 1 auto;
    flex-direction: column;

    $panel_padding: 5px 10px;

    color: $white;

    .panel-header,
    .panel-buttons {
      background-color: $white;
    }
    .panel-buttons {
      padding: 10px;
    }

    .panel-header {
      background-color: $light_grey;
      .panel-title {
        padding: $panel_padding;
        font-variant: small-caps;
        margin-left: 12px;
      }
    }

    .panel-body {
      overflow-y: auto;
      overflow-x: hidden;
      padding: $panel_padding;
      flex: 1;
      background-color: $white;
      padding-bottom: 50px;
    }
  }
  #EditorSidePanel {
    width: 25%;
    display: flex;
    flex-direction: column;

    @media (max-width: 1300px) {
      width: 320px;
    }

    &[data-tab='steps'] #EditorDocumentsPanel {
      display: none;
    }
    &[data-tab='documents'] #EditorTasksPanel {
      display: none;
    }

    #EditorDocumentsPanel {
      background-color: $white;
    }
  }
}
