@import '~styles/variables';
@import '~styles/colors';
@import '~styles/utilities.scss';

.wi-ChooseDataSheetVariationsModal {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: scroll;

  .infoBox {
    @include infoBoxMixin();
    margin: 10px 0 20px 0;
  }
}
