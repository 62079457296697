@import '~styles/variables';

.wi-SearchBarContainer {
  display: flex;
  overflow: hidden;

  &.withIcon {
    border: 1px solid $grey;
    border-radius: 4px;

    .wi-Icon {
      padding-left: 10px;
      background: white;
      svg {
        height: 16px;
        width: 16px;
      }
    }

    .wi-Input,
    input[type='text'] {
      border: none;
    }

    &.focussed {
      border: 1px solid var(--secondaryA, $blue);
      outline: 0;
    }
  }
}
