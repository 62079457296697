@import '~styles/variables';

.wi-MetadataContext {
  .pa-lockIcon {
    padding-top: 0.6rem;
  }

  input[type='text']:disabled {
    background-color: $light_grey;
  }

  .MetadataDate_Container {
    display: flex;

    .wi-Input {
      flex-grow: 1;
    }

    .date-pickerPopup {
      .Button {
        height: 100%;
        border: 1px solid $grey;
      }
    }
  }
}

// Short term fix.
// TODO: Refactor forms so inputs and labels include a
// a presentatioanl wrapper i.e ".form-group"
.ReactModal__Content .wi-Metadata .Select {
  margin-bottom: 0;
}
// Short term fix.
// Makes inputs styles match the AttributeSelector
// in Job Planning.
.wi-MetadataEditor .wi-Input input {
  margin-bottom: 0;
}
