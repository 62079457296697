//mixins:
@import '~styles/variables';
@mixin flexbox($flex-direction: row, $justify-content: space-between, $align-items: center) {
  display: flex;
  flex-direction: $flex-direction;
  justify-content: $justify-content;
  align-items: $align-items;
}

@mixin singleLineTextEllipsis() {
  text-overflow: ellipsis;
  @include singleLineNoWrap();
  overflow: hidden;
}

@mixin singleLineNoWrap() {
  white-space: nowrap;
}

@mixin headerAtTop($offset: 0px) {
  $totalOffset: $offset + $header_height;
  height: calc(100vh - #{$totalOffset});
  position: relative;
}

@mixin dropdownPillHelper($textColor: $blue, $pillColor: $light_grey) {
  font-size: $font-size-micro;
  font-weight: $font-weight-regular;
  background-color: $pillColor;
  color: $textColor;
  border-radius: 50px;
  padding: 3px 8px;
  margin: 8px 0 5px 5px;
}

@mixin addIcon() {
  color: $blue;
  cursor: pointer;
  font-weight: 500;
  span {
    margin-left: 4px;
  }
  img {
    outline: none !important;
  }
}

@mixin truncate() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin boxify($border: $border-standard, $border-radius: $border-radius-standard) {
  border: $border;
  border-radius: $border-radius;
  box-sizing: border-box;
}

@mixin diffSubtext($textColor: $grey, $font-size: $font-size-micro) {
  color: $textColor;
  font-size: $font-size;
  font-style: italic;
}

// we have a global `button` style which is pretty troublesome, this is handy to have around
@mixin buttonReset() {
  text-transform: initial;
  font-weight: normal;
  letter-spacing: initial;
  font-size: inherit;
}

.nowrap {
  white-space: nowrap;
}

.relative {
  position: relative;
}

.fullWidth {
  width: 100%;
}
/*
* $left: left offset to anchor point
* $top: top offset to anchor point
* $direction: wether arrow points from bottom or from top
* $color: arrow color
*/
@mixin calloutArrow(
  $left: -18px,
  $top: 30px,
  $direction: bottom,
  $arrow_offset: 7,
  $color: $dark_blue
) {
  left: $left;
  top: $top;
  &:after,
  &:before {
    #{$direction}: 100%;
    left: -1 * $left + $arrow_offset;
    border: solid transparent;
    content: ' ';
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after {
    border-color: transparent;
    border-#{$direction}-color: $color;
    border-width: 9px;
    margin-left: -9px;
  }
  &:before {
    border-color: transparent;
    border-#{$direction}-color: $charcoal;
    border-width: 10px;
    margin-left: -10px;
  }
}

@mixin center-children() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin zoomAnimation() {
  animation-name: zoomIn;
  animation-duration: 200ms;
  animation-direction: alternate;
}

@keyframes zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@mixin infoBoxMixin($color: $dark_blue, $background: $light_pattens_blue, $borderColor: $blue) {
  display: flex;
  background: $background;
  border-radius: 4px;
  padding: 10px;
  align-items: start;
  color: $color;
  font-size: 13px;
  border: 1px solid $borderColor;

  .wi-Icon {
    margin-right: 10px;

    path {
      fill: $borderColor;
    }
  }
}

@mixin badge() {
  display: inline-block;
  padding: 0.25em 0.4em;
  font-size: 75%;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

@mixin animated($duration) {
  animation-duration: $duration;
  animation-fill-mode: both;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}
