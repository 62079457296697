@import '~styles/variables';
@import '~styles/utilities';

.wi-PaginationFooter {
  @include flexbox(row, space-between);
  background-color: white;
  height: 48px;
  padding: 0 18px;
  border: 1px solid $grey;
  border-top: none;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;

  .wi-PaginationFooter-Left {
    font-size: 14px;
    color: $charcoal;
  }

  .wi-PaginationFooter-Right {
    @include flexbox(row, flex-end);
    color: $blue;

    .wi-PaginationFooter-Ellipsis,
    .wi-PaginationFooter-PageElement,
    .wi-PaginationFooter-Button {
      padding: 5px;
      margin: 1px;
    }

    .wi-PaginationFooter-PageElement,
    .wi-PaginationFooter-Button {
      cursor: pointer;
    }

    .wi-PaginationFooter-PageElement {
      &.wi-Active,
      .wi-Ellipsis {
        color: $charcoal;
      }
    }

    .wi-PaginationFooter-Button_Previous {
      margin-right: 10px;
    }

    .wi-PaginationFooter-Button_Next {
      margin-left: 10px;
    }

    .wi-PaginationFooter-Disabled {
      cursor: default !important;
      opacity: 0.4;
    }
  }
}
