@import '../TemplateDiffReportUtils';

$padding-top: 0;
$length-of-arrow: 12px;
$height-of-arrow: 22px + $padding-top;
$height-of-arrow-half: calc($height-of-arrow / 2);
$start-text-padding: 5px;

.wi-Annotation-Wrapper {
  @include flexbox();
  margin-top: calc(-#{$height-of-arrow-half} / 2);
  align-self: center;
  position: absolute;
  height: auto;
  left: 0;
}

.wi-Annotation-Wrapper--bottom {
  margin-bottom: calc(-#{$height-of-arrow});
  margin-top: 0;
}

.wi-Annotation-Color {
  width: 4px;
  height: $height-of-arrow;
}

.wi-Annotation {
  @include flexbox(row, space-between, baseline);
  height: $height-of-arrow;
  position: relative;
  padding: $padding-top 2px $padding-top 5px;
  color: $white;
  background-color: $charcoal;
  content: ' ';
  width: 75px;
  cursor: default;

  .wi-Annotation-Text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .wi-Annotation-Multiple {
    color: $white;
  }

  &:before {
    top: 0;
    border-style: solid;
    position: absolute;
    height: auto;
    bottom: auto;
    left: auto;
    right: -$length-of-arrow;
    border-width: $height-of-arrow-half 0 $height-of-arrow-half $length-of-arrow;
    content: ' ';
    border-color: transparent $charcoal;
  }

  &:after {
    top: 0;
    border-style: solid;
    position: absolute;
    height: auto;
    bottom: auto;
    left: auto;
    right: -$length-of-arrow;
    border-width: $height-of-arrow-half 0 $height-of-arrow-half $length-of-arrow;
    content: ' ';
    border-color: transparent $charcoal;
  }
}
