@import '~styles/utilities';
@import '~styles/variables';

.JobPlanningDetails-Recurring {
  flex: 1 0 100%;

  form,
  input,
  .radio label,
  .wi-Input--With-Message {
    margin-bottom: 0;
  }

  // customizing the input height to 32px
  input,
  .datePickerContainer,
  .Select-control,
  .Select-input {
    height: 32px !important;
  }

  .Select-control,
  .Select-input {
    height: 30px !important;
  }

  .Select-value {
    line-height: 30px !important;
  }

  .Select-input > input {
    padding: 0px !important;
  }

  .radio input,
  .checkbox input {
    height: auto !important;
  }

  .centerFlexColumn {
    display: flex;
    align-items: center;
  }

  .flexRow,
  .repeatFrequencySelect,
  .timezoneSelect,
  .wi-Input {
    flex: 1;
  }

  .repeatFrequencySelect--value .Select-value {
    overflow: visible;
  }

  .flatLeft {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -1px;
  }

  .flatRight {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    flex: 1;
  }

  .checkBoxRadio {
    flex: 0 0 auto;
    margin-left: 0.3rem;
  }

  .timeInput,
  .repeatTimesInput {
    width: 80px;
  }

  .datePickerContainer {
    display: flex;
    height: 38px;
    flex: 1;
    .date-pickerPopup-container_align-bottomRight {
      bottom: 38px;
    }
  }

  .JobPlanning-Form-Group {
    margin-top: 5px;

    .control-label {
      display: block;
      margin-bottom: 10px;
      font-weight: 600;
    }
  }

  .JobPlanning-Form-Group_tall {
    margin-top: 25px;
  }

  .JobPlanning-Form-Group_compact {
    margin-top: 0px;
  }

  .JobPlanning-Form-Group-content {
    flex: 1;
    flex-basis: 100;
    @include flexbox(row, flex-start, center);

    > span {
      margin-right: 0.5rem;
    }

    // make button the full
    // height to match input
    .Button {
      height: 100%;
      min-width: 0;
      border-color: $grey;

      .wi-Icon {
        max-width: 25px;
        max-height: 25px;
        margin: 0;
      }
    }
  }

  .JobPlanning-Form-Group-content_singleLady {
    flex: 1;
    flex-basis: 100;
  }

  .JobPlanning-Form-Group_withError {
    .wi-Input input,
    .Button,
    .Select-control {
      border-color: $red !important;
    }
  }

  .JobPlanning-Form-Group-Error {
    color: $red !important;
  }

  .JobPlanning-Form-InlineElement {
    @include flexbox(row, flex-start, center);
    flex: 1;
    font-weight: normal;

    &.center {
      align-items: center;
    }

    &.baseline {
      align-items: baseline;
    }

    &.initial {
      align-items: initial;
    }

    &.clear {
      flex-basis: 100;
    }

    &.tall {
      margin-bottom: 5px;
    }

    .JobPlanning-Form-InlineElement-Pre {
      margin-right: 0.5rem;
      margin-bottom: 0px;
      font-weight: inherit;
    }

    .JobPlanning-Form-InlineElement-Post {
      margin-left: 0.2rem;
    }

    .JobPlanning-Form-InlineElement-Inner {
      @include flexbox(row, space-between, center);
      &.noBasis {
        flex: 1;
      }

      &.single {
        flex: 0 1 80px;
      }

      &.double {
        flex: 0 1 160px;
      }
    }
  }

  .wi-HelperText {
    svg {
      width: 14px;
      height: 14px;
    }
  }
}
