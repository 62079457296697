@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-EditorCategory {
  display: inline-block;
  margin-right: 5px;

  img {
    height: 22px;
  }
}
