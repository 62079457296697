@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-StepFieldValidation-MessageContent {
  margin-top: 8px;

  .wi-NoticeSimplified .pa-RefField-Multi {
    overflow: inherit;
  }
}
