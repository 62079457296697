@import '~styles/colors.scss';

.MultipleInputField {
  width: 100%;
  border: $grey solid 1px;
  border-radius: 5px;
  padding: 5px 0px 0px 5px;
  display: flex;
  flex-wrap: wrap;

  .Item {
    height: 24px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 3px;
    color: $white;
    margin-left: 6px;
    margin-top: 6px;

    .close {
      font-size: 12px;
      margin-left: 8px;
      cursor: pointer;
    }
  }
  .valid {
    border: $lochmara solid 1px;
    background-color: $lochmara;
  }
  .invalid {
    border: $red solid 1px;
    background-color: $red;
  }

  .Item-entry {
    border: 0;
    min-width: 50px;
    height: 100%;
    border-radius: 3px;
    color: $white;
    .pa-RefField-Single {
      border: 0;
      .DraftEditor-root {
        flex: none;
      }
    }
    .pa-RefField-Single:focus {
      border: 0;
    }
  }
}
