@import '~styles/variables';
@import '~styles/utilities';

$check-size: 7px;

@mixin borderColor($border-color: $grey) {
  border: 1px solid $border-color;
}

@mixin boxShadowColor($box-shadow-color: $charcoal) {
  box-shadow: 0 0 0.25px 0.25px rgba($box-shadow-color, 0.75);
}

.wi-Check {
  $square-width: 13.5px;
  cursor: pointer;
  position: relative;
  height: $square-width;
  width: $square-width;
  border-radius: 2px;
  @include borderColor();

  &.isDisabled {
    pointer-events: none;
    cursor: not-allowed;
  }

  &.pa-gray {
    @include borderColor();
  }
  &.pa-red {
    @include borderColor($red);
  }
  &.pa-white {
    @include borderColor($white);
  }

  &:hover {
    @include boxShadowColor();

    &.pa-gray {
      @include boxShadowColor();
    }
    &.pa-red {
      @include boxShadowColor($red);
    }
    &.pa-white {
      @include boxShadowColor($white);
    }
  }
}

.wi-Check-Square {
  height: 100%;
  width: 100%;
  background-color: $charcoal;

  &.pa-gray {
    background-color: $grey;
  }
  &.pa-red {
    background-color: $red;
  }
  &.pa-white {
    background-color: $white;
  }
}

.wi-Check-Mark {
  position: absolute;
  display: inline-block;
  top: 0;
  left: calc($check-size / 2);

  &:after {
    $mark-width: 2px;

    content: '';
    display: block;

    /*Make it a small rectangle so the border will create an L-shape*/
    width: calc($check-size / 2) - 0.5px;
    height: $check-size;

    /*Add a white border on the bottom and left, creating that 'L' */
    border: solid $white;

    &.pa-white {
      border: solid $white;
    }
    &.pa-red {
      border: solid $red;
    }
    &.pa-gray {
      border: solid $charcoal;
    }

    border-width: 0 $mark-width $mark-width 0;

    /*Rotate the L 45 degrees to turn it into a checkmark*/
    transform: rotate(45deg);
  }
}
