@import '~styles/variables';
@import '~styles/utilities';

.wi-TemplateTitleEditor2 {
  .wi-TemplateTitleEditor-Title {
    display: flex;
    justify-content: flex-start;
    // align-items: center;

    .wi-TemplateTitleEditor-Title__Pill {
      margin-left: 8px;
    }

    .wi-RequiredBadge {
      height: auto;
      width: auto;
      left: auto;
      font-size: 1.9rem;
    }
  }

  .wi-TemplateTitleEditor-Container {
    @include flexbox(space-between, center);
    // min-height: 50px;
    background-color: $light_grey;
    border-bottom: 1px solid $grey;
    padding: 0 10px;
  }

  .wi-TemplateTitleEditor-Controls {
    @include flexbox();
    flex: 0 0 auto;
    align-items: center;

    .wi-TemplateTitleEditor-Controls__Info {
      color: $charcoal;
      font-size: 12px;
      line-height: 16px;
      align-self: stretch;
      display: flex;
      align-items: center;

      .wi-Icon {
        @extend %wi-Icon--charcoal;
      }
    }

    .wi-TemplateTitleEditor-Controls__Info__Message {
      margin-left: 5px;
    }

    #wi-TemplateTitleEditor-Controls__ButtonSet {
      button,
      a {
        border-radius: 3px;
        height: 29px;
        min-width: 100px;
        font-size: 14px;
        font-weight: normal;
        margin: 0px 0px 0px 8px;
      }
    }
  }
}
