@import '~styles/colors.scss';

.wi-StatusPillV2 {
  &-default {
    background-color: $parsable-grey-800;
    color: $white;
  }

  &-success {
    background-color: $green;
    color: $white;
  }

  &-gray {
    background-color: $parsable-grey-300;
    color: $parsable-grey-800;
  }
}
