@import '~styles/variables';

.wi-JobUserTable {
  .Select {
    margin: 10px 10px;
  }
}

.wi-OnDemandJobInviteNote {
  display: flex;
  align-items: start;
  padding: 10px;
  margin: 0 12px 12px 12px;
  border: 1px solid $blue;
  color: $dark_blue;
  background-color: #f5fdff;
  border-radius: 2px;

  .wi-Icon {
    margin-right: 10px;
    margin-top: 2px;

    path {
      fill: $blue;
    }
  }
}
