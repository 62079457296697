@import '~styles/variables';
@import '~styles/utilities.scss';

.pa-integration404 {
  @include center-children();
  height: 100%;
}

.pa-integrationDetail-DuplicateModal {
  .infoBox {
    @include infoBoxMixin($dark_red, $light_red, $red);
    margin-bottom: 20px;
  }
}

.pa-integrationEditor {
  .pa-editorLayout-title {
    display: flex;
    align-items: center;
    .wi-Pill {
      margin-top: 0px;
      margin-left: 10px;
    }
    .wi-Input {
      width: 300px;
      @include zoomAnimation();
    }
    input {
      margin-bottom: 0px;
    }

    .pa-LabelError {
      display: flex;
      .wi-Icon {
        @include zoomAnimation();
      }
      .wi-Icon path,
      &:hover .wi-Icon path {
        fill: $red !important;
      }
    }
  }
}

.pa-IntegrationDetails {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;

  .pa-IntegrationForm-type {
    width: 50%;
  }

  .pa-Form-Error {
    color: $red;
    flex-basis: 100%;
  }

  .pa-IntegrationDetails-Body {
    flex: 1;
    margin: 0px auto;
    padding: 15px 30px;
    width: 100%;
    max-width: 900px;
    position: relative;
    background-color: $white;
  }
}
