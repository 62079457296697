@import '~styles/variables';
@import '~styles/utilities';

.wi-Rev-DocumentsWrapper {
  margin-top: 20px;
}

.wi-Rev-DocumentList {
  margin-left: $template-left-indent;
}

.wi-Rev-DocumentName {
  @include flexbox(column, flex-start, flex-start);
  margin-top: 5px;
  font-size: $font-size-base;
  font-weight: $font-weight-regular;
}

.wi-Rev-DocumentsText {
  @extend %type-header-title;
  border-bottom: $border-standard;
  padding-bottom: 5px;
}
