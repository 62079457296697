@import '~styles/variables.scss';

.pa-PageName {
  background-color: rgba(255, 255, 255, 0.9);
  font-size: $pa-PageName--font_size;
  line-height: 1;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 20px;
  padding-bottom: 16px;
  padding-left: $pa-container--gutter_width;
  padding-right: $pa-container--gutter_width;
}
