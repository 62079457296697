@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-EditorNoticeIconWidget {
  position: relative;
  display: flex;
  align-items: stretch;

  .pa-EditorNoticeIconWidget-Selected {
    line-height: 0;
    display: flex;
    align-items: center;
  }

  .pa-Notice-NoticeIcon {
    margin: 0 5px;

    svg {
      width: 25px;
      height: 25px;

      .wi-Notice-flattenedStepListFlag & {
        height: 28px;
        width: 28px;
      }
    }
  }

  .wi-EditorNoticeIconWidget-Arrow {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 6px solid #000;
    position: absolute;
    bottom: -4px;
    right: 2px;
  }

  &:not(.wi-EditorNoticeIconWidget-Editable) {
    .wi-EditorNoticeIconWidget-Arrow {
      display: none;
    }
  }
}

.wi-EditorNoticeIconWidget-MenuItem {
  padding: 2px 0px;

  &:hover {
    background-color: $light_grey;
  }
}
