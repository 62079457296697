.wi-SyncStatusIcon {
  right: -2rem;
  line-height: 38px;
  position: absolute;
  font-size: 1.2em;
}

.wi-SyncStatusIcon-Right {
  right: 2rem;
}

.wi-SyncStatusIcon-Left {
  left: -2rem;
}
