.Analytics-action {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 10px;
  margin: 0 0 0 20px;
  cursor: pointer;

  > * + * {
    margin-left: 10px;
  }

  &.p-m-none {
    padding: 0;
    margin: 0;
  }

  &.flex-2x {
    flex: 2 0 0;
  }
}
