@import '~styles/variables';
@import '~styles/utilities';

#EditorTasksPanel {
  display: flex;
  position: relative;

  .panel-title {
    display: none;
  }

  .wi-StepList {
    color: $charcoal;

    .wi-StepListItem {
      padding-top: 3px;
      padding-bottom: 3px;
    }

    .wi-StepListItem-StepGroup {
      color: $black;
    }

    .wi-StepListItem-Title {
      &:hover {
        background-color: $highlight-select;
      }

      .wi-StepListItem-TitleLeft {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      &.wi-StepListItem-Active {
        color: $black;
        @extend %type-text-medium--bold;
        font-weight: 800 !important;
      }
    }
  }
}

#wi-EditorTasksPanel-AddStep {
  .wi-Dropdown-Wrapped {
    display: table;
    left: 0;
    white-space: nowrap;
    min-width: 190px;
  }
}
