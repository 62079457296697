@import '../TemplateDiffReportUtils';

$ele_height: 45px;
$base_wrap_padding: 5px;
$icon_padding: $base_wrap_padding;
$base_wrap_padding: $base_wrap_padding ($base_wrap_padding * 2);

.wi-Diff-EquipmentList {
  @include flexbox(column, flex-start, stretch);
}

.wi-Diff-EquipmentListWrapper,
.wi-Diff-Equipment {
  @include flexbox(row, flex-start);
}

.wi-Diff-EquipmentListWrapper {
  flex-wrap: wrap;
  margin-bottom: -10px;
  margin-left: $template-left-indent;
}

.wi-Diff-EquipmentIconWrapper {
  @include flexbox();
  margin-bottom: 10px;
  margin-right: 10px;
  border: none;

  &:not(:last-child) {
    margin-right: 10px;
  }
}

.wi-Diff-EquipmentIconEle {
  @include flexbox();
  @include diffImg();
  @include diffText();
  margin: 2px;

  .wi-ExifImage-Container,
  .Document-Thumbnail,
  .Document {
    @include flexbox();
    margin: 0;
  }

  img {
    height: $ele_height;
    width: $ele_height;
    margin: 0 !important;
  }

  &.wi-Diff-ImageRemoved {
    margin: -2px;
    padding: 2px;
  }

  &.wi-Diff-ImageAdded {
    margin: -2px;
    padding: 2px;
  }
}
