@import '../TemplateDiffReportUtils';

.wi-Diff-HierarchyText,
.wi-Diff-Heading-TitleText {
  font-size: $font-size-medium;
  font-weight: bold;
}

.wi-Diff-HierarchyWrapper {
  display: inline-block;

  .wi-Diff-HierarchyText {
    @include diffText();
    display: inline;
  }
}
