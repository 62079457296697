@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.wi-EditorTask-Container {
  margin: $spec-margin;
  margin-bottom: $editor-step-spacing;
  position: relative;
  max-width: $editor-step-max-width;
  margin-left: auto;
  margin-right: auto;

  .wi-EditorTask-Header-Container {
    display: flex;
    flex-direction: right;

    > :first-child {
      margin-right: $spec-margin;
      flex: 1 1 100%;
    }
    > :last-child {
      flex: none;
    }

    .wi-EditorTask-Container-Position {
      .pa-Threaded-Anchor {
        position: 'absolute';
        left: '100%';
      }
    }
  }

  &:not(.wi-EditorTask-Container-Editing) .wi-EditorTask-RoleSelector {
    max-width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wi-SelectRole-NonEditing {
    text-transform: uppercase;
    font-weight: $font-weight-regular;
    height: 29px;
  }

  .wi-StepField {
    .wi-StepField-IconContainer {
      margin-right: 0.35rem;
      .wi-StepField-Icon {
        width: 1.65rem;
      }
    }
  }
}

.wi-EditorTask-Container-Editing {
  .wi-TextDescription {
    color: $charcoal;
  }
}

.wi-TextDescription {
  color: $charcoal;
}

.wi-EditorTask-Container.ChallengeResponse {
  .wi-StepTitle-NonEditing input {
    color: $text_color;
  }

  .wi-EditorTask {
    .wi-StepDescription.wi-StepDescription-Editing {
      .wi-StepDescription-Controls {
        display: none;
      }
      .wi-StepDescription-Contents {
        padding-top: 5px;
        min-height: 40px;
        max-height: 40px;
      }
    }
  }
}

.stepContentHeader {
  @extend %type-header-medium;
  color: $dark_grey;
}

.wi-EditorTask {
  .wi-EditorTask-MetadataHeader {
    @extend .stepContentHeader;
  }

  .wi-EditorTask-SaveButtonWrapper {
    display: none;
  }

  &.wi-EditorTask-Editing {
    .wi-DescriptionField-Title {
      border-bottom: none;
    }

    .wi-EditorTask-SaveButtonWrapper {
      display: flex;
      justify-content: flex-end;
      visibility: hidden;

      &.wi-EditorTask-SaveButtonVisible {
        visibility: visible;
      }
    }
  }
}

.wi-NavHelper.wi-NavHelper-ReadOnly .wi-EditorTask-Container {
  cursor: default !important;
}
