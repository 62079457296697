@import '~styles/variables.scss';

.wi-Icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    pointer-events: none;
    width: 16px;
    height: 16px;
    g[fill='#FFFFFF'] {
      fill: white !important;
      path,
      rect,
      polygon {
        fill: white !important;
      }
    }
  }
}

.wi-Icon--clickable {
  cursor: pointer;
}

.wi-Icon--stacked-label {
  flex-direction: column;
  .wi-Icon-Label {
    margin-left: 0px;
  }
}

.wi-Icon--small {
  svg {
    width: 12px;
    height: 12px;
  }
}

.wi-Icon--medium {
  svg {
    width: 24px;
    height: 24px;
  }
}

.wi-Icon--large {
  svg {
    width: 100px;
    height: 100px;
  }
}

.wi-Icon--rotate90 svg {
  transform: rotateZ(90deg);
}

.wi-Icon--rotate180 svg {
  transform: rotateZ(180deg);
}

.wi-Icon--rotate270 svg {
  transform: rotateZ(270deg);
}

.wi-Icon--rotateY180 svg {
  transform: scaleX(-1);
}

.wi-Icon-Hidden {
  visibility: hidden !important;
}

.wi-Icon-Disabled {
  opacity: 0.2;
}

.wi-Icon:focus,
.wi-Icon:active {
  outline: 0 !important;
}

@keyframes pa-spinner-spin {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.pa-Icon--spinning {
  svg {
    animation: pa-spinner-spin 1s infinite;
  }
}

.wi-Icon-Spinner {
  height: 55px;
  width: 55px;
  position: fixed;
  top: calc(50vh - 50px);
  left: calc(50vw - 25px);
  z-index: 99999999;
  background-color: #ffffff;
  padding: 2px;
  border-radius: 8px;
  svg {
    width: 55px;
    height: 55px;
  }
}

.wi-Icon-Label {
  margin-left: 8px;
}
