@import '~styles/variables';

.text-truncate {
  overflow: hidden;

  // aliases but some browsers use one or the other
  overflow-wrap: break-word;
  word-wrap: break-word;

  word-break: break-all;
  // some browser support this, use it
  word-break: break-word;

  // some browsers support adding hyphens
  hyphens: auto;

  min-width: 0;
}

// Styles used to trigger animation which
// will tell us the item has resized
@keyframes resizeanim {
  from {
    opacity: 0;
  }

  to {
    opacity: 0;
  }
}

.resize-triggers {
  animation: 1ms resizeanim;
  visibility: hidden;
  opacity: 0;
}

.resize-triggers,
.resize-triggers > div,
.contract-trigger:before {
  content: ' ';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}

.resize-triggers > div {
  background: #eee;
  overflow: auto;
}

.contract-trigger:before {
  width: 101%;
  height: 101%;
}
