@import '~styles/colors';
@import '../TemplateDiffReportUtils';

.wi-DiffConditionalPill-Text {
  @include diffText();
}

.wi-DiffConditionalPill--strikethrough::after {
  -webkit-print-color-adjust: exact !important;
  print-color-adjust: exact !important;
  content: '';
  position: absolute;
  width: 108%;
  height: 1px;
  top: 50%;
  background-color: $charcoal;
  right: -4px;
}
