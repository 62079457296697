@import '~styles/variables';
@import '~styles/utilities';

.wi-DraggableTemplate {
  .wi-Icon {
    height: 22px;
    width: 22px;
    margin-top: -1px;
  }

  .wi-DraggableTemplate-Icon {
    margin-right: 5px;
  }

  .wi-DraggableTemplate-Icon-Delete {
    margin-left: 5px;
  }
}

.wi-TemplateTitle {
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
}

.wi-TemplateTitle-Title {
  flex-grow: 1;
}

.wi-TemplateTitle-Version {
  @include singleLineNoWrap();
  font-size: 11px;
  color: $text_color;
  line-height: 22.4px;
  margin-left: 5px;

  .wi-DraggableTemplate-List & {
    line-height: inherit;
  }
}

.wi-CurrentTemplates {
  @include flexbox(column, space-between, stretch);
  flex: 1 1 auto;
  flex-grow: 1;
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 3px;
  padding: 4px;
  height: 400px;
  min-height: 400px;
  overflow-y: auto;

  .delete {
    cursor: pointer;
  }
}

.wi-CurrentTemplates-Title {
  flex: 0 0 auto;
}

.wi-CurrentTemplates-List {
  overflow: auto;
  flex: 1 1 auto;
}

// Template Item

.wi-DraggableTemplate {
  background-color: $white;
  border: 1px solid $grey;
  border-radius: 3px;
  margin-bottom: 4px;
  width: 100%;

  &.wi-CurrentTemplate_isReadOnly {
    background-color: $white;
    box-shadow: none;
    cursor: default;
  }

  &.wi-CurrentTemplate_isDragging {
    opacity: 0 !important;
  }

  &.wi-CurrentTemplate_isProxy {
    opacity: 0.5;
  }
}

.wi-DraggableTemplate_isDraggable {
  cursor: move;
}

.wi-DraggableTemplate-Content {
  padding: 5px 8px;
  position: relative;
  font-size: 14px;
}

.wi-DraggableTemplate-Descrip,
.wi-DraggableTemplate-tSetDescrip,
.wi-DraggableTemplate-setContent {
  @include singleLineTextEllipsis();
  padding: 5px 30px;
  position: relative;
  font-size: 11px;
  color: $dark_grey;
}
.wi-DraggableTemplate-tSetDescrip {
  padding: 5px 24px;
}

.wi-DraggableTemplate-setContent {
  padding: 0;
}

.wi-DraggableTemplate-List {
  border-top: 1px solid $grey;
  margin: 0;
  padding: 4px 30px;
  color: $text_color;

  .wi-DraggableTemplate-setContent {
    display: flex;
    align-items: center;
  }

  > .wi-DraggableTemplate-tSetDescrip {
    padding-left: 0;
  }

  li {
    margin: 0;
    list-style: none;
  }
}

.wi-DraggableTemplate-variationsCount {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border: 1px solid $grey;
  border-radius: 3px;
  margin-bottom: 5px;
  margin-top: 5px;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.05);
  .wi-Icon {
    margin-left: -5px;
  }
  .wi-DraggableTemplate-variationsCount-text {
    flex: 1;
    margin-left: 5px;
    text-transform: uppercase;
    color: $dark_grey;
    font-weight: 500;
    font-size: 11px;
  }

  a {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.5px;

    &[disabled] {
      color: $dark_grey;
      cursor: not-allowed;
    }
  }
}

.wi-DraggableTemplate-variationsError {
  display: flex;
  border: 1px solid #d63535;
  border-radius: 5px;
  background-color: #fff9f9;
  margin-top: 10px;
  padding: 8px;

  .wi-Icon {
    margin-right: 10px;

    path {
      fill: #d63531;
    }
  }

  .wi-DraggableTemplate-variationsError-text {
    display: flex;
    flex: 1;
    flex-direction: column;
    color: #a51607;
    font-size: 13px;

    &--title {
      font-weight: bold;
      text-transform: uppercase;
    }

    .Button {
      margin-top: 5px;
    }
  }
}
