@import '~styles/colors';
@import '~styles/utilities';

.pa-Integration-description {
  color: $dark_grey;
  font-size: $font-size-small;
}

.wi-TemplateNavAlign {
  @include flexbox(row, flex-end);
}

// styling for the actions dropdown
.pa-IntegrationActions-Container {
  display: flex;
  justify-content: flex-end;
}
.pa-IntegrationActions-Container .wi-Dropdown {
  .wi-Dropdown-Wrapped {
    left: -11px;
    right: auto;
  }

  .wi-DropdownLabel {
    display: inline-block;
    position: relative;
    font-weight: $font-weight-semibold;

    &:after {
      content: '';
      position: absolute;
      right: -10px;
      top: 47%;
      margin-top: -2.5px;
      width: 0;
      height: 0;
      border-left: 4px solid transparent;
      border-right: 4px solid transparent;
      border-top: 4px solid var(--secondaryA, $blue);
      clear: both;
    }
  }

  a {
    height: 100%;
    width: 100%;
    padding-left: 15px;
    padding-right: 15px;
  }

  &:hover .wi-DropdownLabel {
    &:after {
      border-top: 4px solid var(--secondaryA, $blue);
    }
  }
}

.pa-IntegrationTable-Column-Name {
  width: 100%;
  > a {
    width: 100%;
  }

  .truncate-Container {
    flex: 1 1 0;
  }
}
.pa-IntegrationTable-Column-Type,
.pa-IntegrationTable-Column-Created,
.pa-IntegrationTable-Column-Archived,
.pa-IntegrationTable-Column-Updated {
  @include singleLineTextEllipsis();
}
