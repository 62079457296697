@import '~styles/variables';
@import '~styles/utilities';

.wi-DashboardTable {
  .wi-DashboardTableNameColumn {
    width: 100%;

    .wi-Icon {
      margin-right: 16px;
      margin-left: 4px;
      opacity: 0.5;
      svg {
        width: 16px;
        height: 16px;
      }
    }

    .wi-Pill {
      line-height: 0.95rem;
      padding: 0 6px;
      text-transform: uppercase;
      z-index: 1;
      height: 11.5px;
      margin-top: 5.25px;
      margin-left: 5px;
      font-size: 9px;
      width: 100%;
      height: 100%;
    }
  }

  .tag {
    float: right;
    font-size: $font-size-micro;
    font-weight: $font-weight-regular;
    background-color: $light_blue;
    color: $text_color;
    border-radius: 4px;
    padding: 3px 8px;
    margin: 8px 0 5px 5px;
  }

  .wi-TableRow-SubText,
  .wi-TableDescripSubText {
    color: $dark_grey !important;
    font-size: $font-size-small;
  }

  .wi-DashboardNavButton {
    min-width: 0;
  }

  .wi-DashboardNavAlign {
    @include flexbox(row, flex-end);
  }

  // in review state
  .wi-Dashboard-Row-InReview,
  .wi-DashboardExternal-Row {
    color: $charcoal;

    .wi-TableDescripText {
      color: $charcoal;
    }
  }

  .wi-DashboardTable-Column-Actions {
    display: flex;
    justify-content: flex-start;
    cursor: pointer !important;
    > div {
      display: flex;
    }
    button,
    a {
      align-self: center;
    }
  }

  &.wi-FullWidthTable tr.in-review {
    cursor: default;

    td {
      cursor: default;

      &:first-child {
        color: $charcoal;
      }
    }

    &:hover,
    &.active {
      background-color: initial;
    }
  }

  &.wi-DashboardTable-NoViewButton {
    .wi-TemplateNavButton {
      display: none;
    }
  }

  // styling for the actions dropdown
  .wi-DashboardTable-Column-Actions .wi-Dropdown {
    .wi-Dropdown-Wrapped {
      left: -11px;
      right: auto;
    }
    .wi-DropdownLabel {
      display: inline-block;
      position: relative;
      font-size: 14px;
      font-weight: $font-weight-semibold;
      background-color: inherit;
      padding: 0;
      margin: 0;

      &:after {
        content: '';
        position: absolute;
        right: -10px;
        top: 47%;
        margin-top: -2.5px;
        width: 0;
        height: 0;
        border-left: 4px solid transparent;
        border-right: 4px solid transparent;
        border-top: 4px solid var(--secondaryA, $blue);
        clear: both;
      }
    }

    a {
      height: 100%;
      width: 100%;
      padding-left: 15px;
      padding-right: 15px;
    }

    &:hover .wi-DropdownLabel {
      &:after {
        border-top: 4px solid var(--secondaryA, $blue);
      }
    }
  }

  .wi-DashboardTable-Column-Name,
  .wi-DashboardTable-Column-CreatedAt,
  .wi-DashboardTable-Column-LastViewed,
  .wi-DashboardTable-Column-Description,
  .wi-DashboardTable-Column-Folder,
  .wi-TableRow-SubText {
    @include singleLineTextEllipsis();
  }
}

.wi-DashboardTable-DetailsModal {
  .wi-DashboardTable-DetailsModal-Item {
    font-size: 16px;
  }

  .wi-DashboardTable-DetailsModal-Header {
    font-weight: 500;
  }

  .wi-DashboardTable-DetailsModal-Content {
    color: $dark_grey;
  }
}
