.pa-SectionSubTitle {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 0;
}
.pa-SectionSubTitle-title {
  margin-top: 0;
  margin-bottom: 0;
  line-height: normal;
}
.pa-SectionSubTitle-iconWrapper {
  display: flex;
  align-items: center;
  .wi-Icon {
    width: 14px;
    margin-left: 5px;
  }
}
