@import '~styles/variables';

.d3-axis {
  text {
    fill: $charcoal !important;
    font-size: 10px;
  }
  line,
  path {
    stroke: $charcoal !important;
  }
  .axis-label {
    font-size: 12px;
  }
}

.d3-axis-line {
  line,
  path {
    stroke: $grey !important;
    stroke-width: 1;
  }
  text {
    fill: $grey !important;
    text-anchor: start;
  }
}
