@import '~styles/variables.scss';

.pa-FormulaEditor {
  $main-width: 350px;
  $controls-height: 36px;
  $operator-size: 25px;

  display: flex;
  flex-grow: 1;
  flex-direction: column;

  border-radius: $border-radius-standard;
  border: 1px solid $grey;

  &.pa-FormulaEditor--error {
    border: 1px solid $red;
  }

  .pa-FormulaEditor-Controls {
    height: $controls-height;
    background: $light_grey;
    border-top-right-radius: $border-radius-standard;
    border-top-left-radius: $border-radius-standard;
    display: flex;
    justify-content: space-between;

    .pa-FormulaEditor-buttonbar {
      margin-left: 7px;
      display: inline-flex;
      width: 50%;

      .pa-FormulaEditor-OpButton {
        padding: 0px;
        margin: 0px;
        border-radius: 0px;
        height: $controls-height;
        width: $controls-height;
        border: none;
        background: $light_grey;
        transition: all 0.3s;

        &:hover {
          background: $grey;
        }

        &:active {
          background: $grey;
        }

        i {
          font-size: 1.8rem;
          display: block;
          font-weight: 500;
          font-style: normal;
          line-height: 0px;
        }

        &.pa-FormulaEditor-OpButton--disabled {
          &:hover,
          &:active {
            background: $light_grey;
          }

          i {
            color: $text_color;
          }
        }
      }
    }

    .pa-FormulaEditor-Controls-FieldRef {
      $height: 27px;
      $inner: 25px;

      height: $height;
      width: 200px;
      margin-right: 14px;
      margin-top: 4px;

      .Select-control {
        height: $inner !important;
      }

      .Select-placeholder {
        height: $inner !important;
        line-height: $inner !important;
      }

      .Select-input {
        height: $inner !important;
      }
      .Select-option {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .pa-FormulaEditor-Controls-FieldRef--disabled {
    }

    .pa-FormulaEditor-Controls-validation-error {
      line-height: 30px;
      margin-right: 7px;
      color: $red;
      display: flex;
      align-items: center;
    }
  }

  .pa-FormulaEditor-FormulaEntry {
    min-height: 45px;
    background: $white;
    border-bottom-right-radius: $border-radius-standard;
    border-bottom-left-radius: $border-radius-standard;
    padding: 4px;
    display: flex;
    flex-flow: row wrap;

    .pa-FormulaEditor-FormulaToken {
      display: flex;
      flex-grow: 0;
      flex-direction: column;
      justify-content: center;
      padding: 6px 9px;
      background: $dark_grey;
      margin-left: 4px;
      margin-top: 4px;
      border-radius: 7px;
      i {
        color: $white;
        font-weight: 500;
        font-style: normal;
        font-size: 1.6rem;
        line-height: 0px;
      }
      span {
        color: $white;
        word-break: break-all;
      }
    }

    .pa-FormulaEditor-NumericInput {
      flex-basis: 50px;
      height: 30px;
      flex-grow: 1;
      margin-left: 4px;
      margin-top: 4px;

      &.pa-FormulaEditor-NumericInput--invalid {
        input {
          color: $red;
        }
      }

      input,
      input:focus {
        width: 100%;
        height: 100%;
        outline: 0;
        border: 0px;
        padding: 0px;
        margin: 0px;
        font-size: 1.2rem;
      }
    }
  }

  .pa-FormulaEditor-Status {
    padding: 4px 8px;
    color: $grey;

    &.pa-FormulaEditor-Status--error {
      color: $red;
    }
  }
}
