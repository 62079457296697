@import '~styles/variables';

.dateRange {
  white-space: nowrap;
  display: flex;

  .dateRange-picker,
  .dateRange-spacer {
    padding: 6px 8px;
  }

  .dateRange-spacer {
  }

  .dateRange-picker {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.4s;

    //width: 150px;
    border: 1px solid $grey;
  }

  .dateRange-picker-title {
    margin: 0 10px -2px;
    line-height: 1;
  }

  .dateRange-spacer {
  }
}

.date-pickerPopup_isOpen .dateRange-picker {
  border-color: $blue;
  color: $blue;

  path {
    fill: $blue !important;
  }
}
