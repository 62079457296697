@import '~styles/variables.scss';
.wi-SelectRole {
  margin-bottom: 0;
  padding: 0;
  height: $editor-input-height;
  display: block;
  margin-left: auto;
  background-color: transparent;
  border-color: transparent;
  width: 100%;
  option {
    text-align: right;
  }
}
