@import '~styles/variables.scss';
@import '~styles/utilities.scss';

.pa-StepFieldIntegrationsModal {
  .well {
    overflow-y: scroll !important;
  }
  p {
    margin-bottom: 16px;
  }

  h3 {
    margin-top: 0px;
    margin-bottom: 6px;
  }

  .pa-Resolver-Header {
    margin-top: 32px;
    margin-bottom: 6px;
  }

  .response-errors.flexboxgrid--row {
    margin-top: -10px;
    color: red;
    font-size: 0.9em;
  }

  .pa-responsePath-Select {
    .header {
      font-weight: bold;
    }

    .badge {
      @include badge();
      background-color: $grey;
      color: $charcoal;
    }
  }

  .wi-Select-Container {
    .react-select__control {
      min-height: unset;
      height: 38px;
    }
  }
}

.pa-integrationsDropdown-selectOption {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  font-weight: 600;

  .header {
    font-weight: normal;
    display: flex;
    > span {
      flex: 1;
    }
  }
}
