@import '~styles/variables';
@import '~styles/utilities';

.pa-MessageSubmitForm {
  @include flexbox();
  .form-group {
    flex: 1;
    margin: 0 10px 0 0;
  }
  .form-control {
    box-shadow: 0px 1px 4px 0px $grey;
    border: none;
  }

  .pa-MessageSubmitForm--InputParent {
    width: 100%;
    margin-right: 10px;
  }

  .pa-MessageSubmitForm--Input {
    font-size: $input-font-size-sm;
    height: $input-height-sm;
    margin: 0;
    width: 100%;
    box-shadow: none;
    border-radius: 5px;
    border: solid 1px #d0d0d0;
    resize: none;
  }
}
