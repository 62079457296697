@import '~styles/variables';

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px;
  }
  80% {
    background-position: 800px;
  }

  100% {
    background-position: 800px;
  }
}

//this is terrible, difficult to decouple this component
.wi-FullWidthTable.animated-background {
  animation-duration: 3.5s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
  background-repeat: repeat;
  background-size: 1600px;
  position: relative;
}

.wi-shimerBody {
  tr.wi-shimerRow td {
    font-size: 9px;
    position: relative !important;
    padding: $padding-xs-vertical $spec-padding !important;

    & > div {
      width: 10px;
      background-color: #fff;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
    }

    .topDiv {
      position: absolute;
      height: 12px;
      top: 0;
      width: 100%;
    }

    .bottomDiv {
      position: absolute;
      height: 10px;
      top: calc(100% - 10px);
      width: 100%;
    }

    .rightDiv {
      left: 0;
    }

    &.whiteBkgCell {
      background-color: #fff !important;
    }
  }

  tr.wi-shimerRow:hover,
  tr.wi-shimerRow:active {
    background-color: transparent !important;
  }
}
