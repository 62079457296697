@import '~styles/variables.scss';
@import '~styles/utilities.scss';
.wi-EditorNotices {
  background-color: $white;
  padding: 10px;
  border-bottom: 1px solid transparent;
  border-left: 1px solid $grey;
  .wi-EditorNotices-Page {
    background-color: $white;
  }
  .wi-EditorNotices-Title {
    @extend %type-header-title;
  }
  .wi-EditorNotices-Header {
    @include flexbox();
  }
}

.wi-EditorNotices-Display {
  margin-bottom: 88px;

  .wi-Notice {
    .wi-Notice-Content {
      .wi-Notice-EditablePanel {
        .wi-Notice-IconContainer {
          align-self: center;
          margin-top: 0;
          margin-left: 0;
        }
      }
    }
  }
}
