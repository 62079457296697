@import '../TemplateDiffReportUtils';

.pa-Rev-AnnotatorWrapper {
  max-width: 100%;
  display: flex;

  > div {
    flex: 1 1 auto;
    width: 100%;
  }
  display: flex;
}

.pa-Rev-AnnotatorWrapper > div {
  flex: 1 0 auto;
}
