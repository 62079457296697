@media only screen and (max-width: 667px) {
  .row-container {
    align-items: center !important;
    justify-content: center !important;
  }
  .main-content {
    margin-left: 16px !important;
    margin-right: 16px !important;
    margin-top: 32px;
    width: 344px !important;
  }
  .t-and-c:before {
    content: ' ';
    display: block;
  }
  .secure-container {
    flex-direction: column;
    margin-bottom: 0px !important;
  }
  .secure-item {
    justify-content: flex-start !important;
    margin-right: 0px !important;
  }
  .activate-account {
    width: 344px !important;
    margin-bottom: 20px;
  }
}

@media only screen and (orientation: landscape) {
  .jobs-done-container {
    margin-top: 50px;
  }
}
