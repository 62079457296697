//----------------------------
// Admin Global Styles
//----------------------------

@import '~styles/variables';
@import '~styles/utilities';

body {
  overflow-x: hidden;
}

#react-root {
  height: 100%;
  position: relative;
  overflow: hidden;
  z-index: 0;
}
@media print {
  #react-root {
    overflow: visible !important;
  }
}

.pa-PageHeader {
  position: relative;
}

.pa-AppBody {
  height: 100%;
  padding-left: $pa-VerticalNav--width_collapsed;
  display: flex;
  flex-direction: column;
}
.pa-PageContent {
  flex: 1;
  overflow-y: auto;
  background-color: #ececec;
  .container {
    width: 100%;
    min-width: 0;
    max-width: 2000px;
  }
}

// #f9f9f9

.pa-AppBody.pa-AppBody--is_fluid {
  padding-left: $pa-VerticalNav--width_collapsed;
}
@media only screen and (min-width: $pa-VerticalNav--breakpoint) {
  .pa-AppBody {
    padding-left: $pa-VerticalNav--width_expanded;
  }
}

//needed for the textarea resize react element
body > textarea {
  min-height: initial;
}
