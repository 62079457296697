.wi-NavHelper {
  position: relative;
  padding-left: 10px;
  padding-right: 10px;
  z-index: 0;

  .wi-NavHelper-Hidden {
    display: none;
  }

  //show cursor when step is non editing state, and clickable to edit;
  &:not(.wi-NavHelper-Elevate) {
    cursor: pointer !important;
  }

  //fix steps in view;
  &.wi-NavHelper-Elevate {
    z-index: 100;
  }

  //need to create clickable layer to intercept clicks
  .wi-NavHelper-Intercept {
    opacity: 0;
    transition: opacity 0.55s ease 0.2s;

    &.wi-NavHelper-InterceptActive {
      width: 5000px;
      height: 20000px;
      position: fixed;
      -webkit-transform: translateZ(0);
      top: -10000px;
      z-index: -1;
      left: 0;
      background-color: #000;
      opacity: 0;
      pointer-events: none;
    }
  }
}
