.wi-ModernBrowserRecommandationCurtain {
  display: flex;
  justify-content: center;
  align-items: center;

  & .inner {
    & .browser-logos {
      text-align: center;

      & .item {
        width: 64px;
        height: 64px;
        margin-left: 32px;

        &:first-of-type {
          margin-left: 0;
        }
      }
    }

    & .description {
      margin-top: 32px;
      text-align: center;
      line-height: 1.5;
      font-size: 16px;
    }
  }
}
