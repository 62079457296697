@import '~styles/variables';

.wi-ThreeDotMenu-Icon {
  display: inline-block;
  width: 32px;
  height: 32px;
  padding: 8px;
  cursor: pointer;

  & svg {
    color: currentColor;
    fill: currentColor;
  }
}
