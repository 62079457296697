@import '~styles/variables.scss';
@import '~styles/utilities';

.wi-StepTitle-Ref-Container {
  @include flexbox(row, flex-start, baseline);
  @extend %type-header-title;

  .wi-StepTitle-Ref {
    margin-bottom: 0;
    @extend %type-header-title;
    width: 100%;
    padding-left: 10px;
    background-color: #ffffff;

    &.wi-StepTitle-NonEditing {
      border-color: transparent;
      background-color: transparent;
    }
  }
}
