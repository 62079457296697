@import '~styles/variables';
@import '~styles/utilities';

.TemplateList {
  .wi-TemplateDetailsPrint {
    max-height: 0;
    visibility: hidden;
  }
  .wi-TemplateEditor-container {
    max-height: 0;
    overflow: hidden;
  }
  @media print {
    body .container {
      padding: 0 !important;
    }
    div[id='EditorBody'] {
      flex-basis: 100%;
    }
    .wi-SectionHeader,
    div[data-testid='template-table-header-aciton-bar'],
    div[data-testid='applied-custom-filter-editor'],
    .wi-TemplateTitleEditor-Controls,
    div[role='table'] {
      visibility: hidden;
      max-height: 0;
      height: 0;
      overflow: hidden;
    }
    .editortasks,
    div[id='TemplateEditor'],
    .wi-TemplateEditor-container {
      height: max-content !important;
      max-height: none;
    }
    .wi-TemplateDetailsPrint {
      visibility: visible;
      max-height: none;
      .editortasks {
        overflow: visible !important;
      }
    }
  }
}
