@import '~styles/variables.scss';
@import '~styles/utilities.scss';

#EditorBody {
  position: relative;
  z-index: 1;
  width: 75%; /* IE doesn't like width-less flex boxes */
  $default_padding: 0.6125rem 1.25rem;

  @media (max-width: 1300px) {
    width: calc(100vw - 320px);
  }

  @include flexbox(column, space-between, stretch);

  .wi-EditorBody-CatBanner {
    height: $editor_category_banner;
    background-color: $light_grey;
    border-bottom: 1px solid $grey;
    border-left: 1px solid $grey;
    align-items: center;
    display: flex;
  }

  .wi-Editor-TestControls {
    @include flexbox(row, space-around, center);
    button,
    .Button {
      width: 25%;
      margin: 5px;
    }
  }

  .wi-EditorBody-Pill {
    font-size: 9px;
    font-weight: $font-weight-regular;
    background-color: $white;
    border: 1px solid $charcoal;
    color: $text_color;
    border-radius: 20px;
    padding: 1px;
    padding-left: 6px;
    padding-right: 6px;
    margin-left: 5px;
  }

  .wi-EditorBody-Heading {
    border-bottom: 1px solid $grey;
    border-left: 1px solid $grey;
    z-index: 100;
    flex-grow: 0;
    flex-shrink: 0;
    padding: $spec-padding-small;
  }

  .wi-EditorBody-HeadingTop {
    @include flexbox();
    @extend %type-header-title;
    margin-bottom: 0;
    z-index: 100;
  }

  .editortasks {
    flex-grow: 1000;
    flex-shrink: 1000;
    overflow-y: scroll;
    background-color: #f7f7f7;
    box-shadow: inset 1px 0px 2px $grey;
    .wi-EditorBody-Components {
      border-bottom: 1px solid $grey;
    }
  }

  //when the steps being displayed are the top level steps
  &.wi-EditorBody-TopLevel {
    .editortasks {
      @include headerAtTop($titleEditor_height);
      overflow-y: scroll;
    }

    .wi-EditorBody-Heading {
      display: none;
    }
  }

  &.wi-EditorBody-HasCondition {
    .editortasks {
      @include headerAtTop(
        $titleEditor_height + $stepFieldHeader_height - $editor_conditional_banner
      );
      overflow-y: scroll;
    }
    &.wi-EditorBody-HasCatBanner {
      .editortasks {
        @include headerAtTop(
          $titleEditor_height + $stepFieldHeader_height + $editor_category_banner -
            $editor_conditional_banner
        );
        overflow-y: scroll;
      }
    }
  }

  &.wi-EditorBody-HasCatBanner {
    .editortasks {
      @include headerAtTop($titleEditor_height + $stepFieldHeader_height + $editor_category_banner);
      overflow-y: scroll;
    }

    //NOT SURE IF THIS IS THE CASE YET

    &.wi-EditorBody-TopLevel {
      .editortasks {
        @include headerAtTop($titleEditor_height + $editor_category_banner);
        overflow-y: scroll;
      }
    }
  }

  .step-groups {
    padding: $default_padding;
  }

  .wi-EditorBody-AddTaskWrapper {
    display: flex;
    max-width: 900px;
    margin: 0 auto;
    // //must allow user to scroll far beyond this button;
    margin-bottom: 70vh;
  }
}

#TemplateEditor #EditorBody {
  opacity: 0.000000000000001;
  transition: opacity 0.1s ease 0.3s !important;
}

#TemplateEditor.wi-TemplateEditor-SetTemplate #EditorBody {
  opacity: 1;
}
