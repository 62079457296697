@import '~styles/variables.scss';
.wi-StepMetadata {
  .wi-StepMetata-TitleToggle {
    cursor: pointer;
    margin-left: 7px;
    font-size: 9px;
    color: $blue;
  }

  div.wi-EditorTask-MetadataHeader {
    margin-bottom: 1.5rem;
  }

  &:not(.wi-StepMetadata-Open) .wi-EditorTask-MetadataHeader {
    border-bottom: 0;
  }

  .Select {
    margin-bottom: 1.5rem;
  }

  .wi-MetadataLabel {
    margin-top: 0.6rem;
  }

  form {
    margin-bottom: 0;
  }

  .wi-Input {
    width: 100%;
  }

  input {
    width: 100%;
  }
}
