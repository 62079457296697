@import '~styles/variables';

.wi-PopoverMenuItem {
  display: flex;
  align-items: center;
  padding: 10px 12px;
  background-color: $white;
  color: inherit;
  font-size: $font-size-small;
  line-height: $line-height-base;
  cursor: pointer;

  .wi-Dropdown-Item {
    color: $charcoal;
  }

  &:hover {
    background-color: var(--secondaryA_100, $highlight_table_row);
  }

  &--disabled {
    color: $dark_grey;
    cursor: not-allowed;

    &:hover {
      background-color: $white;
    }
  }

  & > a {
    display: block;
    min-width: calc(100% + 24px);
    min-height: calc(100% + 20px);
    margin: -10px -12px;
    padding: 10px 12px;
    color: inherit;
  }
}
