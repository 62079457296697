@import '~styles/variables';
@import '~styles/utilities.scss';

#wi-TitleEditorHamburger.wi-Dropdown {
  margin-right: 0px;
  margin-left: 8px;

  .wi-Icon {
    @extend %wi-Icon--charcoal;

    &:hover {
      @extend %wi-Icon--blue;
    }
  }
}
