@import '~styles/variables';

.Toastify__toast--default {
  &.pa-Toast-Base-Style {
    border-radius: 3px;
    margin: 8px;
    background: #38a91e;
  }
}

.pa-toastContainer {
  display: flex;

  .pa-toastContainer-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
  }

  .pa-toastContainer-title {
    flex: 1;
    color: $gossip;
    padding-right: 10px;
    padding-left: 10px;

    a {
      color: $gossip;
      text-decoration: underline !important;
    }
  }

  span {
    vertical-align: middle;
  }
}

.pa-toast-warning {
  .pa-toastContainer-title {
    color: $white;
  }
}
